//@ts-check

import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonIcon, IonLabel, 
        IonPage, IonRouterOutlet, IonTabBar,
        IonTabButton, IonTabs, IonLoading, IonToast, IonItem, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonModal} from '@ionic/react';
import { menuController } from '@ionic/core';
import { IonReactRouter } from '@ionic/react-router';
import PrivateRoute from './PrivateRoute';
import { flash, settings, sunny, flag, logOut } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import {client, xml} from "@xmpp/client";
import loki from 'lokijs';
import axios from 'axios';

import { getRollers, useGlobalRollers, getVentilation, useGlobalVentilation, getDevices, useGlobalDevices, useGlobalSelectedDevice, getSelectedDevice, getGlobalStatusOnline, useGlobalStatusOnline } from "./functions/globalStore";
import { useGlobalOnline, getOnline } from "./functions/globalStore";

import { timeout, parseBoolean, randomID } from './functions/fce-math';
import { parseTag, isEmpty, log, decrypt, parseResource } from './functions/fce-string';

import { AuthContext } from './context/useAuth';
import { XmppOnlineContext } from './context/useXmppOnline';

import Login from "./Login";
import TabHeating from './pages/TabHeating';
import TabRoller from './pages/TabRoller';
import TabVentilation from './pages/TabVentilation';
import TabSetting from './pages/TabSetting';
import PageHeatingInfoRoom from './pages/heating/PageHeatingInfoRoom';
import PageHeatingLow from './pages/heating/PageHeatingLow';
import PageHeatingSummer from './pages/heating/PageHeatingSummer';
import PageHeatingRegimeList from './pages/heating/PageHeatingRegimeList';
import PageHeatingRegimeSet from './pages/heating/PageHeatingRegimeSet';
import PageHeatingRoomList from './pages/heating/PageHeatingRoomList';
import PageHeatingRoomSet from './pages/heating/PageHeatingRoomSet';
import PageHeatingKCNET from './pages/heating/PageHeatingKCNET';
import PageHeatingRoomMaxList from './pages/heating/PageHeatingRoomMaxList';
import PageHeatingRoomMaxSet from './pages/heating/PageHeatingRoomMaxSet';
import PageSettingInfo from './pages/setting/PageSettingInfo';
import PageSettingDateTime from './pages/setting/PageSettingDateTime';
import PageHeatingFloorList from './pages/heating/PageHeatingFloorList';
import PageHeatingFloorSet from './pages/heating/PageHeatingFloorSet';
import PageHeatingHDO from './pages/heating/PageHeatingHDO';
import PageHeatingManualList from './pages/heating/PageHeatingManualList';
import PageHeatingManualSet from './pages/heating/PageHeatingManualSet';
import PageHeatingWTR from './pages/heating/PageHeatingWTR';
import { LogoutPopup } from './functions/myReactComponents';
import PageRollerInfoRoom from './pages/roller/PageRollerInfoRoom';
import PageRollerCentral from './pages/roller/PageRollerCentral';
import PageRollerRegimeList from './pages/roller/PageRollerRegimeList';
import PageRollerRegimeSet from './pages/roller/PageRollerRegimeSet';
import PageRollerRoomList from './pages/roller/PageRollerRoomList';
import PageRollerRoomSet from './pages/roller/PageRollerRoomSet';
import PageRollerManual from './pages/roller/PageRollerManual';
import DevicesList from './pages/DevicesList';
import { DeviceSelectedContext } from './context/useDeviceSelectedContext';
import { useDataXMPP } from './functions/fetchXMPP';
import PageRollerWind from './pages/roller/PageRollerWind';
import { emulateResult } from './functions/emulateXMPPserver';

export const DEBUG = false;
export const TIMEOUT_TOAST_SAVED = 500;
export const TIMEOUT_TOAST_ERROR = 3000;

const URL_PREFIX = 'http:';
const SIGNOUT_TIMEOUT = 90000;
const WARNING_TIMEOUT = 80000;

log('---APP START---', false);

// eslint-disable

//global.btoa = base64.encode;
//global.atob = base64.decode;
document.title = 'BMR HC64';

//Memory Buffer pro kontrolu odeslaných/došlých paketů
const db = new loki('dbBuffer.db');
const dbBufferOutput = db.addCollection('dbBufferOutput');
const dbBufferInput = db.addCollection('dbBufferInput');

const XMPPDOMAIN = 'xmpp.bmr.cz';
const XMPPWEBRESOURCE = 'web';
let XMPPTIMEOUT = 50;
const XMPPServerOptions = { uri: 'ws://'+XMPPDOMAIN+':5280/websocket', domain: XMPPDOMAIN };
let XMPPUSERNAME = '';
let XMPPPASSWD = '';
let XMPP_RECIPIENT_RESOURCE = '';
let XMPP_RECIPIENT = XMPPUSERNAME+'@'+XMPPDOMAIN+'/'; 
let XMPPUserCredentials = { jidLocalPart: XMPPUSERNAME, password: XMPPPASSWD };

//GLOBAL proměnné
const [, globalActionOnline] = getOnline();
const [, globalActionStatusOnline] = getGlobalStatusOnline();

const [, globalActionDevices] = getDevices();
const [ globalSelectedDevice, globalActionSelectedDevice] = getSelectedDevice();

//Vymazat seznam řídících jednotek
globalActionDevices.removeAllDevice();
globalActionOnline.setONline();


export async function sendXMPPmessage(uid, type, fce, param) {
  
  XMPP_RECIPIENT_RESOURCE = globalSelectedDevice.active;

  log('sendXMPPmessage to:' + XMPP_RECIPIENT_RESOURCE, false);

  var msg =   '[UID]' + uid + '[/UID]' + 
              '[TYPE]' + type + '[/TYPE]' + 
              '[FCE]' + fce + '[/FCE]' + 
              '[PARAM]' + param + '[/PARAM]';

  const message = xml(
      'message', {
          type: 'chat', 
          id: uid,
          to: XMPP_RECIPIENT + XMPP_RECIPIENT_RESOURCE
      },
      xml(
          'body',
          {xmlns: 'jabber:client',}, //null,
          msg
      )
  );
  
  //Vlozit pozadavek do globálního bufferu
  dbBufferOutput.insert({uid: uid, type: type, fce: fce, param: param});
  log('QUESTION - dbBufferOutput->insert :' + msg, false);
  
  //await xmppClient.send(message); 

  //-----------------------------------------------
  //Počkat na odpověď ...
  //V DEMO režimu odpovím hned
  let atype = 'RESULT';
  let er = emulateResult(uid, type, fce, param);
  dbBufferInput.insert({uid: uid, type: atype, result: er});

  log('ANSWER - dbBufferInput->insert :' + uid + ', ' + atype + ', ' + er, false);

  let result = new loki();
  let r = selectOneRowFromBuffer(dbBufferInput, uid);
    
    if(r!==null) {
      try {
        if(r.type === 'RESULT'){
          result = r;
          //Vymazat zpracovaný záznam z bufferu
          deleteRecordFromBuffer(dbBufferOutput, uid);
          deleteRecordFromBuffer(dbBufferInput, uid);
        }
      }
      catch {
      }
    }
  
  return result;
}

function selectOneRowFromBuffer(buffer, id){
  var r =  buffer.findOne({ uid: id });

  return r; 
}

function deleteRecordFromBuffer(buffer, id){
  buffer.chain().find({ uid: id.toString() }).remove();
}

export function deleteAllRecordFromBuffers(){
  try
  {
      dbBufferOutput.chain().remove();
      dbBufferInput.chain().remove();
  }
  catch {}
}

///
// Funkce je volána z SetTokens() po přihlášení
///
function connectXMPPserver() {
  
  var l = localStorage.getItem('tokens');
  var objlocalStore = JSON.parse(l);

  XMPPUSERNAME = objlocalStore.uid;
  XMPPPASSWD = decrypt(objlocalStore.passwd);
  XMPPTIMEOUT = objlocalStore.timeout;
  //XMPP_RECIPIENT_RESOURCE = objlocalStore.resource; //už se bere z presence jednotek z hook

  //Vymazání hesla v localStorage
  objlocalStore.passwd = '';
  localStorage.setItem('tokens', JSON.stringify(objlocalStore));
}

function presentAlertHC64offline() {
  const alert = document.createElement('ion-alert');
  alert.header = 'Upozornění';
  alert.subHeader = 'Řídící jednotka HC64 neodpovídá.';
  alert.message = 'Zkontrolujte připojení do internetu pro HC64';
  alert.buttons = ['OK'];

  document.body.appendChild(alert);
  
  return alert.present();
}

/**
* REACT COMPONENT 
*/

const App: React.FunctionComponent = () => {
  //const h = useHistory();
  const [globalStatusOnline, ] = useGlobalStatusOnline([]);
  const [authTokens, setAuthTokens] = useState(null);
  const [globalStateDevices, globalActionDevices ] = useGlobalDevices(['devices']);
  const [globalStateSelectedDevice, globalActionSelectedDevice ] = useGlobalSelectedDevice(['active']);
  const [globalStateOnline, globalActionOnline] = useGlobalOnline(['isOnline']);
  const [warn, setWarn] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const setTokens = (data) => {
    //Autorizační token se nastavuje v Login
    //console.log('setTokens', data);
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);

    if(typeof data === 'undefined')
    {
        globalActionOnline.setOFFline();  
    } else {
      checkDeviceOnline();
      globalActionSelectedDevice.setDevice('demo');
      connectXMPPserver();
    }
  }
  
  const onClickLogout = () => {
    localStorage.removeItem("tokens");
    setAuthTokens(null);
  }

  const onCallBackWarning = () => {
    setWarn(true);
  }

  const checkDeviceOnline = async () => {
    //Dotaz na konfiguraci HC64
    //Enable ikon TAB záložek dle povolených funkcí
    deleteAllRecordFromBuffers();
  
    const result = await sendXMPPmessage(
      randomID(),
      'get',
      '/unitSettings',
      ''
    )
    
    if(result === null) {
      //Disable ikon TAB
      globalActionStatusOnline.setDisableAll();
      presentAlertHC64offline();  
    }
    else
    {
      try {
        if(result.result.length === 10) {
  
          globalActionStatusOnline.setHeatingOn();
          globalActionStatusOnline.setSettingOn();
  
          let status = (result.result).substr(0,1);
          parseBoolean(status) ? globalActionStatusOnline.setBackcompatibilityOn() : globalActionStatusOnline.setBackcompatibilityOff();
  
          status = (result.result).substr(8,1);
          parseBoolean(status) ? globalActionStatusOnline.setRollerOn() : globalActionStatusOnline.setRollerOff();
  
          status = (result.result).substr(9,1);
          parseBoolean(status) ? globalActionStatusOnline.setVentilationOn() : globalActionStatusOnline.setVentilationOff();
        }
        else {
          globalActionStatusOnline.setDisableAll();
          presentAlertHC64offline();  
        }
      }
      catch {
        //Disable ikon TAB
        globalActionStatusOnline.setDisableAll();
        presentAlertHC64offline();
      }
    }
    setRefresh(refresh+1);
  }

  const onCallbackSelectedDevice = (name) => {
    //Globální název resource zvolené řídící jednotky
    globalActionSelectedDevice.setDevice(name);
    checkDeviceOnline();
  }

  //<Route path="/deviceslist" exact={true} render={() => <DevicesList title='Informace o místnostech' devices={globalStateDevices.devices} parentCallbackSelectedDevice={onCallbackSelectedDevice} parentCallbackLogout={onClickLogout}/>} />
  //<PrivateRoute path="/deviceslist" exact={true} component={DevicesList} title='Informace o místnostech' devices={globalStateDevices.devices} parentCallbackSelectedDevice={onCallbackSelectedDevice} parentCallbackLogout={onClickLogout}/>
  
  return (
  <IonApp>
    <DeviceSelectedContext.Provider value={globalStateSelectedDevice.active}>
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
    <XmppOnlineContext.Provider value={globalStateOnline.isOnline}>
    
    <IonReactRouter>
      <IonPage id="main">

      {globalStateOnline.isOnline === true ? <LogoutPopup parentCallbackWarn={onCallBackWarning} parentCallbackLogout={onClickLogout} xmppUserName={XMPPUSERNAME} signoutTime={SIGNOUT_TIMEOUT} warningTime={WARNING_TIMEOUT}/>:''}
      
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/" render={() => <Redirect to="/login"/>}/>
            <Route exact path="/login" component={Login} />
            
            <Route path="/deviceslist" exact={true} render={()=> authTokens ? (<DevicesList title='Informace o místnostech' devices={globalStateDevices.devices} parentCallbackSelectedDevice={onCallbackSelectedDevice} parentCallbackLogout={onClickLogout} account={authTokens.uid}/>) : (<Redirect to="/login"/>) }/>

            <PrivateRoute path="/:tab(tabheating)" exact={true} component={TabHeating} />
            <Route path="/:tab(tabheating)/pageheatinginforoom" exact={true} render={() => <PageHeatingInfoRoom title='Informace o místnostech'/>} />
            <Route path="/:tab(tabheating)/pageheatingmanuallist" exact={true} render={() => <PageHeatingManualList title='Ruční změna teploty'/>} />
            <Route path="/:tab(tabheating)/pageheatingmanuallist/room/:id/:name" component={PageHeatingManualSet} />
            <Route path="/:tab(tabheating)/pageheatinglow" exact={true} render={() => <PageHeatingLow title='Útlum vytápění'/>} />
            <Route path="/:tab(tabheating)/pageheatingsummer" exact={true} render={() => <PageHeatingSummer title='Letní režim'/>} />
            <Route path="/:tab(tabheating)/pageheatingregimelist" exact={true} render={() => <PageHeatingRegimeList title='Teplotní režimy'/>} />
            <Route path="/:tab(tabheating)/pageheatingregimelist/regime/:id/:name" component={PageHeatingRegimeSet} />
            <Route path="/:tab(tabheating)/pageheatingroomlist" exact={true} render={() => <PageHeatingRoomList title='Místnosti'/>} />
            <Route path="/:tab(tabheating)/pageheatingroomlist/room/:id/:name" component={PageHeatingRoomSet} />
            <Route path="/:tab(tabheating)/pageheatingroommaxlist" exact={true} render={() => <PageHeatingRoomMaxList title='Maximální změna teploty'/>} />
            <Route path="/:tab(tabheating)/pageheatingroommaxlist/room/:id/:name" component={PageHeatingRoomMaxSet} />
            <Route path="/:tab(tabheating)/pageheatingfloorlist" exact={true} render={() => <PageHeatingFloorList title='Minimální teplota podlahy'/>} />
            <Route path="/:tab(tabheating)/pageheatingfloorlist/room/:id/:name" component={PageHeatingFloorSet} />
            <Route path="/:tab(tabheating)/pageheatingwtr" exact={true} render={() => <PageHeatingWTR title='Venkovní teplota'/>} />
            <Route path="/:tab(tabheating)/pageheatinghdo" exact={true} render={() => <PageHeatingHDO title='Informace o tarifu'/>} />
            <Route path="/:tab(tabheating)/pageheatingkcnet" exact={true} render={() => <PageHeatingKCNET title='Status koncových členů'/>} />

            <PrivateRoute path="/:tab(tabroller)" exact={true} component={TabRoller} />
            <Route path="/:tab(tabroller)/pagerollerinforoom" exact={true} render={() => <PageRollerInfoRoom title='Informace o roletách'/>} />
            <Route path="/:tab(tabroller)/pagerollercentral" exact={true} render={() => <PageRollerCentral title='Centrální tlačítko'/>} />
            <Route path="/:tab(tabroller)/pagerollermanual" exact={true} render={() => <PageRollerManual title='Ruční změna polohy'/>} />
            <Route path="/:tab(tabroller)/pagerollerregimelist" exact={true} render={() => <PageRollerRegimeList title='Roletové režimy'/>} />
            <Route path="/:tab(tabroller)/pagerollerregimelist/regime/:id/:name" component={PageRollerRegimeSet} />
            <Route path="/:tab(tabroller)/pagerollerroomlist" exact={true} render={() => <PageRollerRoomList title='Rolety'/>} />
            <Route path="/:tab(tabroller)/pagerollerroomlist/room/:id/:name" component={PageRollerRoomSet} />
            <Route path="/:tab(tabroller)/pagerollerwind" exact={true} render={() => <PageRollerWind title='Senzor větru'/>} />
            
            <PrivateRoute path="/:tab(tabventilation)" component={TabVentilation} exact={true}/>
            
            <PrivateRoute path="/:tab(tabsetting)" component={TabSetting} exact={true}/>
            <Route path="/:tab(tabsetting)/pagesettinginfo" exact={true} render={() => <PageSettingInfo title='Verze řídící jednotky' onSendXmpp={sendXMPPmessage}/>} />
            <Route path="/:tab(tabsetting)/pagesettingdatetime" exact={true} render={() => <PageSettingDateTime title='Nastavení času'/>} />
          </IonRouterOutlet>

          <IonLoading
            isOpen={!globalStateOnline.isOnline}
            message={'Loading...'}
            duration={50000}
          />

          <IonTabBar slot="bottom">
            <IonTabButton tab="tabheating" href="/tabheating" disabled={!globalStatusOnline.heating}>
              <IonIcon icon={flash} />
              <IonLabel>Topení</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tabroller" href="/tabroller" disabled={!globalStatusOnline.roller}>
              <IonIcon icon={sunny} />
              <IonLabel>Rolety</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tabsetting" href="/tabsetting" disabled={!globalStatusOnline.setting}>
              <IonIcon icon={settings} />
              <IonLabel>Nastavení</IonLabel>
            </IonTabButton>
            <IonTabButton tab="logout" onClick={onClickLogout} disabled={!globalStateOnline.isOnline}>
              <IonIcon icon={logOut} color='danger' />
              <IonLabel>Odhlášení</IonLabel>
            </IonTabButton>
          </IonTabBar>
          
        </IonTabs>
        <IonToast
            isOpen={warn}
            onDidDismiss={() => setWarn(false)}
            message={"K odhlášení dojde za 10s nečinnosti ..."}
            position="bottom"
            duration={5000}
            buttons={[
              {
                text: 'OK',
                role: 'cancel'
              }
            ]}
          />
      </IonPage>
    </IonReactRouter>
    </XmppOnlineContext.Provider>
    </AuthContext.Provider>
    </DeviceSelectedContext.Provider>
  </IonApp>
  )};

export default App;

/**
 * <IonTabButton tab="tabventilation" href="/tabventilation" disabled={!globalStatusOnline.ventilation}>
              <IonIcon icon={flag} />
              <IonLabel>Větrání</IonLabel>
            </IonTabButton>
 */

