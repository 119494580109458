//@ts-check

import React, { useState, useEffect } from "react";
import { getArrOfNames, log } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonContent, 
         IonToast, IonButton, IonList, IonItem, 
         IonSelect, IonSelectOption, 
         IonPopover, IonSpinner, IonAlert, IonCol, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { swapHorizontal, helpCircleOutline } from "ionicons/icons";
import { RouteComponentProps, useHistory } from "react-router";
import { useDataXMPP, useDataXMPP2 } from '../../functions/fetchXMPP';
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

interface UserDetailPageProps extends RouteComponentProps<{
    id: string;
    name: string;
}> {}
  
const PageRollerRoomSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const h = useHistory(); 
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
    const [showHelp, setShowHelp] = useState(false);
    const [showToastSaved, setShowToastSaved] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertRegime, setShowAlertRegime] = useState(false);
    const [isAlertRegime, setIsAlertRegime] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isError, setIsError] = useState(false);

    const defaultXmppObject = {
        typefce: 'get',
        fce: '/listOfPrograms',
        param: ''
    }
    const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
        defaultXmppObject,
        '',
    );  
    const defaultXmppObjectRoomSet = {
        typefce: 'get',
        fce: '/rollerShutterSettings',
        param: prepareID(parseInt(match.params.id))
    }
    const [{ dataXmpp2, isLoadingXmpp2, isErrorXmpp2, errMsgXmpp2 }, doFetchXmpp2] = useDataXMPP2(
        defaultXmppObjectRoomSet,
        '',
    );
    const [listRegimes, setListRegimes] = useState([]);                     //Hook pro pole názvu režimů
    
    const defaultArrayRegimesOfRoom = [
        { dayname: 'PO', value: 99 },
        { dayname: 'ÚT', value: 99 },
        { dayname: 'ST', value: 99 },
        { dayname: 'ČT', value: 99 },
        { dayname: 'PA', value: 99 },
        { dayname: 'SO', value: 99 },
        { dayname: 'NE', value: 99 }
    ];

    const [regimesOfRoom, setRegimesOfRoom] = useState(defaultArrayRegimesOfRoom);         //Hook pro pole přiřazení režimů danému dni
  
    useEffect(function fce() {
        // 👍 We're not breaking the first rule anymore
        //console.log('useEffect() dataXmpp');

        if (!isLoadingXmpp) {
          //console.log('useEffect() dataXmpp -> !isLoadingXmpp');  
          
          if(dataXmpp === '' || dataXmpp === 'true')
            return;

          let resutlXmpp: string = dataXmpp;
          let result = [];
    
          try {  
            if(resutlXmpp.length >= 13) {
              //'Rezim 1      Rezim 2      '
              let dataList = getArrOfNames(resutlXmpp, 13);
              //dataList.unshift('Žádný  '); //Režim pro nepřiřazený režim id=0

              let i = 0;
              dataList.forEach(row => {
                const reg = { 
                  id: i,
                  name: row,
                };
                result.push(reg);
                i ++;
              });
            }
          }
          catch {
            result = [];
          }
          finally {
            setListRegimes(result)
          }
        }
        
      },[dataXmpp, isLoadingXmpp]);

    useEffect(function fce() {
        // 👍 We're not breaking the first rule anymore
        if (!isLoadingXmpp2) {

          if (typeof dataXmpp2 === "undefined" || dataXmpp2 === 'true') 
            return;

            let result: string = dataXmpp2;

            if(result.length >= 2 ) {
                var _r = getArrOfNames(result.slice(0),2); //ID režimů za sebou 
                
                let newArr = JSON.parse(JSON.stringify(regimesOfRoom));

                for(let i = 0; i < Object.keys(newArr).length; i++)
                {
                    let a: number = 0;
                    try {
                      a = parseInt(_r[i]);
                      if(isNaN(a)) {
                        a = 99;
                      }
                    }
                    catch {
                      a = 99;
                    }
                    finally {
                      newArr[i].value = a; //0-Nepřiřazeno
                    }
                }
                setRegimesOfRoom(newArr);
            }
            else {
              //Pokud je prázdná odpověď, nejsou přiřazeny žádné režimy roletám
              setRegimesOfRoom(defaultArrayRegimesOfRoom);
            }
            setShowLoading(false);

        }
      },[dataXmpp2, isLoadingXmpp2]);

    //Smazání všech přiřazení
    useEffect(function fce() {
        setRegimesOfRoom(defaultArrayRegimesOfRoom);
        setIsRemoving(false);
    },[isRemoving]);

    

    const handleOnSelectItem = index => e => {
      log('handleOnSelectItem, index: ' + index + ' e.detail.value: ' + e.detail.value, false);  
      /*  
      if(index >0 && regimesOfRoom[index-1].value === -1) {
            setIsError(true);
            return;
        }
*/
        if(!isRemoving) {
            let copy = JSON.parse(JSON.stringify(regimesOfRoom));
            copy[index].value = parseInt(e.detail.value);
            setRegimesOfRoom(copy);
        }
        else {
            setIsRemoving(false);
        }
  
    }

    const handleOnClickOK = () => {
        //• ID místnosti (2 znaky)
        //• id režimů jednotlivých dnů (každý režim 2 znaky). Posílá se buď jedno ID nebo sedm ID (celý týden)!

        let id = prepareID(parseInt(match.params.id));
        let isErr = false;
        let reg = '';
        for(let i = 0; i < Object.keys(regimesOfRoom).length; i++) {
          let id = regimesOfRoom[i].value;
          
          if (id === 99) {
            if(i > 1) {
              isErr = true;
              setIsAlertRegime(true);
              setShowAlertRegime(true);
            }
            break;
          }
          reg += prepareID(id);
        };

        if( isErr || isAlertRegime) {
          return;
        }

        let p = id + reg;
    
        const saveXmppObject = {
        typefce: 'SET',
        fce: '/saveRollerShutterSettings',
        param: p
        }
        doFetchXmpp(
            saveXmppObject,
        );

        setShowToastSaved(true);
    }

    const RenderItemsOption = () => {
      return (
        <> 
        {
          listRegimes.map((item, index) => (
            <IonSelectOption value={item.id} key={index}>{item.name}</IonSelectOption>
          ))
        }
        </>
      );
    }

    return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabroller/pagerollerroomlist" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

      <IonPopover
        isOpen={showHelp}
        onDidDismiss={e => setShowHelp(false)}
      >
        <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
              Každé roletě/žaluzii se musí přiřadit časový režim-program. 
              <b>Musí být vyplněn jeden den nebo celý týden.</b>
              Pokud vyplníte pouze program na pondělí, bude se tento program opakovat každý den. 
              Pokud vyplníte pondělí-neděle, bude se opakovat celý týden.<br></br>
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
        </IonCard>
      </IonPopover>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Potvrzení'}
          message={'Opravdu chcete smazat všechna přiřazení?'}
          buttons={[
            {
              text: 'NE',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                //console.log('Confirm Cancel: blah');
              }
            },
            {
              text: 'ANO',
              handler: () => setIsRemoving(true)
            }
          ]}
      />
      <IonAlert
          isOpen={showAlertRegime}
          onDidDismiss={() => setShowAlertRegime(false)}
          header={'Potvrzení'}
          message={'Musí být nastaven jeden den nebo celý týden.'}
          buttons={[
            {
              text: 'OK',
              handler: () => setIsAlertRegime(false)
            }
          ]}
      />

      <IonCard className="welcome-card" >
        <IonCardHeader>
          <IonCardTitle>Přiřazení režimů roletám <IonIcon color="medium" icon={swapHorizontal}/></IonCardTitle>
          <IonCardSubtitle></IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem color='primary'>{match.params.name}</IonItem>

          <IonCard>
              <IonCardContent>
                <IonList>
                  {regimesOfRoom.map((data, index) => (
                    <IonItem key={index}>
                      <IonCol>
                        <IonButton disabled={data.value === 99} onClick={(e) => { e.preventDefault(); h.push('/tabroller/pagerollerregimelist/regime/'+data.value+'/'+data.value)}}>{data.dayname}</IonButton>
                      </IonCol>
                      <IonCol>
                      <IonSelect value={data.value} placeholder='Nepřiřazeno' interface="popover" onIonChange={handleOnSelectItem(index)} >
                        <RenderItemsOption/>
                      </IonSelect>
                      </IonCol>
                    </IonItem>
                  ))}
                </IonList>
              </IonCardContent>
          </IonCard>

          <IonButton expand="block" disabled={isLoadingXmpp2} onClick={handleOnClickOK}>Uložit</IonButton>
          <IonLabel>&nbsp;</IonLabel>
          <IonButton expand="block" disabled={isLoadingXmpp2} onClick={()=>setShowAlert(true)}>Smazat všechna přiřazení</IonButton>
          
          </IonCardContent>          
        </IonCard>

        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        <IonToast
          isOpen={isErrorXmpp2}
          message={errMsgXmpp2}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        <IonToast
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          message='Předcházející režim nesmí být prázdný!'
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        </IonContent>  
    </IonPage>
    )
  }
  export default PageRollerRoomSet;  

