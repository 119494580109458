//@ts-check

import { log } from "./fce-string";

/**
const initialStateStatusOnline = {
  heating: false,
  roller: false,
  ventilation: false,
  setting: false,
  backcompatibility: false
};
 */
export const actionsStatusOnline = {
  setDisableAll(store) {
    store.setState({
      heating: false,
      roller: false,
      ventilation: false,
      setting: false,
      backcompatibility: false
    });
  },
  setHeatingOn(store) {
    store.setState({
      heating: true
    });
  },
  setHeatingOff(store) {
    store.setState({
      heating: false
    });
  },
  setRollerOn(store) {
    store.setState({
      roller: true
    });
  },
  setRollerOff(store) {
    store.setState({
      roller: false
    });
  },
  setVentilationOn(store) {
    store.setState({
      ventilation: true
    });
  },
  setVentilationOff(store) {
    store.setState({
      ventilation: false
    });
  },
  setSettingOn(store) {
    store.setState({
      setting: true
    });
  },
  setSettingOff(store) {
    store.setState({
      setting: false
    });
  },
  setBackcompatibilityOn(store) {
    store.setState({
      backcompatibility: true
    });
  },
  setBackcompatibilityOff(store) {
    store.setState({
      backcompatibility: false
    });
  }

};

export const actionsDevices = {
  addDevice: (store, sourceOfDevice) => {
    try {
      let arr = JSON.parse(JSON.stringify(store.state.devices));
      if(!arr.includes(sourceOfDevice)) {
        log('actionsDevices/addDevice: '+sourceOfDevice, false);
        arr.push(sourceOfDevice);
        store.setState({ 
          devices: arr
        });
      }
    }
    catch {
      log('actionsDevices/addDevice', true);
    }
  },
  removeAllDevice: (store) => {
    try {
      store.setState({
        devices: []
      });
    }
    catch {
      log('actionsDevices/removeAllDevice', true);
    }
  }
};

export const actionsSelectedDevice = {
  setDevice: (store, name) => {
    try {
        store.setState({ 
          active: name
        });
      }
    catch {
      log('actionsSelectedDevice/setDevice', true);
    }
  }
};

export const actionsOnline = {
  setONline(store) {
    store.setState({
      isOnline: true
    });
  },
  setOFFline(store) {
    store.setState({
      isOnline: false
    });
  }
};

export const actionsStanza = {
  setDidStanza(store) {
    store.setState({
      isStanza: true
    });
  },
  setDidntStanza(store) {
    store.setState({
      isStanza: false
    });
  }
};

export const actionsRollers = {
  setONrollers(store) {
    store.setState({
      isRollers: true
    });
  },
  setOFFrollers(store) {
    store.setState({
      isRollers: false
    });
  }
};

export const actionsVentilation = {
  setONventilation(store) {
    store.setState({
      isVentilation: true
    });
  },
  setOFFventilation(store) {
    store.setState({
      isVentilation: false
    });
  }
};
