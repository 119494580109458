//@ts-check

export function timeout(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function randomID() {
    return Math.floor(Math.random() * Math.floor(999999999));
}

export function isObjectNull(obj:any) {
    return (obj === null);
    //return (obj == null && obj == '');
}

export function cloneObject(obj:any) {
    let newObject = Object.assign({}, obj )

    return newObject;
}

export function parseBoolean(value:any){
    switch(value){
         case true:
         case "true":
         case 1:
         case "1":
         case "on":
         case "yes":
             return true;
         default: 
             return false;
     }
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/*
export function round(value:number, exp:number) {
    if (typeof exp === 'undefined' || +exp === 0)
      return Math.round(value);
  
    value = +value;
    exp  = +exp;
  
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
      return NaN;
  
    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
  
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}
*/
