//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import {  } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonContent, 
         IonToast, IonList, IonItem, IonRefresher, IonRefresherContent, IonButton, IonPopover, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { informationCircle, helpCircleOutline, sunny, snow, close } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";
import { TIMEOUT_TOAST_ERROR } from "../../App";

const PageHeatingKCNET = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {
      typefce: 'get',
      fce: '/getKCNET',
      param: ''
    }
    const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
      defaultXmppObject,
      '',
    );

    const [showHelp, setShowHelp] = useState(false);
    const [statusKCNET, setStatusKCNET] = useState([]);

    useEffect(function fce() {
      // 👍 We're not breaking the first rule anymore
      if (!isLoadingXmpp) {
        let resultXmpp: string = dataXmpp;
        
        if(resultXmpp === '' || resultXmpp === 'true')
          return;
  
        let result = [];
        try {
          if(resultXmpp.length === 42) {
            //vrací se 8x5 znaků, plus dva znaky asi pro globální KCNET
            //000000000000000000000000000000000000000001
            var _r = resultXmpp.match(/.{1,5}/g); 

            for(let i = 0; i < Object.keys(_r).length; i++) {
                    let value: number = parseInt(_r[i].substr(0,1));
                    let typeKCNET: number = parseInt(_r[i].substr(1,1));
                    
                    var _icon = close;
                    switch(typeKCNET) {
                      case 0:
                        _icon = close;
                      break;
                      case 1:
                        _icon = sunny;
                        break;
                        case 2:
                          _icon = snow;
                        break;
                      default:
                    }

                    let o = {value: value, typeKCNET: _icon};
                    result.push(o);
            }
            setShowLoading(false);
            setStatusKCNET(result);
          }
        }
        catch {
        }
        finally {
          
        }
      }
  
    },[dataXmpp, isLoadingXmpp]);

    const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
      doFetchXmpp(
        defaultXmppObject,
      );
      e.detail.complete();
    }
    
    return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      
      <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
      </IonRefresher>

      <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Koncový člen odepíná topný zdroj jestliže jsou všechny místnosti natopené na požadovanou teplotu. V systému pro vodní vytápění může být až 8 koncových členů. 
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

      <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" size="" icon={informationCircle}/></IonCardTitle>
            <IonCardSubtitle>
              
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
            {statusKCNET && statusKCNET.map((item, index) => (
            <IonItem key={index}>
              <IonLabel>KC číslo: {index+1}</IonLabel>
              <IonIcon icon={item.typeKCNET}></IonIcon>
              <IonLabel> {item.value > 0 ? 'Zapnuto' : 'Vypnuto'}</IonLabel>
            </IonItem>
          ))}
            </IonList>
          </IonCardContent>
        </IonCard>
        
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />

        </IonContent>  
    </IonPage>
    )
  }
  export default PageHeatingKCNET;  
