//@ts-check

import React, { useState, useEffect } from "react";
import { parseBoolean } from '../../functions/fce-math';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, IonToggle,
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonContent, 
         IonToast, IonButton, IonList, IonItem, IonPopover, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { sunny, helpCircleOutline } from "ionicons/icons";

/* XMPP fetch */
import { useDataXMPP, useDataXMPP2, useDataXMPP3 } from '../../functions/fetchXMPP';
import { getArrOfNames } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

const PageHeatingSummer = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);

  //Načtení statusu Léto
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadSummerMode',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );  
  
  const [isSummer, setIsSummer] = useState(false);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      let result = true;  //výchozí stav - není letní režim
      
      if(resultXmpp === '' || resultXmpp === 'true')
        return;

      try {
        if(resultXmpp.length === 1) {
          result = parseBoolean(resultXmpp);
          //Logika je otočená:
          //0 je letní režim = není topná sezóna
          //1 není letní režim = je topná sezóna
          
        }
      }
      catch {
        result = true;
      }
      finally {
        //console.log('useEffect setIsSummer() ' + result);
        setIsSummer(!result);
      }
    }
    
  },[dataXmpp, isLoadingXmpp]);
  
  //----Načtení místností
  const defaultXmppObject2 = {
    typefce: 'get',
    fce: '/listOfRooms',
    param: ''
  }
  const [{ dataXmpp2, isLoadingXmpp2, isErrorXmpp2, errMsgXmpp2 }, doFetchXmpp2] = useDataXMPP2(
    defaultXmppObject2,
    '',
  );  
  const [listRooms, setListRooms] = useState([]);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp2) {
      let resultXmpp: string = dataXmpp2;
      let result = [];

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {  
        if(resultXmpp.length >= 13) {
          let dataList = getArrOfNames(resultXmpp, 13);
          let i = 0;
          dataList.forEach(row => {
            const reg = { 
              id: i,
              name: row,
            };
            result.push(reg);
            i ++;
          });
          setShowLoading(false);
        }
      }
      catch {
        result = [];
      }
      finally {
        setListRooms(result)
      }
    }
  },[dataXmpp2,isLoadingXmpp2]);

  //----Načtení statusů přiřazení Léto k místnostem
  const defaultXmppObject3 = {
    typefce: 'get',
    fce: '/letoLoadRooms',
    param: ''
  }
  const [{ dataXmpp3, isLoadingXmpp3, isErrorXmpp3, errMsgXmpp3 }, doFetchXmpp3] = useDataXMPP3(
    defaultXmppObject3,
    '',
  );
  
  const defaultListSummer = () => {
    let result = [];
    for(let i=0;i<32;i++){
      const reg = { 
        id: i,
        checked: false
      };
      result.push(reg);
    }
    return result;
  }
  const [listSummer, setListSummer] = useState(defaultListSummer);
  
  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp3) {
      let resultXmpp: string = dataXmpp3;
      let result = [];

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {  
        if(resultXmpp.length > 1) {
          let dataList = getArrOfNames(resultXmpp, 1);
          let i = 0;
          dataList.forEach(row => {
            let checked = parseBoolean(row);
            
            const reg = { 
              id: i,
              checked: checked,
            };
            result.push(reg);
            i ++;
          });
          setShowLoading(false);
        }
      }
      catch {
        result = [];
      }
      finally {
        setListSummer(result)
      }
    }
  },[dataXmpp3, isLoadingXmpp3]);


  const ListRooms = () => {
    return (
        <>
          {listRooms.map((item, index) => (
            <IonItem key={item.id}>
              <IonLabel>{item.name}</IonLabel>
              <IonToggle disabled={isErrorXmpp} checked={listSummer[item.id].checked} onIonChange={(e)=>handleOnCheckedRoom(e, item.id)}/>
            </IonItem>
          ))}
        </>
    );
  }

  const handleOnChange = (e) => {
    var v = e.detail.checked;
    setIsSummer(v);
  }

  const handleOnClickOK = () => {
    var p = '0';
    if(isSummer) {
      p = '0';
    }
    else {
      p = '1';
    }
    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveSummerMode',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );

    setShowToastSaved(true);
  }

  const handleOnCheckedRoom = (e, id:number) => {
    var v = e.detail.checked;
    let copy = JSON.parse(JSON.stringify(listSummer));
    copy[id].checked = v;
    setListSummer(copy);
  }

  const handleOnClickOKsummer = () => {
    
    let p = '';
    listSummer.forEach(row=>{
      if(row.checked) {
        p += '1';
      }
      else {
        p += '0';
      }
    })

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/letoSaveRooms',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );

    setShowToastSaved(true);
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>Zapnutím tzv. Letního režimu dojde u zařazených místností k nahrání požadované teploty:</p>
              <ul>
                <li>0°C pro elektrické vytápění</li>
                <li>99°C pro vodní vytápění, koncový člen KC64 vypne zdroj, o půlnoci dojde pravidelně k zavření a otevření ventilů proti zalehnutí</li>
              </ul>
            <p>Standardně všechny místnosti reagují na letní režim. Zvolené místnosti lze vyřadit z této funkce na druhé kartě.</p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

        <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />

        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" icon={sunny}/></IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          
          <IonCardContent>

                <IonCard>
                  <IonCardHeader>
                    <IonCardSubtitle>Aktivace letního režimu</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonLabel>{isSummer ? 'Léto zapnuto' : 'Léto vypnuto'}</IonLabel>
                        <IonToggle value="summer" disabled={isErrorXmpp} checked={isSummer} onIonChange={handleOnChange}/>
                      </IonItem>
                    </IonList>
                    <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOK}>Uložit</IonButton>
                  </IonCardContent>
                </IonCard>
                

                <IonCard>
                  <IonCardHeader>
                    <IonCardSubtitle>Přiřazení místností</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <ListRooms/>
                    </IonList>
                    <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOKsummer}>Uložit místnosti</IonButton>
                  </IonCardContent>
                </IonCard>

          </IonCardContent>

        </IonCard>
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
      </IonContent>  
    </IonPage>
  )
}
export default PageHeatingSummer;  
