//@ts-check

import React, { useState, useEffect } from "react";
import { getArrOfNames, log } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonText, IonContent, 
         IonToast, IonButton, IonList, IonItem, 
         IonSlides, IonSlide, IonSelect, IonSelectOption, 
         IonPopover, IonSegmentButton, IonSegment, IonSpinner, IonAlert, IonCol, IonCheckbox, IonChip, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { swapHorizontal, helpCircleOutline } from "ionicons/icons";
import { RouteComponentProps, useHistory } from "react-router";
import { useDataXMPP, useDataXMPP2 } from '../../functions/fetchXMPP';
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

interface UserDetailPageProps extends RouteComponentProps<{
    id: string;
    name: string;
}> {}
  
const PageHeatingRoomSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
    const h = useHistory();
    const deviceResource = useDeviceSelected();
    const [showLoading, setShowLoading] = useState(true);
    const [showHelp, setShowHelp] = useState(false);
    const [showToastSaved, setShowToastSaved] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isError, setIsError] = useState(false);

    const defaultXmppObject = {
        typefce: 'get',
        fce: '/listOfModes',
        param: ''
    }
    const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
        defaultXmppObject,
        '',
    );  
    const defaultXmppObjectRoomSet = {
        typefce: 'get',
        fce: '/roomSettings',
        param: prepareID(parseInt(match.params.id))
    }
    const [{ dataXmpp2, isLoadingXmpp2, isErrorXmpp2, errMsgXmpp2 }, doFetchXmpp2] = useDataXMPP2(
        defaultXmppObjectRoomSet,
        '',
    );
    const [listRegimes, setListRegimes] = useState([]);                     //Hook pro pole názvu režimů
    const [firstDay, setFirstDay] = useState('01'); //Hook pro začátek regulace (1. týden, 2. týden, 3. týden)
    //const [moreWeeks, setMoreWeeks] = useState(7); //Hook pro diagnostiku více týdenního cyklu. Udává počet týdnů v cyklu.
    const [checkedWeek2, setCheckedWeek2] = useState(false);
    const [checkedWeek3, setCheckedWeek3] = useState(false);
    
    const defaultArrayRegimesOfRoom1 = [
        { id: 0, value: -1, isFirstDay: false, dayname: 'PO' },
        { id: 1, value: -1, isFirstDay: false, dayname: 'ÚT' },
        { id: 2, value: -1, isFirstDay: false, dayname: 'ST' },
        { id: 3, value: -1, isFirstDay: false, dayname: 'ČT' },
        { id: 4, value: -1, isFirstDay: false, dayname: 'PA' },
        { id: 5, value: -1, isFirstDay: false, dayname: 'SO' },
        { id: 6, value: -1, isFirstDay: false, dayname: 'NE' },
    ];
    const defaultArrayRegimesOfRoom2 = [
        { id: 7, value: -1, isFirstDay: false, dayname: 'PO' },
        { id: 8, value: -1, isFirstDay: false, dayname: 'ÚT' },
        { id: 9, value: -1, isFirstDay: false, dayname: 'ST' },
        { id: 10, value: -1, isFirstDay: false, dayname: 'ČT' },
        { id: 11, value: -1, isFirstDay: false, dayname: 'PA' },
        { id: 12, value: -1, isFirstDay: false, dayname: 'SO' },
        { id: 13, value: -1, isFirstDay: false, dayname: 'NE' },
    ];
    const defaultArrayRegimesOfRoom3 = [
        { id: 14, value: -1, isFirstDay: false, dayname: 'PO' },
        { id: 15, value: -1, isFirstDay: false, dayname: 'ÚT' },
        { id: 16, value: -1, isFirstDay: false, dayname: 'ST' },
        { id: 17, value: -1, isFirstDay: false, dayname: 'ČT' },
        { id: 18, value: -1, isFirstDay: false, dayname: 'PA' },
        { id: 19, value: -1, isFirstDay: false, dayname: 'SO' },
        { id: 20, value: -1, isFirstDay: false, dayname: 'NE' }
    ];

    const [regimesOfRoom1, setRegimesOfRoom1] = useState(defaultArrayRegimesOfRoom1);         //Hook pro pole přiřazení režimů danému dni
    const [regimesOfRoom2, setRegimesOfRoom2] = useState(defaultArrayRegimesOfRoom2);
    const [regimesOfRoom3, setRegimesOfRoom3] = useState(defaultArrayRegimesOfRoom3);
    
    useEffect(function fce() {
        // 👍 We're not breaking the first rule anymore

        if (!isLoadingXmpp) {
          
          if(dataXmpp === '' || dataXmpp === 'true')
            return;

          let resutlXmpp: string = dataXmpp;
          let result = [];
    
          try {  
            if(resutlXmpp.length >= 13) {
              //'Rezim 1      Rezim 2      '
              let dataList = getArrOfNames(resutlXmpp, 13);
              dataList.unshift('Žádný  '); //Režim pro nepřiřazený režim

              let i = -1;
              dataList.forEach(row => {
                const reg = { 
                  id: i,
                  name: row,
                };
                result.push(reg);
                i ++;
              });
            }
          }
          catch {
            result = [];
          }
          finally {
            setListRegimes(result)
          }
        }

        return function cleanup() {
            log('Clean hook PageHeatingRoomSet 1', false);
        };
        
      },[dataXmpp, isLoadingXmpp]);

    useEffect(function fce() {
        // 👍 We're not breaking the first rule anymore

        if (!isLoadingXmpp2) {
          //Seznam dostupných režimů
          //Formát od řídící jednotky:
          //'0832000000000101-1-1-1-1-1-1-1-1-1-1-1-1-1-1';
          //'0100000000000133-1-1-1-1-1-1-1-1-1-1-1-1-1-1'
          //'0132-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1'
          //08 - začátek regulace
          //32 - režim 0 pro pondělí (+32 pro označení začátku hvězdičkou)
          //00|01|01|-1 cyklus režimů za sebou

          if (typeof dataXmpp2 === "undefined" || dataXmpp2 === '' || dataXmpp2 === 'true') 
            return;

            let result: string = dataXmpp2;
            result = result.padEnd(42, '-1');
            
            if(result.length > 2 ) {
                //0100000000320101-1-1-1-1-1-1-1-1-1-1-1-1-1-1
                var _firstDay = result.substr(0,2);
                setStartWeek(_firstDay);

                var _r = getArrOfNames(result.slice(2),2); //ID režimů za sebou do 3x7dnů=21 
                //Převést pole režimů na čísla
                let _rInt = [];
                for (let index = 0; index < _r.length; index++) {
                    const element = parseInt(_r[index]);
                    _rInt.push(element);
                    
                }

                //Zjištění počtu cyklických týdnů
                //První týden se naplní režimy vždy
                let clone1 = JSON.parse(JSON.stringify(defaultArrayRegimesOfRoom1));
                for(let i=0; i < Object.keys(clone1).length; i++)
                {
                    let a: number = _rInt[i];
                    let b: boolean = false;
                    //Začátek regulace
                    if (a >= 32) {
                        b = true;
                        a = a - 32;
                    }
                    clone1[i].value = a;
                    clone1[i].isFirstDay = b;
                }
                setRegimesOfRoom1(clone1);

                if(_rInt[7] === -1 && _rInt[14] === -1)
                {
                    log('1-týdenní cyklus', false);
                    //setMoreWeeks(7);
                    
                }
                if(_rInt[7] > -1 && _rInt[14] === -1)
                {
                    log('2-týdenní cyklus', false);
                    //setMoreWeeks(14);
                    setCheckedWeek2(true);

                    let clone2 = JSON.parse(JSON.stringify(defaultArrayRegimesOfRoom2));
                    for(let i=0; i < Object.keys(clone2).length; i++)
                    {
                        let a: number = _rInt[i+7];
                        let b: boolean = false;
                        //Začátek regulace
                        if (a >= 32) {
                            b = true;
                            a = a - 32;
                        }
                        clone2[i].value = a;
                        clone2[i].isFirstDay = b;
                    }
                    setRegimesOfRoom2(clone2);

                }
                if(_rInt[7] > -1 && _rInt[14] > -1)
                {
                    log('3-týdenní cyklus', false);
                    //setMoreWeeks(21);
                    setCheckedWeek3(true);

                    let clone2 = JSON.parse(JSON.stringify(defaultArrayRegimesOfRoom2));
                    for(let i=0; i < Object.keys(clone2).length; i++)
                    {
                        let a: number = _rInt[i+7];
                        let b: boolean = false;
                        //Začátek regulace
                        if (a >= 32) {
                            b = true;
                            a = a - 32;
                        }
                        clone2[i].value = a;
                        clone2[i].isFirstDay = b;
                    }
                    setRegimesOfRoom2(clone2);

                    let clone3 = JSON.parse(JSON.stringify(defaultArrayRegimesOfRoom3));
                    for(let i=0; i < Object.keys(clone3).length; i++)
                    {
                        let a: number = _rInt[i+14];
                        let b: boolean = false;
                        //Začátek regulace
                        if (a >= 32) {
                            b = true;
                            a = a - 32;
                        }
                        clone3[i].value = a;
                        clone3[i].isFirstDay = b;
                    }
                    setRegimesOfRoom3(clone3);

                }
                
                setShowLoading(false);
            }
        }
        
        return function cleanup() {
            log('Clean hook PageHeatingRoomSet 2', false);
        };

      },[dataXmpp2, isLoadingXmpp2]);

    const slideOpts = {
        initialSlide: 0,
        pager: true,
        stretch: 0,
        speed: 0,
        freemode: true
    };

    useEffect(function fce() {
        setRegimesOfRoom1(defaultArrayRegimesOfRoom1);
        setRegimesOfRoom2(defaultArrayRegimesOfRoom2);
        setRegimesOfRoom3(defaultArrayRegimesOfRoom3);
        
        setIsRemoving(false);
    },[isRemoving]);

    const RenderItemsOption = () => {
        return (
            <>
              {listRegimes.map((item, index) => (
                  <IonSelectOption value={item.id} key={index}>{item.name}</IonSelectOption>
              ))}
            </>
        );
    }

    const handleOnChangeSegmentButton = (e) => {
        setStartWeek(e.detail.value);
    }

    function setStartWeek(s: string) {
        setFirstDay(s);
    }

    const handleOnSelectItem = (e, id:number) => {
        log('PageHeatingRoomSet-handleOnSelectItem()', false);

        if(id===0){
            if(!isRemoving) {
                let copy = JSON.parse(JSON.stringify(regimesOfRoom1));
                copy[id].value = parseInt(e.detail.value);
                setRegimesOfRoom1(copy);
            }
            else {
                setIsRemoving(false);
            }
        }
        if(id>0 && id<7) {
            //1. týden
            if(regimesOfRoom1[id-1].value===-1) {
                setIsError(true);
                return;
            }
            if(!isRemoving) {
                let copy = JSON.parse(JSON.stringify(regimesOfRoom1));
                copy[id].value = parseInt(e.detail.value);
                setRegimesOfRoom1(copy);
            }
            else {
                setIsRemoving(false);
            }
        }
        else if(id>6 && id<14) {
            //2. týden
            if(id===7 && regimesOfRoom1[6].value===-1) {
                setIsError(true);
                return;
            }

            if(id>7 && regimesOfRoom2[id-8].value===-1) {
                setIsError(true);
                return;
            }
            if(!isRemoving) {
                let copy = JSON.parse(JSON.stringify(regimesOfRoom2));
                copy[id-7].value = parseInt(e.detail.value);
                setRegimesOfRoom2(copy);
            }
            else {
                setIsRemoving(false);
            }
        }
        else if(id>13 && id<21) {
            //3. týden
            if(id===14 && regimesOfRoom2[6].value===-1) {
                setIsError(true);
                return;
            }
            if(id>14 && regimesOfRoom3[id-15].value===-1) {
                setIsError(true);
                return;
            }
            if(!isRemoving) {
                let copy = JSON.parse(JSON.stringify(regimesOfRoom3));
                copy[id-14].value = parseInt(e.detail.value);
                setRegimesOfRoom3(copy);
            }
            else {
                setIsRemoving(false);
            }
        }
    }

    const handleOnCheckedWeek2 = (e) => {
        if(e.detail.checked) {
            setCheckedWeek2(true);
        }
        else {
            setFirstDay('01');
            setCheckedWeek2(false);
            setCheckedWeek3(false);
            setRegimesOfRoom2(defaultArrayRegimesOfRoom2);
            setRegimesOfRoom3(defaultArrayRegimesOfRoom3);
        }
    }

    const handleOnCheckedWeek3 = (e) => {
        if(e.detail.checked) {
            if(!checkedWeek2) {
                setCheckedWeek2(true);    
            }
            else {
                setCheckedWeek3(true);
            }
        }
        else {
            setFirstDay('01');
            setCheckedWeek3(false);
            setRegimesOfRoom3(defaultArrayRegimesOfRoom3);
        }
    }

    const handleOnClickOK = () => {
        //000100-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1
        //000100000000000101-1-1-1-1-1-1-1-1-1-1-1-1-1-1
        //• ID místnosti (2 znaky)
        //• počáteční den (2 znaky, pouze 01,08,15)
        //• id režimů jednotlivých dnů (každý režim 2 znaky).  '-1' značí nepřiřazený režim a je doplněn do 21 dnů. 

        let id = prepareID(parseInt(match.params.id));

        let reg = '';
        for(let i = 0; i < Object.keys(regimesOfRoom1).length; i++) {
            reg += prepareID(regimesOfRoom1[i].value);
        };
        for(let i = 0; i < Object.keys(regimesOfRoom2).length; i++) {
            reg += prepareID(regimesOfRoom2[i].value);
        };
        for(let i = 0; i < Object.keys(regimesOfRoom3).length; i++) {
            reg += prepareID(regimesOfRoom3[i].value);
        };
        reg = reg.padEnd(42, '-1');
        let p = id + firstDay + reg;
    
        const saveXmppObject = {
        typefce: 'SET',
        fce: '/saveAssignmentModes',
        param: p
        }
        doFetchXmpp(
            saveXmppObject,
        );

        setShowToastSaved(true);
    }

    const Render7daysItems = (props: any) => {
        
        return (
            <>
            {props.arrList.map((item, index) => (
                <IonItem key={item.id} color={item.isFirstDay ? 'tertiary' : ''}>
                    <IonCol>
                        <IonButton disabled={item.value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+item.value+'/'+item.value)}}>{item.dayname}:</IonButton>
                    </IonCol>
                    <IonCol>
                        <IonSelect value={item.value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, item.id)} >
                            <RenderItemsOption/>
                        </IonSelect>
                    </IonCol>
                </IonItem>
            ))}
            </>
        )
    }

    //<IonButton routerLink='/tabheating/pageheatingroomlist'> <IonIcon icon={arrowBack}></IonIcon>Zpět1</IonButton>

    return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating/pageheatingroomlist" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

      <IonPopover
        isOpen={showHelp}
        onDidDismiss={e => setShowHelp(false)}
      >
        <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
              Danému dni v týdnu se musí přiřadit topný režim. 
              Režimy se opakují v cyklech. 
              Pokud vyplníte pouze režim na pondělí, bude se tento režim opakovat každý den. 
              Pokud vyplníte pondělí-neděle, bude se opakovat celý týden.<br></br>
              Celý cyklus může být až tří-týdenní. Při jeho nastavení se specifikuje počáteční týden.
            </p>
            <p>Barevný řádek označuje aktuálně platný den při vícedenním cyklu.</p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
        </IonCard>
      </IonPopover>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Potvrzení'}
          message={'Opravdu chcete smazat všechna přiřazení?'}
          buttons={[
            {
              text: 'NE',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                //console.log('Confirm Cancel: blah');
              }
            },
            {
              text: 'ANO',
              handler: () => setIsRemoving(true)
            }
          ]}
      />

      <IonCard className="welcome-card" >
        <IonCardHeader>
          <IonCardTitle>Přiřazení režimů místnosti <IonIcon color="medium" icon={swapHorizontal}/></IonCardTitle>
          <IonCardSubtitle></IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem color='primary'>{match.params.name}</IonItem>

          <IonSlides pager={true} options={slideOpts}>
          <IonSlide>
              <IonCard>
                <IonCardHeader>
                <IonChip><IonLabel> Přiřazení režimů pro 1. týden</IonLabel></IonChip>
                </IonCardHeader>
                <IonCardContent>
                  <IonList>
                    <Render7daysItems arrList={regimesOfRoom1}></Render7daysItems>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonSlide>
            <IonSlide>
              <IonCard>
                <IonCardHeader>
                    <IonChip><IonCheckbox checked={checkedWeek2} onIonChange={handleOnCheckedWeek2}></IonCheckbox>&nbsp;<IonLabel>Přiřazení režimů pro 2. týden</IonLabel></IonChip>
                </IonCardHeader>
                <IonCardContent>
                  <IonList>
                    {checkedWeek2 ? <Render7daysItems arrList={regimesOfRoom2}></Render7daysItems> : ''}
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonSlide>
            <IonSlide>
              <IonCard>
                <IonCardHeader>
                <IonChip><IonCheckbox checked={checkedWeek3} onIonChange={handleOnCheckedWeek3}></IonCheckbox>&nbsp;<IonLabel>Přiřazení režimů pro 3. týden</IonLabel></IonChip>
                </IonCardHeader>
                <IonCardContent>
                  <IonList>
                    {checkedWeek3 ? <Render7daysItems arrList={regimesOfRoom3}></Render7daysItems> : ''}
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonSlide>
          </IonSlides>
            
          <IonText color='primary' class='ion-align-items-center'>Začátek regulace pro vícetýdenní cyklus: </IonText>
            <IonSegment value={firstDay} onIonChange={handleOnChangeSegmentButton}>
                <IonSegmentButton value='01'>
                    <IonLabel>1.týden</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton disabled={!checkedWeek2} value='08'>
                    <IonLabel>2.týden</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton  disabled={!checkedWeek3} value='15'>
                    <IonLabel>3.týden</IonLabel>
                </IonSegmentButton>
            </IonSegment>

            <IonButton expand="block" disabled={isLoadingXmpp2} onClick={handleOnClickOK}>Uložit</IonButton>
            <IonLabel>&nbsp;</IonLabel>
            <IonButton expand="block" disabled={isLoadingXmpp2} onClick={()=>setShowAlert(true)}>Smazat všechna přiřazení</IonButton>
            </IonCardContent>          
        </IonCard>

        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        <IonToast
          isOpen={isErrorXmpp2}
          message={errMsgXmpp2}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        <IonToast
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          message='Předcházející režim nesmí být prázdný!'
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        </IonContent>  
    </IonPage>
    )
  }
  export default PageHeatingRoomSet;  

  
/**
 * <IonItem color={regimesOfRoom[0].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[0].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[0].value+'/'+regimesOfRoom[0].value)}}>PO:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[0].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 0)} >
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[1].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[1].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[1].value+'/'+regimesOfRoom[1].value)}}>ÚT:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[1].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 1)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[2].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[2].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[2].value+'/'+regimesOfRoom[2].value)}}>ST:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[2].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 2)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[3].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[3].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[3].value+'/'+regimesOfRoom[3].value)}}>ČT:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[3].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 3)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[4].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[4].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[4].value+'/'+regimesOfRoom[4].value)}}>PA:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[4].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 4)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[5].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[5].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[5].value+'/'+regimesOfRoom[5].value)}}>SO:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[5].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 5)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[6].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[6].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[6].value+'/'+regimesOfRoom[6].value)}}>NE:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[6].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 6)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
 */

 /**
  * <IonSlide>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle></IonCardTitle>
                        <IonCardSubtitle>Přiřazení režimů pro 2. týden</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonList>
                            <IonItem color={regimesOfRoom[7].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[7].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[7].value+'/'+regimesOfRoom[7].value)}}>PO:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[7].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 7)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[8].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[8].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[8].value+'/'+regimesOfRoom[8].value)}}>ÚT:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[8].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 8)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[9].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[9].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[9].value+'/'+regimesOfRoom[9].value)}}>ST:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[9].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 9)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[10].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[10].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[10].value+'/'+regimesOfRoom[10].value)}}>ČT:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[10].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 10)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[11].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[11].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[11].value+'/'+regimesOfRoom[11].value)}}>PA:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[11].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 11)} >
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[12].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[12].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[12].value+'/'+regimesOfRoom[12].value)}}>SO:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[12].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 12)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[13].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[13].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[13].value+'/'+regimesOfRoom[13].value)}}>NE:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[13].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 13)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                        </IonList>
                        </IonCardContent>
                    </IonCard>
                </IonSlide>
                <IonSlide>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle></IonCardTitle>
                        <IonCardSubtitle>Přiřazení režimů pro 3. týden</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonList>
                            <IonItem color={regimesOfRoom[14].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[14].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[14].value+'/'+regimesOfRoom[14].value)}}>PO:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[14].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 14)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[15].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[15].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[15].value+'/'+regimesOfRoom[15].value)}}>ÚT:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[15].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 15)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[16].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[16].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[16].value+'/'+regimesOfRoom[16].value)}}>ST:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[16].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 16)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[17].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[17].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[17].value+'/'+regimesOfRoom[17].value)}}>ČT:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[17].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 17)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[18].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[18].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[18].value+'/'+regimesOfRoom[18].value)}}>PA:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[18].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 18)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[19].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[19].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[19].value+'/'+regimesOfRoom[19].value)}}>SO:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[19].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 19)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                            <IonItem color={regimesOfRoom[20].isFirstDay ? 'tertiary' : 'primary'}>
                                <IonCol>
                                    <IonButton disabled={regimesOfRoom[20].value === -1} onClick={(e) => { e.preventDefault(); h.push('/tabheating/pageheatingregimelist/regime/'+regimesOfRoom[20].value+'/'+regimesOfRoom[20].value)}}>NE:</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonSelect value={regimesOfRoom[20].value} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 20)}>
                                        <RenderItemsOption/>
                                    </IonSelect>
                                </IonCol>
                            </IonItem>
                        </IonList>
                        </IonCardContent>
                    </IonCard> 
                </IonSlide>
  */

  //arrReduced = regimesOfRoom1.filter((obj)=>{return obj.id < 7});