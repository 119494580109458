//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail, DatetimeChangeEventDetail } from '@ionic/core';
import { compareTime, replaceDiacritic, prepareID, prepareSaveTemp, prepareTimeColon, log } from '../../functions/fce-string';
import { IonDateTimeRegime, IonRangeRegime } from '../../functions/myReactComponents';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle,
         IonIcon, IonContent,
         IonToast, IonButton, IonList, IonItem, IonRefresher, IonRefresherContent,
         IonInput, IonFab, IonFabButton, IonBadge, IonPopover, IonSpinner, IonAlert
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { add, remove, helpCircleOutline, options } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { RouteComponentProps } from "react-router";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

interface UserDetailPageProps extends RouteComponentProps<{
    id: string;
    name: string;
}> {}

const PageHeatingRegimeSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);

  const defaultXmppObject = {
      typefce: 'get',
      fce: '/loadMode',
      param: prepareID(parseInt(match.params.id))
  }

  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
      defaultXmppObject,
      '',
  );  
  
  //PONE         00:0000505:0000508:0000515:0000522:00005
  
  const hours: string = '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23';
  const minutes : string = '0,5,10,15,20,25,30,35,40,45,50,55';

  const defaultArrRegimes = [
    {time: '00:00', temp: 0, disabled: true, hourValues: hours, minuteValues: minutes},
    {time: '00:00', temp: 0, disabled: true, hourValues: '0', minuteValues: '0'},
    {time: '00:00', temp: 0, disabled: true, hourValues: '0', minuteValues: '0'},
    {time: '00:00', temp: 0, disabled: true, hourValues: '0', minuteValues: '0'},
    {time: '00:00', temp: 0, disabled: true, hourValues: '0', minuteValues: '0'},
    {time: '00:00', temp: 0, disabled: true, hourValues: '0', minuteValues: '0'},
    {time: '00:00', temp: 0, disabled: true, hourValues: '0', minuteValues: '0'},
    {time: '00:00', temp: 0, disabled: true, hourValues: '0', minuteValues: '0'}
  ]
  const [regimeName, setRegimeName] = useState('');
  const [regimes, setRegimes] = useState(defaultArrRegimes);
  const [count, setCount] = useState(0);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      let resultRegimeName = '';  
      
      if(resultXmpp === '' || resultXmpp === 'true')
        return;

      let clone = defaultArrRegimes;
      try {
        if(resultXmpp.length >= 13) {
          //Rezim  2     00:0001805:00024
          resultRegimeName = resultXmpp.substr(0, 13).trim();
          var s = resultXmpp.substr(13, resultXmpp.length);
          var arr = [];   //pole režimů
          var n = 8;      //čas spuštění (5 znaků) + nastavená teplota (4 znaky) 

          for (let i = 0, len = s.length; i < len; i += n) {
            arr.push(s.substr(i, n))
          }

          // Režimy získané z HC64
          var arrRegimesFromUnit = [];
          arr.forEach(row => {
            const reg = { 
              time: row.substr(0,5),
              temp: parseInt(row.substr(5,3))
            };
            arrRegimesFromUnit.push(reg);
          });

          //Do výchozího pole režimů zapsat data z HC64
          //var clone = Object.assign({}, defaultArrRegimes);
          
          let i = 0;
          //V první kole pouze přiřadit časy a teploty
          arrRegimesFromUnit.forEach(row => {
            clone[i].temp = row.temp;
            clone[i].time = row.time;
        
            if(i > 0) {
              clone[i].disabled = false;
            }
            i++;
          })
          //Ve druhém kole nastavit min, max rozsahy časů
          i = 0;
          arrRegimesFromUnit.forEach(row => {
            if(i > 0) {
              let _t = [];
              if (i < 7) {
                  _t = allowedTime(clone[i-1].time, clone[i+1].time);
              }
              else {
                  _t = allowedTime(clone[i-1].time, '24:00');
              }
              clone[i].hourValues = _t[0];
              clone[i].minuteValues = _t[1];
            }
            i++;
          })
          setShowLoading(false);
        }
      }
      catch {
        log('catch PageHeatingRegimeSet', true);
        resultRegimeName = '';
        clone = defaultArrRegimes;
      }
      finally {
        //console.log('finally PageHeatingRegimeSet: ' + clone);
        setRegimeName(resultRegimeName);
        setRegimes(clone);
      }
    }

  },[dataXmpp, isLoadingXmpp]);

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
      doFetchXmpp(
        defaultXmppObject,
      );
      e.detail.complete();
  }

  const handleOnChangeInput = (e) => {
      var t = replaceDiacritic(e.detail.value);
      setRegimeName(t);
  }

  /*
  const handleOnChangeStartTime1 = (e : CustomEvent<DatetimeChangeEventDetail>) => {
    //Nepoužito, první čas je vždy 00:00
  }
  */
 const handleOnChangeStartTime = (e : CustomEvent<DatetimeChangeEventDetail>, idRegime : number) => {
  if(count > 0) {
  let copy = JSON.parse(JSON.stringify(regimes));
    //console.log('handleOnChangeStartTime, idRegime: ' + idRegime + ' e.detail.value: ' + e.detail.value);  
    //Vynechat úvodní průchody události
    if(e.detail.value === '00:00') {
      //Překleslení React inicializace hodnot
    }
    else if((e.detail.value === '00:00') && (copy[idRegime-1].time === '00:00')) {
      //Překleslení React inicializace hodnot
    }
    else {
      let dt = compareTime(e.detail.value, copy[idRegime-1].time);
      copy[idRegime].time = dt;
    }

    setRegimes(copy);

  }
  else 
  {
    setCount(1);
  }

  /*
  //Vynechat úvodní tři průchody události
  if(count > 0) {
    let copy = JSON.parse(JSON.stringify(regimes));
    let dt = compareTime(e.detail.value, copy[idRegime-1].time);
    copy[idRegime].time = dt;

    setRegimes(copy);
  }
  else {
    setCount(count);
  }
  */
}

const handleOnSetRange = (e, idRegime : number) => {
  if(count > 0) {
    let copy = JSON.parse(JSON.stringify(regimes));
    copy[idRegime].temp = parseInt(e.detail.value);
    setRegimes(copy);
  }
  else 
  {
    setCount(1);
  }
}
  useEffect(function fce() {
    setRegimes(defaultArrRegimes);
    setIsRemoving(false);
},[isRemoving]);

  const handleOnClickAdd = () => {
    var clone = Object.assign({}, regimes);
    //1. změna [0] prvek v poli se nemění
    //Hledám od začátku první řádek disabled a povolím ho a nastavím výchozí teplotu, čas předchozí změny, min datetime
    for(let i = 1; i < 8; i++) {
      const row = {
          time: clone[i].time,
          temp: clone[i].temp,
          disable: clone[i].disabled
      }

      if(row.disable)
      {
        clone[i].disabled = false;
        clone[i].temp = 20;
        clone[i].time = clone[i-1].time;

        var t = allowedTime(clone[i].time, '24:00');
        clone[i].hourValues = t[0];
        clone[i].minuteValues = t[1];

        break;
      }
    }

    setRegimes(clone);
  }

  const handleOnClickRemove = () => {
    var clone = Object.assign({}, regimes);
    //1. změna [0] prvek v poli se nemění
    //Hledám od konce první řádek enabled a zakážu ho nastavím výchozí teplotu a čas
    for(let i = 7; i > 0; i--) {
      if ( !clone[i].disabled ) {
          clone[i].disabled = true;
          clone[i].temp = 0;
          clone[i].time = '00:00';
          clone[i].hourValues = '0';
          clone[i].minuteValues = '0';
          
          //předposlednímu režimu nastavit rozsah času dle položky ještě před ní
          //mimo druhé a první položky
          if(i>2) {
              var tprev = allowedTime(clone[i-2].time, '24:00');
              clone[i-1].hourValues = tprev[0];
              clone[i-1].minuteValues = tprev[1];
          }

          break;
      }
    }

    setRegimes(clone);
  }

  function allowedTime(time1: string, time2: string) {
    let h1: number = parseInt(time1.substr(0,2));
    let m1: number = parseInt(time1.substr(3,2));
    var _h1: string = '';
    var _m1: string = '';

    let h2: number = parseInt(time2.substr(0,2));
    let m2: number = parseInt(time2.substr(3,2));

    if(time2 === '24:00')
    {
        h2 = 24;
        m2 = 60;
    }

    if (m1 === 55 ) {
        //Navýší se hodina
        let i = h1 + 1;
        for (i; i < h2; i++) {
            _h1 = _h1 + i.toString() + ',';
        }
        //minuty zůstanou všechny
        for (let i = 0; i < m2; i+=5) {
            _m1 = _m1 + i.toString() + ',';
        }
    }
    else {
        //Hodina zůstane shodná
        for (h1; h1 < h2; h1++) {
            _h1 = _h1 + h1.toString() + ',';
        }
        //Navýší se minuty od poslední
        for (m1; m1 < m2; m1+=5) {
            _m1 = _m1 + m1.toString() + ',';
        }
    }
    
    //Odstranit poslední znak ','
    _h1 = _h1.slice(0,-1);
    _m1 = _m1.slice(0,-1);

    return [_h1, _m1];
  }

  const handleOnClickOK = () => {
    //00Rezim  1     00:00020
    //00Rezim  1     00:00030
    //00Rezim  1     00:0000002:0001205:00026
    //!!! Místo : se musí použít '%3A'
    let p = prepareID(parseInt(match.params.id));
    p += regimeName.padEnd(13,'+');
    p += prepareTimeColon(regimes[0].time);
    p += prepareSaveTemp(regimes[0].temp);

    for(let i=1; i<8; i++) {
      if(regimes[i].time !=='00:00') {
        p += prepareTimeColon(regimes[i].time);
        p += prepareSaveTemp(regimes[i].temp);
      }
    }

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveMode',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );
    
    setShowToastSaved(true);
  }
    
  
  return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating/pageheatingregimelist" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      
      <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
      </IonRefresher>
      
      <IonPopover
        isOpen={showHelp}
        onDidDismiss={e => setShowHelp(false)}
      >
        <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Nastavení teplotního režimu. Je k dispozici až 8 změn za den. První režim vždy začíná v čase 00:00.
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
        </IonCard>
      </IonPopover>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Potvrzení'}
          message={'Opravdu chcete smazat celý režim?'}
          buttons={[
            {
              text: 'NE',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                //console.log('Confirm Cancel: blah');
              }
            },
            {
              text: 'ANO',
              handler: () => setIsRemoving(true)
            }
          ]}
      />

      <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>Nastavení režimu <IonIcon color="medium" icon={options}/></IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
          <IonItem color='primary'>
          <IonInput type='text' maxlength={13} value={regimeName} onIonChange={handleOnChangeInput}></IonInput>
        </IonItem>
        <IonList>
          <IonItem>
              <IonBadge>1:</IonBadge>
              <IonDateTimeRegime value={regimes[0].time} hourValues={regimes[0].hourValues} minuteValues={regimes[0].minuteValues} disabled={true}></IonDateTimeRegime>
              <IonRangeRegime value={regimes[0].temp} onIonChange={(e)=>handleOnSetRange(e, 0)} disabled={false}></IonRangeRegime>
              <IonLabel>{regimes[0].temp}°C</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>2:</IonBadge>
              <IonDateTimeRegime value={regimes[1].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 1)} hourValues={regimes[1].hourValues} minuteValues={regimes[1].minuteValues} disabled={regimes[1].disabled} ></IonDateTimeRegime>
              <IonRangeRegime value={regimes[1].temp} onIonChange={(e)=>handleOnSetRange(e, 1)} disabled={regimes[1].disabled}></IonRangeRegime>
              <IonLabel>{regimes[1].temp}°C</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>3:</IonBadge>
              <IonDateTimeRegime value={regimes[2].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 2)} hourValues={regimes[2].hourValues} minuteValues={regimes[2].minuteValues} disabled={regimes[2].disabled} ></IonDateTimeRegime>
              <IonRangeRegime value={regimes[2].temp} onIonChange={(e)=>handleOnSetRange(e, 2)} disabled={regimes[2].disabled}></IonRangeRegime>
              <IonLabel>{regimes[2].temp}°C</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>4:</IonBadge>
              <IonDateTimeRegime value={regimes[3].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 3)} hourValues={regimes[3].hourValues} minuteValues={regimes[3].minuteValues} disabled={regimes[3].disabled} ></IonDateTimeRegime>
              <IonRangeRegime value={regimes[3].temp} onIonChange={(e)=>handleOnSetRange(e, 3)} disabled={regimes[3].disabled}></IonRangeRegime>
              <IonLabel>{regimes[3].temp}°C</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>5:</IonBadge>
              <IonDateTimeRegime value={regimes[4].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 4)} hourValues={regimes[4].hourValues} minuteValues={regimes[4].minuteValues} disabled={regimes[4].disabled} ></IonDateTimeRegime>
              <IonRangeRegime value={regimes[4].temp} onIonChange={(e)=>handleOnSetRange(e, 4)} disabled={regimes[4].disabled}></IonRangeRegime>
              <IonLabel>{regimes[4].temp}°C</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>6:</IonBadge>
              <IonDateTimeRegime value={regimes[5].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 5)} hourValues={regimes[5].hourValues} minuteValues={regimes[5].minuteValues} disabled={regimes[5].disabled} ></IonDateTimeRegime>
              <IonRangeRegime value={regimes[5].temp} onIonChange={(e)=>handleOnSetRange(e, 5)} disabled={regimes[5].disabled}></IonRangeRegime>
              <IonLabel>{regimes[5].temp}°C</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>7:</IonBadge>
              <IonDateTimeRegime value={regimes[6].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 6)} hourValues={regimes[6].hourValues} minuteValues={regimes[6].minuteValues} disabled={regimes[6].disabled} ></IonDateTimeRegime>
              <IonRangeRegime value={regimes[6].temp} onIonChange={(e)=>handleOnSetRange(e, 6)} disabled={regimes[6].disabled}></IonRangeRegime>
              <IonLabel>{regimes[6].temp}°C</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>8:</IonBadge>
              <IonDateTimeRegime value={regimes[7].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 7)} hourValues={regimes[7].hourValues} minuteValues={regimes[7].minuteValues} disabled={regimes[7].disabled} ></IonDateTimeRegime>
              <IonRangeRegime value={regimes[7].temp} onIonChange={(e)=>handleOnSetRange(e, 7)} disabled={regimes[7].disabled}></IonRangeRegime>
              <IonLabel>{regimes[7].temp}°C</IonLabel>
          </IonItem>
        </IonList>

        <IonFab vertical="top" horizontal="end" edge slot="fixed" >
            <IonFabButton color="primary" size='small' onClick={handleOnClickAdd}>
                <IonIcon icon={add} />
            </IonFabButton>
            <IonFabButton color="danger" size='small' onClick={handleOnClickRemove}>
                <IonIcon icon={remove} />
            </IonFabButton>
        </IonFab>

        <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOK}>Uložit</IonButton>
        <IonLabel>&nbsp;</IonLabel>
        <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={()=>setShowAlert(true)}>Smazat režim</IonButton>
        
      </IonCardContent>
    </IonCard>
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        </IonContent>  
    </IonPage>
    )
  }
  export default PageHeatingRegimeSet;  

  /*
  <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={()=>setShowAlert(true)}>Smazat všechny režimy</IonButton>
  <IonIcon size="small" slot="start" icon={thermometer}/>
  <IonIcon slot="end" icon={thermometer}/>

  {match.params.name} {match.params.id} 
  <IonListHeader>ČASOVÉ ZMĚNY</IonListHeader>
  */
