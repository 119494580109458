import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { setupConfig } from '@ionic/react';

//IonicModule.forRoot({animated: false});

setupConfig({
  rippleEffect: false,
  animated: true

});

ReactDOM.render(<App />, document.getElementById('root'));
