//@ts-check

import { IonPage,  IonContent,  IonHeader,  IonIcon,  IonItem,
  IonLabel,  IonList,  IonTitle,  IonToolbar, IonButtons, IonButton, IonBadge
} from '@ionic/react';
import { options, moon, sunny, thermometer, swapHorizontal, informationCircle, 
  trendingDown, handRight, ellipsisVertical } from 'ionicons/icons';
import React from 'react';
import './TabHeating.css';

import { XmppOnlineContext } from '../context/useXmppOnline';
import { useAuth } from '../context/useAuth';
import { useHistory } from 'react-router';
import { useDeviceSelected } from '../context/useDeviceSelectedContext';

interface IAppProps {};

const TabHeating: React.FunctionComponent<IAppProps> = (props) => {
  const h = useHistory();
  const { setAuthTokens } = useAuth();
  const deviceResource = useDeviceSelected();
 
  const handleOnClick = (e) => {
    e.preventDefault(); 
    h.push("/deviceslist");
  }

  return (
    <XmppOnlineContext.Consumer>{useXmppOnline => (
    
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot='start'>Regulace vytápění</IonTitle><IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={handleOnClick}><IonIcon icon={ellipsisVertical}></IonIcon></IonButton>  
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
        <IonList lines="none" >
          
          <IonItem routerLink="/tabheating/pageheatinginforoom" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={informationCircle} />
            <IonLabel>
              <h2>Informace o místnostech</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatingmanuallist" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={handRight} />
            <IonLabel>
              <h2>Ruční změna teploty</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatinglow" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={moon} />
            <IonLabel>
              <h2>Útlum vytápění</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatingsummer" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={sunny} />
            <IonLabel>
              <h2>Letní režim</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatingregimelist" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={options} />
            <IonLabel>
              <h2>Teplotní režimy</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatingroomlist" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={swapHorizontal} />
            <IonLabel>
              <h2>Přiřazení režimů místnostem</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatingroommaxlist" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={thermometer} />
            <IonLabel>
              <h2>Maximální změna teploty</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatingwtr" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={thermometer} />
            <IonLabel>
              <h2>Venkovní teplota</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatingfloorlist" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={thermometer} />
            <IonLabel>
              <h2>Minimální teplota podlahy</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatinghdo" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={trendingDown} />
            <IonLabel>
              <h2>Informace o tarifu</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabheating/pageheatingkcnet" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={informationCircle} />
            <IonLabel>
              <h2>Status koncových členů</h2>
            </IonLabel>
          </IonItem>

        </IonList>
      </IonContent>
    </IonPage>
    )}
    </XmppOnlineContext.Consumer>
  );
};

export default TabHeating;
