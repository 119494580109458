//@ts-check

import React, { useState, useLayoutEffect } from "react";
import { useHistory } from 'react-router';
import { RefresherEventDetail } from '@ionic/core';
import { parseBoolean } from '../../functions/fce-math';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonContent,
         IonList, IonItem, IonLabel, 
         IonToast, IonNote, IonIcon, IonGrid, IonRow, IonCol, 
         IonText, IonRefresher, IonRefresherContent, IonCard, 
         IonCardHeader, IonCardTitle, IonCardContent, IonPopover, IonButton, IonSpinner, IonItemSliding, IonItemOptions, IonItemOption, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { informationCircle, handRight, helpCircleOutline } from "ionicons/icons";
import central from "../../img/central.svg";
import roller from "../../img/roller.svg";
import shutter from "../../img/shutter.svg";
import shutterdisable from "../../img/shutter-disable.svg";


import { useDataXMPP } from '../../functions/fetchXMPP';
import { useFetchRoomsXMPP } from "../../functions/fetchRoomsXMPP";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";


const PageRollerInfoRoom = (props : any) => {  
  //debugger;
  const h = useHistory();
  const deviceResource = useDeviceSelected();
  const [showHelp, setShowHelp] = useState(false);

  //Počet místností
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/numOfRollerShutters',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );  

  useLayoutEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp && dataXmpp !=='') {
      //console.log('dataXmpp ' + dataXmpp );
      doFetchXmppListRooms(
        defaultXmppObjectListRooms,
      );
    }
  },[dataXmpp, isLoadingXmpp]);
 

  //List místností
  const defaultXmppObjectListRooms = {
    typefce: 'get',
    fce: '/moreRollerShutter',
    param: dataXmpp
  }
  const [{ dataXmppListRooms, isLoadingXmppListRooms, isErrorXmppListRooms, errMsgXmppListRooms }, doFetchXmppListRooms] = useFetchRoomsXMPP(
    defaultXmppObjectListRooms,
    [], 33
  );  
  const doFetchXmppListRoomsRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmppListRooms(
      defaultXmppObjectListRooms,
    );
    e.detail.complete();
  }
  
  const RollerIcon = (props : any) => {
    var icon = '';
    switch (props.typeroller) {
      case 0: icon = shutterdisable;
        break;
      case 1: icon = roller;
        break;
      case 2: icon = shutter;
        break;
    }
    return (
      <IonIcon icon={icon}></IonIcon>
    )
  }

  const RollerPositionProc = (props : any) => {
    let textRotate = '';
    if(props.typeroller === 2){
      textRotate = '/' + (props.rotate);
    }

    let textManual = '';
    if(props.isManual) {
      textManual += ' (' + props.manualPosition +'%';
      if (props.typeroller === 2) {
        textManual += '/' + props.manualRotation;
      }
      textManual += ')';
    }

    return (
      <>
        {props.id === 0 ? <IonText color={props.color}><small>Aktuálně:</small><br></br></IonText> : ''}
        <IonText color={props.color}><small>{props.position}%{textRotate}</small></IonText><IonText color={props.colorManual}><small>{textManual}</small></IonText>
      </>
    )
  }

  const RollerPositionText = (props : any) => {
    let textRotate = '';
    if(props.typeroller === 2){
      textRotate = '/' + (props.rotate);
    }

    let textPosition = '';
    switch (props.position) {
      case 0: textPosition ='Otevřeno';
        break;
      case 1: textPosition ='Zavřeno';
        break;
      case 2: textPosition ='Štěrbiny';
        break;
      case 3: textPosition ='Mezipoloha';
        break;
    
      default:
        break;
    }

    return (
      <>
        {props.id === 0 ? <IonText color={props.color}><small>Nastaveno:</small><br></br></IonText> : ''}
        <IonText color={props.color}><small>{textPosition}{textRotate}</small></IonText>
      </>
    )
  }

  

  const RenderListRooms = () => {

    var data = [];
    let i = 0;
    /*
    1Technicka    0000012000000000000
    1Kuchyne      0330012000000330000
    */
    try {
      dataXmppListRooms.forEach(row => {
        const room = { 
          id: i,
          use: parseInt(row.substr(0,1)),
          name: row.substr(1,13),
          actualPosition: parseInt(row.substr(14,3), 10) || 0, //uvedeno v %
          setPosition: parseInt(row.substr(17,1)),
          centralActive: parseBoolean(row.substr(18,1)),
          manualActive: parseBoolean(row.substr(19,1)),
          error: parseInt(row.substr(20,1), 10) || 0,
          validity: parseInt(row.substr(21,3)),
          setRotation: parseInt(row.substr(24,2)),
          manualPosition: parseInt(row.substr(26,3)), //uvedeno v %
          manualRotation: parseInt(row.substr(29,2)),
          actualRotation: parseInt(row.substr(31,2))
        };
      
        data.push(room);
        i ++;
      });
    }
    catch {
      data = [];
    }

    return (
      <>
        { data.map((item, index) => (
          <IonItemSliding key={item.id}>
          <IonItem key={item.id} onClick={(e) => {e.preventDefault(); h.push('/tabroller/pagerollerroomlist/room/'+item.id+'/'+item.name)}}>
            <IonGrid>
              <IonRow>
                <IonCol size='8'><IonLabel color='primary'>{item.name}</IonLabel></IonCol>
                <IonCol size='4'>
                  <IonNote><RollerIcon typeroller={item.use}></RollerIcon></IonNote>
                  <IonNote>{item.manualActive ? <IonIcon color='warning' icon={handRight}/> : ''}</IonNote >
                  <IonNote>{item.centralActive ? <IonIcon color='dark' icon={central}/> : ''}</IonNote >
                  <IonNote color='danger'>{item.validity > 0 ? 'v'+item.validity : ''}</IonNote>
                  <IonNote color='danger'>{item.error > 0 ? 'e'+item.error : ''}</IonNote>
                </IonCol>
              </IonRow>

              <IonRow >
                <IonCol size='6'>
                  <RollerPositionProc 
                    id={item.id}
                    color='dark' 
                    colorManual='warning'
                    typeroller={item.use}
                    position={item.actualPosition}
                    rotate={item.actualRotation}
                    isManual={item.manualActive}
                    manualPosition={item.manualPosition}
                    manualRotation={item.manualRotation}
                  ></RollerPositionProc>

                  </IonCol>
                  <IonCol size='6'>
                    <RollerPositionText 
                      id={item.id}
                      color='dark' 
                      typeroller={item.use}
                      position={item.setPosition}
                      rotate={item.setRotation}
                    ></RollerPositionText>
                  </IonCol>
              </IonRow>

            </IonGrid>
          </IonItem>
          <IonItemOptions side="end">
            <IonItemOption onClick={(e) => {e.preventDefault(); h.push('/tabroller/pagerollermanual')}}><IonIcon icon={handRight}/>Ručně změnit</IonItemOption>
        </IonItemOptions>
        </IonItemSliding>
        ))}
      </>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabroller" />
            {isLoadingXmppListRooms===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doFetchXmppListRoomsRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
        </IonRefresher>
        
        <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
        >
          <IonCard>
            <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
              <p>Zde jsou zobrazeny aktuální a požadované polohy a dále žlutě i ruční změny.</p>
              <p>Výpis lze obnovit přetažením dolů. Žlutou barvou jsou označeny ruční změny.</p>
              <p>Kliknutím na položku přejdete k přiřazení režimu/programu pro vybranou místnost.</p>
              <p>Posunem řádku doleva můžete ručně změnit polohu.</p>
              <p>Aktuální poloha je udávána v % doby pojezdu. Poloha 0% znamená otevřeno, poloha 100% je zavřeno. Naklopení lamel u žaluzií je od 0 vodorovně, až po 10 svislá poloha.</p>
              <p>Statusy ikon:</p>
              <ul>
                <li><IonIcon icon={roller}/> - Roleta</li>
                <li><IonIcon icon={shutter}/> - Žaluzie</li>
                <li><IonIcon icon={shutterdisable}/> - Vyřazená roleta/žaluzie</li>
                <li><IonIcon icon={central}/> - Roleta reaguje na tl. central</li>
                <li><IonIcon icon={handRight}/> - Byla provedena ruční změna</li>
                <li>v5 - Validita komunikace, číslo udává počet chybných komunikaci rolety</li>
                <li>e1 - Chyba napájení dolů, nebyl detekován žádný proud</li>
                <li>e2 - Chyba napájení nahoru, nebyl detekován žádný proud</li>
                <li>e3 - Chyba napájení dolů, byl překročen proud</li>
                <li>e4 - Chyba napájení nahoru, byl překročen proud</li>
              </ul>
              <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>
        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" icon={informationCircle}/></IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <RenderListRooms/>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        <IonToast
          isOpen={isErrorXmppListRooms}
          message={errMsgXmppListRooms}
          position="bottom"
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />

      </IonContent>
    </IonPage>
  );
};

export default PageRollerInfoRoom;

/* Sliding item with text options on both sides */
/*
<IonItemSliding>
<IonItemOptions side="start">
  <IonItemOption onClick={() => console.log('favorite clicked')}>Favorite</IonItemOption>
</IonItemOptions>

<IonItem>
  <IonLabel>Test sliding item</IonLabel>
</IonItem>

<IonItemOptions side="end">
  <IonItemOption onClick={() => console.log('unread clicked')}>Unread</IonItemOption>
</IonItemOptions>
</IonItemSliding>

<IonLoading
          isOpen={isLoadingXmppListRooms}
          message={'Loading...'}
          duration={30000}
        />
*/