//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonText, IonContent, 
         IonToast, IonButton, IonList, IonItem, 
         IonRefresher, IonRefresherContent, IonRange, IonLabel, IonPopover, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { thermometer, helpCircleOutline } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { RouteComponentProps } from "react-router";
import { prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

interface UserDetailPageProps extends RouteComponentProps<{
  id: string;
  name: string;
}> {}
  
const PageHeatingRoomMaxSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/wholeRoom',
    param: prepareID(parseInt(match.params.id))
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );  

  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [maxOfset, setMaxOfset] = useState(0);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      let result = 0;  
      
      if(resultXmpp === '' || resultXmpp === 'true')
        return;

      try {
        if(resultXmpp.length >= 45) {
          //1Okruh  1     028.4+20020.0000.006.0000000000
          //1Okruh  1     028.8+20020.0000.005.00000000005
          result = parseFloat(resultXmpp.substr(32,4));
          if(isNaN(result))
            result = 0;

          setShowLoading(false);
        }
      }
      catch {
        result = 0;
      }
      finally {
        setMaxOfset(result);
      }
    }

  },[dataXmpp, isLoadingXmpp]);

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(
      defaultXmppObject,
    );
    e.detail.complete();
  }

  const handleOnChange = (e) => {
    var v = e.detail.value;
    setMaxOfset(v);
  }

  const handleOnClickOK = () => {
    //write: '0006'
    var id = prepareID(parseInt(match.params.id));
    var temp = prepareID(maxOfset);
    
    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveMaxOffset',
      param: id+temp
    }
    doFetchXmpp(
      saveXmppObject,
    );
    
    setShowToastSaved(true);
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating/pageheatingroommaxlist" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
      
      <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
      </IonRefresher>    

      <IonPopover
        isOpen={showHelp}
        onDidDismiss={e => setShowHelp(false)}
      >
        <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Pro zvolenou místnost lze specifikovat max. možnou velikost ruční změny na tlačítkách.
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
        </IonCard>
      </IonPopover>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />

        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>Maximální změna teploty <IonIcon color="medium" icon={thermometer}/></IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem color='primary'>{match.params.name}</IonItem>
              <IonItem lines="none">
                <IonLabel>Maximální teplota:</IonLabel><IonText>{maxOfset}°C</IonText>
              </IonItem>
              <IonItem>
                <IonIcon size="small" slot="start" icon={thermometer}/>
                <IonRange min={0} max={12} step={1} pin={true} value={maxOfset} onIonChange={handleOnChange}></IonRange>
                <IonIcon slot="end" icon={thermometer}/>
              </IonItem>
            </IonList>
              <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOK}>Uložit</IonButton>
          </IonCardContent>          
        </IonCard>

        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
      </IonContent>  
    </IonPage>
    )
  }
  export default PageHeatingRoomMaxSet;  
