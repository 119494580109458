//@ts-check

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonButton, IonAlert, IonButtons, IonPopover, IonCard, IonIcon, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { log } from '../functions/fce-string';
import { useHistory } from 'react-router';
import { helpCircleOutline } from 'ionicons/icons';


const DevicesList = (props: any ) => {
  const h = useHistory();
  const [showHelp, setShowHelp] = useState(false);
  const [arr, setArr] = useState(props.devices);
  const [alert,setAlert] = useState(false);
  const [firstReload, setFirstReload] = useState(0);

  useEffect(function fce() {

    if(firstReload > 0) {
      try {
        if(props.devices.length === 0){
          log('DevicesList props.devices.length === 0', false);
          //setAlert(true);
        }
        else
        {
          log('DevicesList setArr(props.devices)', false);
          setArr(props.devices);
        }
      }
      catch {
        log('DevicesList catch', true);
      }
    }
    
    setFirstReload(firstReload+1);

  },[props.devices]);

  const handleOnClick = (e) => {
    e.preventDefault();
    props.parentCallbackSelectedDevice(e.target.innerText);

    h.push("/tabheating");
  }

  const handleOnClickDefault = () => {
    props.parentCallbackSelectedDevice('hc64');

    h.push("/tabheating");
  }

  return (
    <IonPage>
      
      <IonHeader>
        <IonToolbar>
          <IonTitle>Řídící jednotky HC64</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="ion-padding">
      
      <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>Seznam jednotek HC64</IonCardTitle>
            <IonCardSubtitle>Seznam jednotek přiřazených k účtu '{props.account}'<br></br>Vyberte název jednotky, kterou chcete použít pro nastavení regulace.</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
          
          <IonList>
          {arr.map((item, index) => (
            <IonItem button key={index} onClick={handleOnClick}>
              <IonLabel>{item}</IonLabel>
            </IonItem>
          ))}
          </IonList>
          
          </IonCardContent>
        </IonCard>
      
      <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Seznam nalezených řídících jednotek přiřazených k tomuto účtu. Zvolte řídící jednotku kterou chcete nastavovat.<br></br>
            Pokud je seznam prázdný, není dostupná žádná jednotka nebo používáte starší verzi firmware. Zkuste vybrat tlačítkem výchozí jednotku hc64.
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

        <IonAlert
          isOpen={alert}
          onDidDismiss={() => setAlert(false)}
          header={'Varování'}
          message={'Nebyla nalezena žádná řídící jednotka. Chcete přesto přidat výchozí?'}
          buttons={[
            {
              text: 'Ne',
              role: 'cancel',
              cssClass: 'secondary',
              handler: no => {

              }
            },
            {
              text: 'Ano',
              handler: () => {
                setArr(['hc64']);
              }
            }
          ]} 
        />
        
        <p></p>
        <IonButton size='default' expand='block' onClick={handleOnClickDefault}>Použít výchozí jednotku HC64</IonButton>

      </IonContent>
    </IonPage>
  );
};

export default DevicesList;
