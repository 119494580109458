//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import { compareTime, replaceDiacritic, prepareID, prepareSaveTemp, prepareTimeColon, log } from '../../functions/fce-string';
import { IonDateTimeRegime, IonSelectRollerPosition, IonRangeRollerRotate } from '../../functions/myReactComponents';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle,
         IonIcon, IonContent,
         IonToast, IonButton, IonList, IonItem, IonRefresher, IonRefresherContent, IonInput, IonFab, IonFabButton, IonBadge, IonPopover, IonSpinner, IonAlert
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { add, remove, helpCircleOutline, options } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { RouteComponentProps } from "react-router";

import rotate from "../../img/rotate.svg";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

interface UserDetailPageProps extends RouteComponentProps<{
    id: string;
    name: string;
}> {}

const PageRollerRegimeSet : React.FunctionComponent <UserDetailPageProps> = ({match}) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);

  const defaultXmppObject = {
      typefce: 'get',
      fce: '/loadProgram',
      param: prepareID(parseInt(match.params.id))
  }

  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
      defaultXmppObject,
      '',
  );  
  
  //PONE         00:0000505:0000508:0000515:0000522:00005
  
  const hours: string = '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23';
  const minutes : string = '0,5,10,15,20,25,30,35,40,45,50,55';

  const defaultArrRegimes = [
    {time: '00:00', position: 0, rotate: 0, rotatedisabled: false, disabled: true, hourValues: hours, minuteValues: minutes},
    {time: '00:00', position: 0, rotate: 0, rotatedisabled: false, disabled: true, hourValues: '0', minuteValues: '0'},
    {time: '00:00', position: 0, rotate: 0, rotatedisabled: false, disabled: true, hourValues: '0', minuteValues: '0'},
    {time: '00:00', position: 0, rotate: 0, rotatedisabled: false, disabled: true, hourValues: '0', minuteValues: '0'},
  ]
  const [regimeName, setRegimeName] = useState('');
  const [regimes, setRegimes] = useState(defaultArrRegimes);
  const [count, setCount] = useState(0);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      let resultRegimeName = '';  
      
      if(resultXmpp === '' || resultXmpp === 'true')
        return;

      //let clone = defaultArrRegimes;
      let clone = JSON.parse(JSON.stringify(defaultArrRegimes));

      try {
        if(resultXmpp.length >= 13) {
          //Program  1   00:000000009:000010011:0000000
          resultRegimeName = resultXmpp.substr(0, 13).trim();
          var s = resultXmpp.substr(13, resultXmpp.length);
          var arr = [];   //pole režimů
          var n = 10;      //čas spuštění (5 znaků) + nastavená poloha (3 znaky) + nastavena rotace 2 znaky

          for (let i = 0, len = s.length; i < len; i += n) {
            arr.push(s.substr(i, n))
          }

          // Režimy získané z HC64
          var arrRegimesFromUnit = [];
          arr.forEach(row => {
            const reg = { 
              time: row.substr(0,5),
              position: parseInt(row.substr(5,3)),
              rotate: parseInt(row.substr(8,2))
            };
            arrRegimesFromUnit.push(reg);
          });

          //Do výchozího pole režimů zapsat data z HC64
          //var clone = Object.assign({}, defaultArrRegimes);
          
          let i = 0;
          //V první kole pouze přiřadit časy a polohy
          arrRegimesFromUnit.forEach(row => {
            clone[i].position = row.position;
            //Poloha otevřeno nemůže mít rotate
            if(row.position === 0) {
              clone[i].rotate = 0;
              clone[i].rotatedisabled = true;  
            }
            else {
              clone[i].rotate = row.rotate;
              clone[i].rotatedisabled = false;  
            }
            
            clone[i].time = row.time;
        
            if(i > 0) {
              clone[i].disabled = false;
            }
            i++;
          })
          //Ve druhém kole nastavit min, max rozsahy časů
          i = 0;
          arrRegimesFromUnit.forEach(row => {
            if(i > 0) {
              let _t = [];
              if (i < 3) {
                  _t = allowedTime(clone[i-1].time, clone[i+1].time);
              }
              else {
                  _t = allowedTime(clone[i-1].time, '24:00');
              }
              clone[i].hourValues = _t[0];
              clone[i].minuteValues = _t[1];
            }
            i++;
          })
          setShowLoading(false);
        }
      }
      catch {
        log('catch PageRollerRegimeSet', true);
        resultRegimeName = '';
        clone = defaultArrRegimes;
      }
      finally {
        //console.log('finally PageRollerRegimeSet: ' + clone);
        setRegimeName(resultRegimeName);
        setRegimes(clone);
      }
    }

    /*
    return () => {
      console.log('cleanup PageRollerRegimeSet');
    }
    */

  },[dataXmpp, isLoadingXmpp]);

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
      doFetchXmpp(
        defaultXmppObject,
      );
      e.detail.complete();
  }

  const handleOnChangeInput = (e) => {
      var t = replaceDiacritic(e.detail.value);
      setRegimeName(t);
  }

  /*
  const handleOnChangeStartTime1 = (e : CustomEvent<DatetimeChangeEventDetail>) => {
    //Nepoužito, první čas je vždy 00:00
  }
  */
  //const handleOnChangeStartTime = (e : CustomEvent<DatetimeChangeEventDetail>, idRegime : number) => {
  const handleOnChangeStartTime = index => e => {    
    if(count > 0) {
      let copy = JSON.parse(JSON.stringify(regimes));
      log('handleOnChangeStartTime, idRegime: ' + index + ' e.detail.value: ' + e.detail.value, false);  
      //Vynechat úvodní průchody události
      if(e.detail.value === '00:00') {
        //Překleslení React inicializace hodnot
      }
      else if((e.detail.value === '00:00') && (copy[index-1].time === '00:00')) {
        //Překleslení React inicializace hodnot
      }
      else {
        let dt = compareTime(e.detail.value, copy[index-1].time);
        copy[index].time = dt;
      }
      setRegimes(copy);
    }
    else 
    {
      setCount(1);
    }
  }
  /*
  const handleOnSetPosition = (e, idRegime : number) => {
    console.log('handleOnSetPosition, idRegime: ' + idRegime + ' e.detail.value: ' + e.detail.value);
    let copy = JSON.parse(JSON.stringify(regimes));
    copy[idRegime].position = parseInt(e.detail.value);
    setRegimes(copy);
  }
  const handleOnSetRange = (e, idRegime : number) => {
    console.log('handleOnSetRange, idRegime: ' + idRegime + ' e.detail.value: ' + e.detail.value);
    let copy = JSON.parse(JSON.stringify(regimes));
    copy[idRegime].rotate = parseInt(e.detail.value);
    setRegimes(copy);
  }
  */
  const handleOnSetPosition = index => e => {
    if(count > 0) {
      //console.log('handleOnSetPosition, idRegime: ' + index + ' e.detail.value: ' + e.detail.value);
      let newArr = JSON.parse(JSON.stringify(regimes));
      newArr[index].position = parseInt(e.detail.value);
      
      if(parseInt(e.detail.value) === 0) {
        newArr[index].rotate = 0;  
        newArr[index].rotatedisabled = true;
      }
      else {
        newArr[index].rotatedisabled = false;
      }
      setRegimes(newArr);
    }
    else 
    {
      setCount(1);
    }
  }
  const handleOnSetRange = index => e => {
    if(count > 0) {
      //console.log('handleOnSetRange, idRegime: ' + index + ' e.detail.value: ' + e.detail.value);
      let newArr = JSON.parse(JSON.stringify(regimes));
      newArr[index].rotate = parseInt(e.detail.value);
      setRegimes(newArr);
    }
    else 
    {
      setCount(1);
    }
  }

  //Smazání celého režimu
  useEffect(function fce() {
    setRegimes(defaultArrRegimes);
    setIsRemoving(false);
  },[isRemoving]);

  
  const handleOnClickAdd = () => {
    //console.log('handleOnClickAdd');
    let newArr = JSON.parse(JSON.stringify(regimes));
    //1. změna [0] prvek v poli se nemění
    //Hledám od začátku první řádek disabled a povolím ho a nastavím výchozí teplotu, čas předchozí změny, min datetime
    for(let i = 1; i < 4; i++) {
      const row = {
          time: newArr[i].time,
          position: newArr[i].position,
          rotate: newArr[i].rotate,
          disable: newArr[i].disabled
      }

      if(row.disable)
      {
        newArr[i].disabled = false;
        newArr[i].position = 0;
        newArr[i].rotate = 0;
        newArr[i].time = newArr[i-1].time;

        var t = allowedTime(newArr[i].time, '24:00');
        newArr[i].hourValues = t[0];
        newArr[i].minuteValues = t[1];

        break;
      }
    }

    setRegimes(newArr);
  }

  const handleOnClickRemove = () => {
    //console.log('handleOnClickRemove');
    let newArr = JSON.parse(JSON.stringify(regimes));
    //1. změna [0] prvek v poli se nemění
    //Hledám od konce první řádek enabled a zakážu ho nastavím výchozí teplotu a čas
    for(let i = 3; i > 0; i--) {
      if ( !newArr[i].disabled ) {
        newArr[i].disabled = true;
        newArr[i].position = 0;
        newArr[i].rotate = 0;
        newArr[i].time = '00:00';
        newArr[i].hourValues = '0';
        newArr[i].minuteValues = '0';
          
        //předposlednímu režimu nastavit rozsah času dle položky ještě před ní
        //mimo druhé a první položky
        if(i>2) {
            var tprev = allowedTime(newArr[i-2].time, '24:00');
            newArr[i-1].hourValues = tprev[0];
            newArr[i-1].minuteValues = tprev[1];
        }

        break;
      }
    }

    setRegimes(newArr);
  }

  function allowedTime(time1: string, time2: string) {
    let h1: number = parseInt(time1.substr(0,2));
    let m1: number = parseInt(time1.substr(3,2));
    var _h1: string = '';
    var _m1: string = '';

    let h2: number = parseInt(time2.substr(0,2));
    let m2: number = parseInt(time2.substr(3,2));

    if(time2 === '24:00')
    {
        h2 = 24;
        m2 = 60;
    }

    if (m1 === 55 ) {
        //Navýší se hodina
        let i = h1 + 1;
        for (i; i < h2; i++) {
            _h1 = _h1 + i.toString() + ',';
        }
        //minuty zůstanou všechny
        for (let i = 0; i < m2; i+=5) {
            _m1 = _m1 + i.toString() + ',';
        }
    }
    else {
        //Hodina zůstane shodná
        for (h1; h1 < h2; h1++) {
            _h1 = _h1 + h1.toString() + ',';
        }
        //Navýší se minuty od poslední
        for (m1; m1 < m2; m1+=5) {
            _m1 = _m1 + m1.toString() + ',';
        }
    }
    
    //Odstranit poslední znak ','
    _h1 = _h1.slice(0,-1);
    _m1 = _m1.slice(0,-1);

    return [_h1, _m1];
  }

  const handleOnClickOK = () => {
    //00Program  1   00:000001009:000010011:000000012:0300200
    //00Program  1+++00%3A000020509%3A000030611%3A000000012%3A0000000
    /*
    • id režimu (2 znaky)
    • název režimu (13 znaků)
    • čas změny (7 znaků)
    • nastavená poloha (3 znaky)
    • nastavené natočení (2 znaky)
     */
    
    //!!! Místo : se musí použít '%3A'
    let p = prepareID(parseInt(match.params.id));
    p += regimeName.padEnd(13,'+');
    p += prepareTimeColon(regimes[0].time);
    p += prepareSaveTemp(regimes[0].position); //doplní 3 znaky stejně jako pro teplotu
    p += prepareID(regimes[0].rotate); //doplní 2 znaky
    
    for(let i=1; i<4; i++) {
      if(regimes[i].time === '00:00')
        break;
      p += prepareTimeColon(regimes[i].time);
      p += prepareSaveTemp(regimes[i].position);
      p += prepareID(regimes[i].rotate);
    }

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveProgram',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );

    setShowToastSaved(true);
  }
    
  
  return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabroller/pagerollerregimelist" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      
      <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
      </IonRefresher>
      
      <IonPopover
        isOpen={showHelp}
        onDidDismiss={e => setShowHelp(false)}
      >
        <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Nastavení roletového režimu tzv. programu. Jsou k dispozici až 4 změny za den. První režim vždy začíná v čase 00:00.
            Pro žaluzie lze zadat naklopení lamely od 0 do 10, kde: 0-vodorovná poloha, 10-svislá poloha (zavřeno). Pro polohu otevřeno nelze zadat natočení lamely.
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
        </IonCard>
      </IonPopover>

      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />

      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Potvrzení'}
          message={'Opravdu chcete smazat celý režim?'}
          buttons={[
            {
              text: 'NE',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            },
            {
              text: 'ANO',
              handler: () => setIsRemoving(true)
            }
          ]}
      />

      <IonCard className="welcome-card" >
        <IonCardHeader>
          <IonCardTitle>Nastavení režimu <IonIcon color="medium" icon={options}/></IonCardTitle>
          <IonCardSubtitle></IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
        <IonItem color='primary'>
          <IonInput type='text' maxlength={13} value={regimeName} onIonChange={handleOnChangeInput}></IonInput>
        </IonItem>
        <IonList>
          {regimes.map((data, index) => (
            <IonItem key={index}>
              <IonBadge>{index+1}:</IonBadge>
              <IonDateTimeRegime value={data.time} onIonChange={handleOnChangeStartTime(index)} hourValues={data.hourValues} minuteValues={data.minuteValues} disabled={index > 0 ? data.disabled : 'true' }></IonDateTimeRegime>
              <IonSelectRollerPosition value={data.position} onIonChange={handleOnSetPosition(index)} disabled={index > 0 ? data.disabled : 'false' }></IonSelectRollerPosition>
              <IonRangeRollerRotate value={data.rotate} onIonChange={handleOnSetRange(index)} disabled={data.rotatedisabled ? 'true' : 'false' }></IonRangeRollerRotate>
              <IonIcon color='dark' icon={rotate}/>
              <IonBadge color="light">{data.rotate}</IonBadge>
          </IonItem>
          ))}
        </IonList>

        <IonFab vertical="top" horizontal="end" edge slot="fixed" >
            <IonFabButton color="primary" size='small' onClick={handleOnClickAdd}>
                <IonIcon icon={add} />
            </IonFabButton>
            <IonFabButton color="danger" size='small' onClick={handleOnClickRemove}>
                <IonIcon icon={remove} />
            </IonFabButton>
        </IonFab>

        <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOK}>Uložit</IonButton>
        <IonLabel>&nbsp;</IonLabel>
        <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={()=>setShowAlert(true)}>Smazat celý režim</IonButton>
        
      </IonCardContent>
    </IonCard>
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        </IonContent>  
    </IonPage>
    )
  }
  export default PageRollerRegimeSet;  

  /*
  <IonItem>
              <IonBadge>1:{regimes[0].position}</IonBadge>
              <IonDateTimeRegime value={regimes[0].time} hourValues={regimes[0].hourValues} minuteValues={regimes[0].minuteValues} disabled={true}></IonDateTimeRegime>
              <IonSelectRollerPosition value={regimes[0].position} onIonChange={(e)=>handleOnSetPosition(e, 0)} disabled={false}></IonSelectRollerPosition>
              <IonRangeRollerRotate value={regimes[0].rotate} onIonChange={(e)=>handleOnSetRange(e, 0)} disabled={false}></IonRangeRollerRotate>
              <IonIcon color='dark' icon={rotate}/>
              <IonLabel>{regimes[0].rotate}</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>2:{regimes[1].position}</IonBadge>
              <IonDateTimeRegime value={regimes[1].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 1)} hourValues={regimes[1].hourValues} minuteValues={regimes[1].minuteValues} disabled={regimes[1].disabled} ></IonDateTimeRegime>
              <IonSelectRollerPosition value={regimes[1].position} onIonChange={(e)=>handleOnSetPosition(e, 1)} disabled={regimes[1].disabled}></IonSelectRollerPosition>
              <IonRangeRollerRotate value={regimes[1].rotate} onIonChange={(e)=>handleOnSetRange(e, 1)} disabled={regimes[1].disabled}></IonRangeRollerRotate>
              <IonIcon color='dark' icon={rotate}/>
              <IonLabel>{regimes[1].rotate}</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>3:{regimes[2].position}</IonBadge>
              <IonDateTimeRegime value={regimes[2].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 2)} hourValues={regimes[2].hourValues} minuteValues={regimes[2].minuteValues} disabled={regimes[2].disabled} ></IonDateTimeRegime>
              <IonSelectRollerPosition value={regimes[2].position} onIonChange={(e)=>handleOnSetPosition(e, 2)} disabled={regimes[2].disabled}></IonSelectRollerPosition>
              <IonRangeRollerRotate value={regimes[2].rotate} onIonChange={(e)=>handleOnSetRange(e, 2)} disabled={regimes[2].disabled}></IonRangeRollerRotate>
              <IonIcon color='dark' icon={rotate}/>
              <IonLabel>{regimes[2].rotate}</IonLabel>
          </IonItem>
          <IonItem>
              <IonBadge>4:</IonBadge>
              <IonDateTimeRegime value={regimes[3].time} onIonChange={(e : CustomEvent<DatetimeChangeEventDetail>)=>handleOnChangeStartTime(e, 3)} hourValues={regimes[3].hourValues} minuteValues={regimes[3].minuteValues} disabled={regimes[3].disabled} ></IonDateTimeRegime>
              <IonSelectRollerPosition value={regimes[3].position} onIonChange={(e)=>handleOnSetPosition(e, 3)} disabled={regimes[3].disabled}></IonSelectRollerPosition>
              <IonRangeRollerRotate value={regimes[3].rotate} onIonChange={(e)=>handleOnSetRange(e, 3)} disabled={regimes[3].disabled}></IonRangeRollerRotate>
              <IonIcon color='dark' icon={rotate}/>
              <IonLabel>{regimes[3].rotate}</IonLabel>
          </IonItem>
   */

