//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import { parseBoolean } from '../../functions/fce-math';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonContent, 
         IonToast, IonButton, IonList, IonItem, IonRefresher, IonRefresherContent, IonPopover, IonSelectOption, IonSelect, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { thermometer } from "ionicons/icons";

/* XMPP fetch */
import { useDataXMPP, useDataXMPP2 } from '../../functions/fetchXMPP';
import { getArrOfNames, prepareID } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

const PageHeatingWTR = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/listOfModes',
    param: ''
  }
  
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );

  const defaultXmppObject2 = {
    typefce: 'get',
    fce: '/wtr01Settings',
    param: ''
  }
  const [{ dataXmpp2, isLoadingXmpp2, isErrorXmpp2, errMsgXmpp2 }, doFetchXmpp2] = useDataXMPP2(
    defaultXmppObject2,
    '',
  );  
  
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [enabledWTR, setEnabledWTR] = useState(false);
  const [idRegime, setIdRegime] = useState(-1);
  const [temperature, setTemperature] = useState(0);
  const [listRegimes, setListRegimes] = useState([]);                     //Hook pro pole názvu režimů

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore

    if (!isLoadingXmpp) {

      if(dataXmpp === '' || dataXmpp === 'true')
        return;

      let resutlXmpp: string = dataXmpp;
      let result = [];

      try {  
        if(resutlXmpp.length >= 13) {
          //'Rezim 1      Rezim 2      '
          let dataList = getArrOfNames(resutlXmpp, 13);
          dataList.unshift('Žádný  '); //Režim pro nepřiřazený režim

          let i = -1;
          dataList.forEach(row => {
            const reg = { 
              id: i,
              name: row,
            };
            result.push(reg);
            i ++;
          });
        }
      }
      catch {
        result = [];
      }
      finally {
        setListRegimes(result)
      }
    }
    
  },[dataXmpp]);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp2) {
      let resultXmpp: string = dataXmpp2;
      
      if(resultXmpp === '' || resultXmpp === 'true')
        return;

      try {
        //0-1???
        if(resultXmpp.length >= 6) {
          let enabled = parseBoolean(resultXmpp.substr(0,1));
          let id = parseInt(resultXmpp.substr(1,2));

          let temperature = parseFloat(resultXmpp.substr(3,5));
          if(isNaN(temperature)) {
            setTemperature(111);  
          }
          else
          {
            setTemperature(temperature);
          }

          setShowLoading(false);
          setEnabledWTR(enabled);
          setIdRegime(id);
        }
      }
      catch {

      }
      finally {

      }
    }
    
  },[dataXmpp2]);
  
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp2(
       defaultXmppObject2,
    );
    e.detail.complete();
  }
  
  const RenderItemsOption = () => {
    return (
        <>
          {listRegimes.map((item, index) => (
              <IonSelectOption value={item.id} key={index}>{item.name}</IonSelectOption>
          ))}
        </>
    );
  } 

  const handleOnSelectItem = (e, id : number) => {
   
    setIdRegime(e.detail.value);
  }

  
  const handleOnClickOK = () => {

    var p = prepareID(idRegime);

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/savewtr01Settings',
      param: p
    }
    doFetchXmpp2(
      saveXmppObject,
    );

    setShowToastSaved(true);
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
            <IonRefresherContent 
              pullingIcon="arrow-dropdown"
              pullingText="Přetažením dolů načti znovu data"
              refreshingSpinner="circles"
              refreshingText="Načítám ...">
            </IonRefresherContent>
        </IonRefresher>
        
        <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={thermometer}/> Nápověda</h3>
            <p>
              Volitelný modul WTR01 umožňuje zobrazit venkovní teplotu. Modulu lze přiřadit režim, dle kterého se spíná výstupní relé. To umožňuje ovládat např. ohřev střešních oken.
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

        <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />
      
        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon icon={thermometer}/></IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
            <IonItem>
              <IonLabel>{enabledWTR ? 'WTR01 je aktivní' : 'WTR01 není aktivní'}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Venkovní teplota: {temperature===111 ? 'Nezjištěno' : temperature + ' °C'}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Režim:</IonLabel>
              <IonSelect value={idRegime} interface="popover" onIonChange={(e)=>handleOnSelectItem(e, 0)} >
                  <RenderItemsOption/>
              </IonSelect>
            </IonItem>
            </IonList>
            <IonButton disabled={isErrorXmpp2 || isLoadingXmpp2} expand="block" onClick={handleOnClickOK}>Uložit</IonButton>
          </IonCardContent>
        </IonCard>
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        <IonToast
          isOpen={isErrorXmpp2}
          message={errMsgXmpp2}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
      </IonContent>  
    </IonPage>
  )
}
export default PageHeatingWTR;  
