//@ts-check

import { parseISO, addDays, isValid, addMinutes, compareAsc, format } from 'date-fns';
import * as CryptoJs from "crypto-js";
import { DEBUG } from '../App';
//import addHours from 'date-fns/esm/addHours';

export function myNowDateTimeFormat() : string {
  let date = new Date();
  return date.toISOString();
}

export function myaddDays(olddate: Date, day: number) : string {
    let date = addDays(olddate, day);
    return date.toISOString(); 
}

export function parseDateAlarmWind(day, month, minutes) : string {
    //log("month: " + month + " day: "+ day + " min: " + minutes, false);

    if(month ==='00' || day ==='00') {
        return '';
    }

    let date = new Date();
    var year = date.getFullYear();

    var result = addMinutes(new Date(year, parseInt(month)-1, parseInt(day), 0, 0), parseInt(minutes));

    return format(result, "dd-MM-yyyy HH:mm");
}

export function parseTag(text: string, tag: string) : string {
    var a : string = '[' + tag + ']';
    var b : string = '[/' + tag + ']';

    var pos1 = text.lastIndexOf(a) + a.length;
    
    let mySubString : string = '';
    if(pos1 < a.length)
    {
        mySubString = '';
    }
    else
    {
        var pos2 = text.lastIndexOf(b);
        if(pos2 > a.length)
        {
            mySubString = text.substring(pos1, pos2);
        }
        else
        {
            mySubString = '';
        }
    }
    
    return mySubString;
}

export function parseResource(jid: string) : string {
    let result = '';
    try {
        const pos = jid.lastIndexOf('/');
        result = jid.substring(pos+1,jid.length);
    }
    catch {
        result = '';
    }

    return result;
}

export function isEmpty(str:string) {
    if(str === undefined)
        return true;
        
    return (!str || 0 === str.length);
}

export function log(text:string, err:boolean) {
    if(DEBUG) {
        if(err) {
            console.error('❌', new Date().toLocaleString() + ': ' + text);
        }
        else {
            console.log(new Date().toLocaleString() + ': ' + text);
        }
    }
}

export function string2DateTime(str:string) {
    //Formát od řídící jednotky:
    //2012-01-2008:30
    let date = new Date();
    try {
        if (str.length === 15) {
            var y = parseInt(str.substr(0, 4));
            var m = parseInt(str.substr(5, 2));
            var d = parseInt(str.substr(8, 2));
            var h = parseInt(str.substr(10, 2));
            var min = parseInt(str.substr(13, 2));
    
            date = new Date(y,m,d,h,min);
        }
        else
        {
            date = new Date(2000,1,1,0,0,0,0);
        }    
    } catch (error) {
        date = new Date(2000,1,1,0,0,0,0);
    }

    return date;
}



export function dateTime2String(datetime: Date) {
    
    let date:string = '';

    if(datetime === new Date(2000,1,1,0,0,0,0)) {
        
        date = '               '; //datum + čas = 15mezer
    }
    else {
        var y = String(datetime.getFullYear());
        var m = String(datetime.getMonth() + 1);
        if (m.length < 2) m = "0" + m;
        var d = String(datetime.getDate());
        if (d.length < 2) d = "0" + d;
        var h = String(datetime.getHours());
        if (h.length < 2) h = "0" + h;
        var min = String(datetime.getMinutes());
        if (min.length < 2) min = "0" + min;

        date = y + '-'+ m + '-' + d + h + ':' + min;
    }

    return date;
}

export function HC64dateTimeFormat2ISO(str: string) {
    //Formát od řídící jednotky: '2012-01-2008:30'
    //Formát ISO: '2014-02-11T11:30:30'
    let result: string = '';
    try {
        if (str.length === 15) {
            var y = parseInt(str.substr(0, 4));
            var m = parseInt(str.substr(5, 2)) -1 ;
            var d = parseInt(str.substr(8, 2));
            var h = parseInt(str.substr(10, 2));
            var min = parseInt(str.substr(13, 2));
            
            var date = new Date(y,m,d,h,min);
            result = date.toISOString();
        }
        else
        {
            result = '2000-01-01T00:00:00';
        }    
    }
    catch {
        result = '2000-01-01T00:00:00';
    }

    return result;
}

export function ISOdateTimeFormat2HC64(s: string) {
  //Formát ISO od IonDatetime: 2019-10-23T12:33:52.775+02:00
  //Formát HC64: '2019-10-2311:55'
  let result: string = '';
  
  var objectDate = parseISO(s);
  if(isValid(objectDate)) {
    var y = String(objectDate.getFullYear());
    var m = String(objectDate.getMonth()+1);
    if (m.length < 2) m = "0" + m;
    var d = String(objectDate.getDate());
    if (d.length < 2) d = "0" + d;
    var h = String(objectDate.getHours());
    if (h.length < 2) h = "0" + h;
    var min = String(objectDate.getMinutes());
    if (min.length < 2) min = "0" + min;

    result = y + '-'+ m + '-' + d + h + '%3A' + min;
  }
  else {
    result = '2000-01-0100%3A00'; //datum + čas = 15mezer    
  }

  return result;
}

export function prepareTimeColon(s: string) {
    //00:00  -> 00%3A00
    let hour: string = s.substr(0,2);
    let min: string = s.substr(3,2);

    return hour + '%3A' + min;
}

//Doplni nuly pred cislo pro ukladani tří-místných integer teplot
export function prepareSaveTemp(temp: number) : string {
    if (temp > 99) {
        temp = 99;
    }
    var temps = temp.toString();
    if (temps.length === 1)
        temps = "00" + temps;
    else if (temps.length === 2)
        temps = "0" + temps;

    return temps;
}

//Upraví formát pro uložení float teploty userOfset
export function prepareOffsetTemp(temp) {
    let result : string = '';
    var t = temp * 10;

    if (t >= 0) {
        var ts = t.toString();
        while (ts.length < 4) {
            ts = "0" + ts;
        }
        result = ts;
    }
    else {
        var tt = (t * (-1)).toString();

        while (tt.length < 3) {
            tt = "0" + tt;
        }
        result = "-" + tt;
        //t = "-" + tt;
    }

    return result;
}

//Doplni ID na dve mista 
export function prepareID(id) {
    var tmpId = id.toString();
    if (tmpId.length === 1)
        tmpId = "0" + tmpId;
    return tmpId;
}

export function getArrOfNames(s: string, num: number) {
    var chunks = [];

    for (var i = 0, charsLength = s.length; i < charsLength; i += num) {
        chunks.push(s.substring(i, i + num));
    }

    return chunks;
}

export function compareTime(timeA: string, timeB: string) {
/*
TimeA - new/next time
TimeB - previous time
*/
    var result = '';

    if((timeA.length === 5) && (timeB.length === 5)) {
        var hourA = parseInt(timeA.substr(0, 2));
        var minA = parseInt(timeA.substr(3, 2));
        var dateA = new Date(2000, 0, 1, hourA, minA);

        var hourB = parseInt(timeB.substr(0, 2));
        var minB = parseInt(timeB.substr(3, 2));
        var dateB = new Date(2000, 0, 1, hourB, minB);

        let c = compareAsc(dateA, dateB);
        var newtime = dateA;

        switch (c) {
            case 0: //časy jsou stejné
                newtime = addMinutes(dateA, 1);    
                break;
            case 1: //následující čas je větší, OK
                newtime = dateA;    
                break;
            case -1: //následující čas je menší
                    {
                        let cd = compareAsc(dateA, new Date(2000, 0, 1, 0, 0)); //nový čas je 00:00
                        if(cd === 0) {
                            newtime = new Date(2000, 0, 1, 0, 0);
                        }
                        else {
                            newtime = addMinutes(dateB, 1);
                        }
                    }
                break;        
            default:
                break;
        }

        //Convert time to string format
        var h = String(newtime.getHours());
        if (h.length < 2) h = "0" + h;
        var min = String(newtime.getMinutes());
        if (min.length < 2) min = "0" + min;

        //result = h + '%3A' + min;
        result = h + ':' + min;
    }

    return result;
}

var diacritics = {
    a: 'ÀÁÂÃÄÅàáâãäåĀāąĄ',
    c: 'ÇçćĆčČ',
    d: 'đĐďĎ',
    e: 'ÈÉÊËèéêëěĚĒēęĘ',
    i: 'ÌÍÎÏìíîïĪī',
    l: 'łŁ',
    n: 'ÑñňŇńŃ',
    o: 'ÒÓÔÕÕÖØòóôõöøŌō',
    r: 'řŘ',
    s: 'ŠšśŚ',
    t: 'ťŤ',
    u: 'ÙÚÛÜùúûüůŮŪū',
    y: 'ŸÿýÝ',
    z: 'ŽžżŻźŹ',
    ' ': '=*-_:;§€/|\\[]+.&#@{}()><*÷×¤ß$¨%\,\'`~'
  }
  
  export function replaceDiacritics(text: string) {
    for(var toLetter in diacritics) if(diacritics.hasOwnProperty(toLetter)) {
      for(var i = 0, ii = diacritics[toLetter].length, fromLetter, toCaseLetter; i < ii; i++) {
        fromLetter = diacritics[toLetter][i];
        if(text.indexOf(fromLetter) < 0) continue;
        toCaseLetter = fromLetter === fromLetter.toUpperCase() ? toLetter.toUpperCase() : toLetter;
        text = text.replace(new RegExp(fromLetter, 'g'), toCaseLetter);
      }
    }
    return text;
  }

  export function replaceDiacritic(text: string) {
      //var t = removeExoticCharacter(text);
      return text.split('').map(l => Object.keys(diacritics).find(k => diacritics[k].includes(l)) || l).join(''); 
  }

  export function parseNaN2questionMarks(n: number) {
    let result = '';
    if(isNaN(n)) {
        result = '??.?';
    }
    else {
        result = n.toFixed(1); //Zarovnání na 1 desetinu
        if(result.length === 3)
            result = String.fromCharCode(160) + result; //&nbsp;

    }

    return result;
  }

//  function removeExoticCharacter(text: string) {
//    var str = text.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
//    return str;
//  }

export function encrypt(s:string) {
    // INIT
    //const myString = '75322541'; // Utf8-encoded string
  
    // PROCESS
    const encryptedWord = CryptoJs.enc.Utf8.parse(s); // encryptedWord Array object
    const encrypted = CryptoJs.enc.Base64.stringify(encryptedWord); // string: 'NzUzMjI1NDE='
    
    return encrypted;
}

export function decrypt(s:string) {
    // INIT
    //const encrypted = 'NzUzMjI1NDE='; // Base64 encrypted string
  
    // PROCESS
    const encryptedWord = CryptoJs.enc.Base64.parse(s); // encryptedWord via Base64.parse()
    const decrypted = CryptoJs.enc.Utf8.stringify(encryptedWord); // decrypted encryptedWord via Utf8.stringify() '75322541'
    
    return decrypted;
}

