//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import { parseDateAlarmWind, log } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonText, IonContent, 
         IonToast, IonList, IonItem, IonRefresher, IonRefresherContent, IonButton, IonPopover, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { informationCircle, helpCircleOutline, thunderstorm, alarm, alertCircleOutline } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";
import { parseBoolean } from "../../functions/fce-math";
import { TIMEOUT_TOAST_ERROR } from "../../App";

const PageRollerWind = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);

    const defaultXmppObject = {
      typefce: 'get',
      fce: '/windSensorStatus',
      param: ''
    }
    const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
      defaultXmppObject,
      'nezjištěno',
    );

    const [showHelp, setShowHelp] = useState(false);
    const [windSensorEnable, setWindSensorEnable] = useState(false);
    const [isSensorError, setIsSensorError] = useState(false);
    const [speed, setSpeed] = useState(0);
    const [isAlarm, setIsAlarm] = useState(false);
    const [alarmSpeed, setAlarmSpeed] = useState(0);
    const [alarmDate, setAlarmDate] = useState('');

    useEffect(function fce() {
      // 👍 We're not breaking the first rule anymore
      if (!isLoadingXmpp) {
        let resultXmpp: string = dataXmpp;
        let result = 'nezjištěno';  
        
        if(resultXmpp === '' || resultXmpp === 'true')
          return;
  
        try {
          //0002255001100000000000000000000000000000000000000000
          //1000051001100000000000000000000000000000000000000000
          //1000102101100000000000000000000000000000010218030624
          if(resultXmpp.length >= 9) {
            let status = (dataXmpp).substr(0,1);
            let _windSensorEnable = parseBoolean(status);
            setWindSensorEnable(_windSensorEnable);

            if(_windSensorEnable) {
              status = (dataXmpp).substr(4,3);
              let _speed = parseInt(status);
              //Chyba čidla
              if(_speed === 255) {
                setSpeed(0);
                setIsSensorError(true);
              }
              else {
                setSpeed(_speed);
              }
            } else {
              setSpeed(0);
            }

            status = (dataXmpp).substr(7,1);
            setIsAlarm(parseBoolean(status));
              /**
               * 32 rolet 0/1 aktivuje alarm
               */
            status = (dataXmpp).substr(41,3);
            let _speedAlarm = parseInt(status);
            if(_speedAlarm === 255) {
              setAlarmSpeed(0);
              setIsSensorError(true);
            }
            else {
              setAlarmSpeed(_speedAlarm);
            }

            let day = (dataXmpp).substr(44,2);
            let month = (dataXmpp).substr(46,2);
            let minutes = (dataXmpp).substr(48,4);

            let alarmDate = parseDateAlarmWind(day, month, minutes);
            setAlarmDate(alarmDate);

            setShowLoading(false);
          }
        }
        catch {
          result = 'nezjištěno'
        }
        finally {
          
        }
      }
  
    },[dataXmpp, isLoadingXmpp]);

    const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
      doFetchXmpp(
        defaultXmppObject,
      );
      e.detail.complete();
    }
    
    return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabroller" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      
      <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
      </IonRefresher>

      <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Senzor větru zajistí ochranu žaluzií při překročení dané rychlosti větru a jeho trvání po nastavenou dobu. <br></br>
            Pokud dojde k překročení hodnot, žaluzie se otevřou, tzn. zajedou do žaluziového kastlíku.
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

      <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" size="" icon={thunderstorm}/></IonCardTitle>
            <IonCardSubtitle>
              
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
                <IonItem lines="none">
                  <IonLabel>Senzor větru:</IonLabel><IonText>{windSensorEnable ? 'Je aktivní' : 'Není aktivní'}</IonText>
                </IonItem>
                <IonItem hidden={ ! (windSensorEnable && isSensorError) } lines="none">
                  <IonLabel></IonLabel><IonText color='danger'>Senzor větru nekomunikuje! <IonIcon icon={alertCircleOutline} color='danger' size='large' /></IonText>
                </IonItem>
                <IonItem hidden={!windSensorEnable}>
                  <IonLabel>Aktuální rychlost větru:</IonLabel><IonText>{speed} km/h</IonText>
                </IonItem>
                <IonItem hidden={!windSensorEnable} lines="none">
                  <IonLabel></IonLabel>{isAlarm ? <IonText color='danger'>ALARM větrného čidla! <IonIcon icon={alertCircleOutline} color='danger' size='large' /></IonText> : <IonText>Aktuálně není překročena stanovená rychlost větru a doba trvání.</IonText>}
                </IonItem>
                <IonItem></IonItem>
                <IonItem lines='none'>
                  <IonLabel>Poslední alarm:</IonLabel><IonText>{alarmDate}</IonText>
                </IonItem>
                <IonItem>
                  <IonLabel>Rychlost větru:</IonLabel><IonText>{alarmSpeed} km/h</IonText>
                </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />

        </IonContent>  
    </IonPage>
    )
  }
  export default PageRollerWind;  
