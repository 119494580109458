//@ts-check

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { IonPage, IonInput,
    IonLabel,
    IonCard, IonCardHeader, IonCardSubtitle, 
    IonCardContent, IonCardTitle,
    IonContent, IonToast, IonButton, IonList, IonItem, IonHeader, IonToolbar, IonTitle, IonButtons, IonIcon, IonPopover, IonText
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './login.css';

import axios from 'axios';
import * as CryptoJs from "crypto-js";
import logoBMR from "./img/bmrlogoinvert.png";

import { useAuth } from "./context/useAuth";
import { log, encrypt, isEmpty } from "./functions/fce-string";
import { isNull } from "util";
import { informationCircle, logInOutline } from "ionicons/icons";
import { TIMEOUT_TOAST_ERROR } from "./App";

const URL_PREFIX = 'http:';

const Login = () => {
  
  const { setAuthTokens } = useAuth();

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uid, setUserName] = useState("");
  const [passphrase, setPassword] = useState("");
  const [showInfo, setShowInfo] = useState(false);

  const referer = '/tabheating'; //deviceslist
  
  const handleErrorDismiss = () => {
    setErrorMessage('');
    setIsError(false);
  }

  const postLogin = (e)=> {
    e.preventDefault();

    let logged = false;
    let err = false;
    let response = {auth:'', uid:'', passwd:'', status:''};

    if(uid === 'demohc64' && passphrase === '12345') {
      response.auth = 'true';
      response.uid = uid;
      response.passwd = encrypt(passphrase);
      response.status = '120';

      setAuthTokens(response);
      logged = true;
    } 
    else {
      setErrorMessage('Nesprávné přihlašovací údaje');
      setIsError(true);
      err = true;
      logged = false;
    }
    setLoggedIn(logged);  
  } 

  var l = localStorage.getItem('tokens');
  
  if(!isNull(l) && isLoggedIn ) {
      //log('logged Redirect to ...', false);
      return <Redirect to={referer}/>;
  }

  return (
    <IonPage id='login-content'>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start"><img src={logoBMR} className='logo'/></IonTitle>
          <IonTitle slot='start'>DEMOVERZE BMR HC64</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={()=>setShowInfo(true)}>?</IonButton>  
          </IonButtons>
        </IonToolbar>  
      </IonHeader>
      <IonContent>

      
      <IonPopover
        isOpen={showInfo}
        onDidDismiss={e => setShowInfo(false)}
      >
        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>Informace <IonIcon icon={informationCircle}/></IonCardTitle>
            <IonCardSubtitle>
              Verze webového rozhraní: 0.9 beta
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
          <IonText color="primary">
            <p>Aplikace je nyní ve stádiu testovacího provozu, kdy se odlaďují zjištěné chyby.</p>
            <p>Aktuálně jsou implementovány funkce pro ovládání vytápění a rolet. Následovat budou funkce rozšiřující funkce.</p>
            <p>Funkce zabezpečeného přenosu HTTPS bude aktivovaná ihned po odladění provozu.</p>
          </IonText>
          <IonButton expand='block' onClick={()=>setShowInfo(false)}>Zavřít</IonButton>
          </IonCardContent>
        </IonCard>
      </IonPopover>

        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardSubtitle>
              Vítejte v DEMOVERZI aplikace BMR pro ovládání řídící jednotky regulace vytápění, rolet/žaluzií.
            </IonCardSubtitle>
            <IonCardSubtitle>
              Demoverze je určena pouze pro první seznámení s aplikací a způsobem ovládání. Zobrazovaná data nejsou reálně měřená.
            </IonCardSubtitle>
            <IonCardSubtitle>
              Můžete si vyzkoušet jednotlivé funkce, ale provedená nastavení nelze uložit.
            </IonCardSubtitle>
            <IonCardSubtitle>
              Zadejte přihlašovací jméno 'demohc64' a heslo '12345':
            </IonCardSubtitle>
          
          </IonCardHeader>
          <IonCardContent>
            <form onSubmit={postLogin}>
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">Přihlašovací jméno:</IonLabel>
                  <IonInput class='inputlogin' name="username" required type="text" maxlength={12} minlength={5} autocorrect='off' value={uid} onIonChange={e => {setUserName((e.detail.value).toLowerCase());}}></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Heslo:</IonLabel>
                  <IonInput class='inputlogin' name="password" required type="password" maxlength={12} autocorrect='off' autocomplete='off' value={passphrase} onIonChange={e=> {setPassword(e.detail.value)}}></IonInput>
                </IonItem>
              </IonList>
              <IonButton type="submit" expand="full">Přihlásit <IonIcon icon={logInOutline}></IonIcon></IonButton>              
            </form>
            <IonList>
            </IonList>
          </IonCardContent>
          
          <IonToast
            isOpen={isError}
            onDidDismiss={() => setIsError(false)}
            message={errorMessage}
            position="bottom"
            duration={TIMEOUT_TOAST_ERROR}
            buttons={[
              {
                text: 'OK',
                role: 'cancel',
                handler: handleErrorDismiss
              }
            ]}
          />
        </IonCard>
      </IonContent>  
    </IonPage>
  );
}
export default Login;
