//@ts-check

import React, { useState, useEffect } from "react";
import { getArrOfNames, prepareID } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, IonRange,
         IonIcon, IonContent, 
         IonToast, IonButton, IonList, IonItem, IonPopover, IonSpinner, IonSelect, IonSelectOption, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { helpCircleOutline, handRight } from "ionicons/icons";
import { useDataXMPP, useDataXMPP2 } from '../../functions/fetchXMPP';

import roller0 from "../../img/roller0.svg";
import roller10 from "../../img/roller10.svg";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

/*
Load form HC64:
listOfRollerShutters  ->  'Roleta  1    Roleta  2    '
loadCentral           ->  '0!000'
centralRollerShutters ->  '11'

Save to HC64:

*/

const PageRollerManual = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);

  //Načtení názvu mezipolohy
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/rollerShutterIntermediate',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );

  const [intermediate, setIntermediate] = useState('');
  const [manualPosition, setManualPosition] = useState(0);
  const [manualRotation, setManualRotation] = useState(0);
  const [rotationDisable, setRotationDisable] = useState(true);
    
  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      try {
        resultXmpp = resultXmpp.trim();
      }
      catch {
        resultXmpp = '';
      }

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {
        if(resultXmpp.length > 0)
        {
          setIntermediate(resultXmpp);
        }
      }
      catch {}
      finally {}
    }
  },[dataXmpp, isLoadingXmpp]);

  
  //----Načtení názvů rolet
  const defaultXmppObject2 = {
    typefce: 'get',
    fce: '/listOfRollerShutters',
    param: ''
  }
  const [{ dataXmpp2, isLoadingXmpp2, isErrorXmpp2, errMsgXmpp2 }, doFetchXmpp2] = useDataXMPP2(
    defaultXmppObject2,
    '',
  );  
  const [listRooms, setListRooms] = useState([]);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp2) {
      let resultXmpp: string = dataXmpp2;
      let result = [];

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {  
        if(resultXmpp.length >= 13) {
          let dataList = getArrOfNames(resultXmpp, 13);
          let i = 0;
          dataList.forEach(row => {
            const reg = { 
              id: i,
              name: row,
              checked: false
            };

            result.push(reg);
            i ++;
          });
        }
      }
      catch {
        result = [];
      }
      finally {
        setListRooms(result);
        setShowLoading(false);
      }
    }
  },[dataXmpp2, isLoadingXmpp2]);
  
  const ListRooms = () => {
    return (
        <>
          {listRooms.map((item, index) => (
            <IonItem key={item.id}>
              <IonLabel>{item.name}</IonLabel>
              <IonButton disabled={isErrorXmpp} onClick={(e)=>handleOnClickRoom(e, item.id)}>Odeslat</IonButton>
            </IonItem>
          ))}
        </>
    );
  }

  const handleOnSetRotation = (e) => {
    let p = parseInt(e.detail.value);
    setManualRotation(p);
  }

  const handleOnSelectItem = (e) => {
    let p = parseInt(e.detail.value);
    if(p === 0) {
      setManualRotation(0);
      setRotationDisable(true);
    }
    else {
      setRotationDisable(false);
    }

    setManualPosition(p);
  }

  const handleOnClickRoom = (e, id: number) => {
    let p = '';
    p += prepareID(id);
    p += manualPosition.toString();
    p += prepareID(manualRotation);
        
    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveManualChange',
      param: p
    }

    doFetchXmpp(saveXmppObject, );

    setShowToastSaved(true);
  }

  return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabroller" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>Pro zvolené rolety/žaluzie lze režimem požadovanou polohu ručně dočasně změnit shodně, jako ovládáním přímo pomocí roletových tlačítek.</p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

        <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />
        
        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" icon={handRight}/></IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
                <IonCard>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                          <IonLabel>Poloha:</IonLabel>
                          <IonSelect value={manualPosition.toString()} interface="popover" onIonChange={(e)=>handleOnSelectItem(e)} >
                            <IonSelectOption value="0">Otevřeno</IonSelectOption>
                            <IonSelectOption value="1">Zavřeno</IonSelectOption>
                            <IonSelectOption value="2">Šterbiny</IonSelectOption>
                            <IonSelectOption value="3">{intermediate}</IonSelectOption>
                          </IonSelect>
                      </IonItem>
                      <IonItem lines="none">
                          <IonLabel>Natočení 0-10 (žaluzie): {manualRotation}</IonLabel>
                      </IonItem>
                      <IonItem>
                          <IonRange min={0} max={10} step={1} pin={true} value={manualRotation} onIonChange={handleOnSetRotation} disabled={rotationDisable}>
                              <IonIcon slot="start" icon={roller0}/>
                              <IonIcon slot="end" icon={roller10}/>
                          </IonRange>
                      </IonItem>
                      <IonItem lines='none'>
                        <IonLabel>Vyberte roletu/žaluzii:</IonLabel>
                      </IonItem>
                      <ListRooms/>
                    </IonList>
                  </IonCardContent>
                </IonCard>
          </IonCardContent>
        </IonCard>

        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
      </IonContent>  
    </IonPage>
    )
  }
  export default PageRollerManual;  
