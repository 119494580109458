//@ts-check

import {
  IonCard,
  IonPage,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBadge
  } from '@ionic/react';
import {  informationCircle, time, ellipsisVertical } from 'ionicons/icons';
import React from 'react';
import './TabSetting.css';
import { useHistory } from 'react-router';
import { useAuth } from '../context/useAuth';
import { useDeviceSelected } from '../context/useDeviceSelectedContext';
import { XmppOnlineContext } from '../context/useXmppOnline';

interface IAppProps {};

const TabSetting: React.FunctionComponent<IAppProps> = (props) => {
  const h = useHistory();
  const { setAuthTokens } = useAuth();
  const deviceResource = useDeviceSelected();
 
  const handleOnClick = (e) => {
    e.preventDefault(); 
    h.push("/deviceslist");
  }
  
  return (
    <XmppOnlineContext.Consumer>{useXmppOnline => (
    
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonTitle slot='start'>Nastavení</IonTitle><IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={handleOnClick}><IonIcon icon={ellipsisVertical}></IonIcon></IonButton>  
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonCard className="welcome-card" hidden={true}>
          <img src="/assets/shapes.svg" alt=""/>
          <IonCardHeader>
            <IonCardSubtitle>Get Started</IonCardSubtitle>
            <IonCardTitle>Ovládání vytápění HC64</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>blabla</p>
          </IonCardContent>
        </IonCard>

        <IonList lines="none">
          <IonListHeader>
            <IonLabel>Informace a nastavení řídící jednotky</IonLabel>
          </IonListHeader>
          
          <IonItem routerLink="/tabsetting/pagesettinginfo">
            <IonIcon slot="start" color="primary" icon={informationCircle} />
            <IonLabel>
              <h2>Verze firmware</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabsetting/pagesettingdatetime">
            <IonIcon slot="start" color="primary" icon={time} />
            <IonLabel>
              <h2>Nastavit datum a čas</h2>
            </IonLabel>
          </IonItem>
          
        </IonList>
      </IonContent>
    </IonPage>
    )}
    </XmppOnlineContext.Consumer>
  );
};

export default TabSetting;
