//@ts-check

import React, { useState, useEffect } from "react";
import { myNowDateTimeFormat, myaddDays, HC64dateTimeFormat2ISO, ISOdateTimeFormat2HC64, prepareSaveTemp, getArrOfNames } from '../../functions/fce-string';
import { MyIonDateTime } from '../../functions/myReactComponents';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, IonToggle,
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, IonRange,
         IonIcon, IonText, IonContent, IonDatetime,
         IonToast, IonButton, IonList, IonItem, IonAlert, IonPopover, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { thermometer, moon, helpCircleOutline } from "ionicons/icons";
import { useDataXMPP, useDataXMPP2, useDataXMPP3 } from '../../functions/fetchXMPP';
import { parseBoolean } from "../../functions/fce-math";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

const PageHeatingLow = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);

  //Načtení statusu LOW
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadLows',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );

  const [first, setFirst] = useState(0);
  const [low, setLow] = useState(false);
  const [valueRange, setValueRange] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [disabledEndDate, setDisableEndDate] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
    
  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      try {
        resultXmpp = resultXmpp.trim();
      }
      catch {
        resultXmpp = '';
      }

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {
        let lowtemp = 0;  
        switch(resultXmpp.length) {
          case 3:
            // 028
            // Není útlum, vrací se pouze teplota
            lowtemp = parseFloat(resultXmpp);
            if(!isNaN(lowtemp)) {
              setValueRange(lowtemp); 
            }
            break;
          case 18:
            // 0282019-10-3112:01
            // Je útlum trvalý, vrací se teplota a datum startu útlumu
            lowtemp = parseFloat(resultXmpp.substr(0,3));
            if(!isNaN(lowtemp)) {
              setValueRange(lowtemp); 
              setLow(true);
              let d = HC64dateTimeFormat2ISO(resultXmpp.substr(3, 15));
              setStartDate(d);
            }
            break;
          case 33:
            // 0282019-10-3112:012019-10-3115:00
            //Je útlum s ukončením, vrací se teplota, datum startu a datum konce  
            lowtemp = parseFloat(resultXmpp.substr(0,3));
            if(!isNaN(lowtemp)) {
              setValueRange(lowtemp); 
              setLow(true);
              let dstart = HC64dateTimeFormat2ISO(resultXmpp.substr(3, 15));
              setStartDate(dstart);
              let dend = HC64dateTimeFormat2ISO(resultXmpp.substr(18, 15));
              setDisableEndDate(false);
              setEndDate(dend);
            }
            break;
          default:
            // code block
        }
      }
      catch {}
      finally {}
    }
  },[dataXmpp]);

  
  //----Načtení místností
  const defaultXmppObject2 = {
    typefce: 'get',
    fce: '/listOfRooms',
    param: ''
  }
  const [{ dataXmpp2, isLoadingXmpp2, isErrorXmpp2, errMsgXmpp2 }, doFetchXmpp2] = useDataXMPP2(
    defaultXmppObject2,
    '',
  );  
  const [listRooms, setListRooms] = useState([]);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp2) {
      let resultXmpp: string = dataXmpp2;
      let result = [];

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {  
        if(resultXmpp.length > 13) {
          let dataList = getArrOfNames(resultXmpp, 13);
          let i = 0;
          dataList.forEach(row => {
            const reg = { 
              id: i,
              name: row,
            };
            result.push(reg);
            i ++;
          });
        }
      }
      catch {
        result = [];
      }
      finally {
        setListRooms(result)
      }
    }
  },[dataXmpp2]);
  

  //----Načtení statusů přiřazení Léto k místnostem
  const defaultXmppObject3 = {
    typefce: 'get',
    fce: '/lowLoadRooms',
    param: ''
  }
  const [{ dataXmpp3, isLoadingXmpp3, isErrorXmpp3, errMsgXmpp3 }, doFetchXmpp3] = useDataXMPP3(
    defaultXmppObject3,
    '',
  );
  
  const defaultListLow = () => {
    let result = [];
    for(let i=0;i<32;i++){
      const reg = { 
        id: i,
        checked: false
      };
      result.push(reg);
    }
    return result;
  }
  const [listLow, setListLow] = useState(defaultListLow);
  
  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp3) {
      let resultXmpp: string = dataXmpp3;
      let result = [];

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {  
        if(resultXmpp.length > 1) {
          let dataList = getArrOfNames(resultXmpp, 1);
          let i = 0;
          dataList.forEach(row => {
            let checked = parseBoolean(row);
            
            const reg = { 
              id: i,
              checked: checked,
            };
            result.push(reg);
            i ++;
          });
          setShowLoading(false);
        }
      }
      catch {
        result = [];
      }
      finally {
        setListLow(result)
      }
    }
  },[dataXmpp3]);


  const ListRooms = () => {
    return (
        <>
          {listRooms.map((item, index) => (
            <IonItem key={item.id}>
              <IonLabel>{item.name}</IonLabel>
              <IonToggle disabled={isErrorXmpp} checked={listLow[item.id].checked} onIonChange={(e)=>handleOnCheckedRoom(e, item.id)}/>
            </IonItem>
          ))}
        </>
    );
  }

  //Konfigurace Slidu
  const slideOpts = {
    initialSlide: 0,
    pager: true,
    stretch: 0,
    speed: 200,
    freemode: true
  };

  const handleOnSetRange = (e) => {
      setValueRange(parseInt(e.detail.value));
  }

  const handleOnChecked = (e) => {
      if(first > 0) {
        setLow(e.target.checked);
        
        if(e.target.checked)
        {
          setStartDate(myNowDateTimeFormat());
          setShowAlert(true);
        }
        else {
          setStartDate('');
          setEndDate('');
          setDisableEndDate(true);
        }
    }
    else {
      setFirst(1);
    }
  }

  const handleOnChangeStartDate = (e) => {
    if(first > 0) {  
      setStartDate(e.detail.value);
    }
  }

  const handleOnChangeMyIonDateTime = (e) => {
    if(first > 0) {  
      setEndDate(e.detail.value);
    }
  }

  const handleOnClickOK = () => {
    let p = prepareSaveTemp(valueRange);

    if(!low) {
      //ukládá se pouze teplota  
    }
    else
    {
      if(endDate === '') {
        //trvalý útlum
        let date1 = ISOdateTimeFormat2HC64(startDate);
        p += date1;
      }
      else {
        //časový útlum s koncem
        let date1 = ISOdateTimeFormat2HC64(startDate);
        let date2 = ISOdateTimeFormat2HC64(endDate);
        p += date1;
        p += date2;
      }
    }
    //doplnit mezery do 33 délky
    //'0252019-10-3115:102019-10-3121:10'
    //'0252019-10-3115:07               '
    //'025                              '

    p = p.padEnd(33,'+');

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/lowSave',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );

    setShowToastSaved(true);
  }

  const handleOnCheckedRoom = (e, id:number) => {
    var v = e.detail.checked;
    let copy = JSON.parse(JSON.stringify(listLow));
    copy[id].checked = v;
    setListLow(copy);
  }

  const handleOnClickOKroomsAssign = () => {
    
    let p = '';
    listLow.forEach(row=>{
      if(row.checked) {
        p += '1';
      }
      else {
        p += '0';
      }
    })

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/lowSaveRooms',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );

    setShowToastSaved(true);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

      <IonPopover
        isOpen={showHelp}
        onDidDismiss={e => setShowHelp(false)}
        >
        <IonCard>
        <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
          <p>
          Zapnutím tzv. Low režimu - útlumu dojde k nastavení útlumové požadované teploty na vybrané místnosti.
          Začátek útlumu je vždy aktuální čas. Konec útlumu lze nastavit na pozdější dobu.
          </p>
          <p>Standardně všechny místnosti reagují na útlumový režim. Zvolené místnosti lze níže vyřadit z této funkce.</p>
          <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
        </IonCard>
      </IonPopover>
      
      <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />
        
        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" icon={moon}/></IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle></IonCardTitle>
                    <IonCardSubtitle>Aktivace útlumového režimu</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                          <IonLabel>{low ? 'Útlum je zapnutý' : 'Útlum je vypnutý'}</IonLabel>
                          <IonToggle value="low" checked={low} onIonChange={handleOnChecked}/>
                      </IonItem>
                      <IonItem lines="none">
                          <IonLabel>Teplota útlumu:</IonLabel><IonText>{valueRange}°C</IonText>
                      </IonItem>
                      <IonItem>
                          <IonRange min={0} max={50} step={1} pin={true} value={valueRange} onIonChange={handleOnSetRange} disabled={!low}>
                              <IonIcon size="small" slot="start" icon={thermometer}/>
                              <IonIcon slot="end" icon={thermometer}/>
                          </IonRange>
                      </IonItem>
                      <IonItem>
                          <IonLabel>Začátek útlumu:</IonLabel>
                          <IonDatetime displayFormat="DD. MM. YYYY HH:mm" max="2050" doneText='OK' cancelText='Zrušit' min="2019" value={startDate} disabled={true} onIonChange={handleOnChangeStartDate}></IonDatetime>
                      </IonItem>
                      <IonItem disabled={disabledEndDate}>
                          <IonLabel>Konec útlumu:</IonLabel>
                          <MyIonDateTime min={startDate} value={endDate} onIonChange={handleOnChangeMyIonDateTime}></MyIonDateTime>
                      </IonItem>
                    </IonList>
                    <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOK}>Uložit</IonButton>
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle></IonCardTitle>
                    <IonCardSubtitle>Přiřazení místností</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <ListRooms/>
                    </IonList>
                    <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOKroomsAssign}>Uložit místnosti</IonButton>
                  </IonCardContent>
                </IonCard>

          </IonCardContent>
        </IonCard>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Útlumový režim'}
          message={'Chcete zadat datum ukončení útlumu?'}
          buttons={[
            {
              text: 'Ne',
              role: 'cancel',
              cssClass: 'secondary',
              handler: no => {
                setStartDate(myNowDateTimeFormat());
                setEndDate('');
                setDisableEndDate(true);
              }
            },
            {
              text: 'Ano',
              handler: () => {
                var d = myaddDays(new Date(), 7);
                setEndDate(d);
                setDisableEndDate(false);
              }
            }
          ]}
        />
        
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
      </IonContent>  
    </IonPage>
    )
  }
  export default PageHeatingLow;  
