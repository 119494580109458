//@ts-check

import { createState } from "react-global-hook";
import { actionsOnline, actionsStanza, actionsRollers, actionsVentilation, actionsDevices, actionsSelectedDevice, actionsStatusOnline} from "./globalActions";

const initialStateDevices = {
  devices: []
};
export const [useGlobalDevices, getDevices] = createState(
  initialStateDevices,
  actionsDevices,
  store => {
    store.setState({ devices: [] });
  }
);
export const [useGlobalSelectedDevice, getSelectedDevice] = createState(
  {active: ''},
  actionsSelectedDevice,
  store => {
    store.setState({ active: '' });
  }
);

const initialStateStatusOnline = {
  heating: false,
  roller: false,
  ventilation: false,
  setting: false,
  backcompatibility: false
};
export const [useGlobalStatusOnline, getGlobalStatusOnline] = createState(
  initialStateStatusOnline,
  actionsStatusOnline
);

export const [useGlobalOnline, getOnline] = createState(
  {isOnline: false},
  actionsOnline,
  store => {
    store.setState({ isOnline: false });
  }
);

export const [useStatusStanza, getStatusStanza] = createState(
  {isStanza: false},
  actionsStanza,
  store => {
    store.setState({ isStanza: false });
  }
);

export const [useGlobalRollers, getRollers] = createState(
  {isRollers: false},
  actionsRollers,
  store => {
    store.setState({ isRollers: false });
  }
);

export const [useGlobalVentilation, getVentilation] = createState(
  {isVentilation: false},
  actionsVentilation,
  store => {
    store.setState({ isVentilation: false });
  }
);