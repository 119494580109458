//@ts-check

import React, { useState, useLayoutEffect as useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import { getArrOfNames } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonContent, 
         IonToast, IonList, IonItem, IonRefresher, IonRefresherContent, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { options } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";
import { TIMEOUT_TOAST_ERROR } from "../../App";

const PageHeatingRegimeList = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/listOfModes',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );  

  const [listRegimes, setListRegimes] = useState([]);
  
  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      let result = [];

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {  
        if(resultXmpp.length >= 13) {
          //'Rezim 1      Rezim 2      '
          let dataList = getArrOfNames(resultXmpp, 13);
          let i = 0;
          dataList.forEach(row => {
            const reg = { 
              id: i,
              name: row,
            };
            result.push(reg);
            i ++;
          });
          setShowLoading(false);
        }
      }
      catch {
        result = [];
      }
      finally {
        setListRegimes(result)
      }
    }
    
  },[dataXmpp, isLoadingXmpp]);
  
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(
      defaultXmppObject,
    );
    e.detail.complete();
  }

  const ListRegimes = () => {
    return (
      <>
        {listRegimes.map((item, index) => (
          <IonItem key={item.id} routerLink={'/tabheating/pageheatingregimelist/regime/' + index.toString() +'/' + item.name}>
            <IonLabel>{item.name}</IonLabel>
          </IonItem>
        ))}
      </>
    );
  }

  return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      
      <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
      </IonRefresher>

      <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" icon={options}/></IonCardTitle>
            <IonCardSubtitle>Výpis dostupných teplotních režimů. Vyberte režim pro úpravu teploty nebo času.</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
                <ListRegimes/>
            </IonList>
          </IonCardContent>
        </IonCard>
        
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />

        </IonContent>  
    </IonPage>
    )
  }
  export default PageHeatingRegimeList;  


