//@ts-check

import { IonPage,  IonContent,  IonHeader,  IonIcon,  IonItem,
  IonLabel,  IonList,  IonTitle,  IonToolbar, IonBadge, IonButtons, IonButton
} from '@ionic/react';
import { options,swapHorizontal, informationCircle, handRight, ellipsisVertical, thunderstorm } from 'ionicons/icons';
import React from 'react';
import './TabHeating.css';

//import { useAuth } from "../context/useAuth";
import { XmppOnlineContext } from '../context/useXmppOnline';
import { useAuth } from '../context/useAuth';
import { useHistory } from 'react-router';
import { useDeviceSelected } from '../context/useDeviceSelectedContext';

interface IAppProps {};

const TabRoller: React.FunctionComponent<IAppProps> = (props) => {
  const h = useHistory();
  const { setAuthTokens } = useAuth();
  const deviceResource = useDeviceSelected();

  const handleOnClick = (e) => {
    e.preventDefault(); 
    h.push("/deviceslist");
  }

  return (
    <XmppOnlineContext.Consumer>{useXmppOnline => (
    
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot='start'>Rolety / žaluzie</IonTitle><IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={handleOnClick}><IonIcon icon={ellipsisVertical}></IonIcon></IonButton>  
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
        <IonList lines="none" >
          
          <IonItem routerLink="/tabroller/pagerollerinforoom" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={informationCircle} />
            <IonLabel>
              <h2>Informace o roletách</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabroller/pagerollermanual" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={handRight} />
            <IonLabel>
              <h2>Ruční změna polohy</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabroller/pagerollercentral" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={handRight} />
            <IonLabel>
              <h2>Centrální tlačítko</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabroller/pagerollerregimelist" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={options} />
            <IonLabel>
              <h2>Roletové režimy</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabroller/pagerollerroomlist" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={swapHorizontal} />
            <IonLabel>
              <h2>Přiřazení režimů roletám</h2>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/tabroller/pagerollerwind" disabled={!useXmppOnline}>
            <IonIcon slot="start" color="primary" icon={thunderstorm} />
            <IonLabel>
              <h2>Senzor větru</h2>
            </IonLabel>
          </IonItem>

        </IonList>
      </IonContent>
    </IonPage>
    )}
    </XmppOnlineContext.Consumer>
  );
};

export default TabRoller;