//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import {  } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonText, IonContent, 
         IonToast, IonList, IonItem, IonRefresher, IonRefresherContent, IonButton, IonPopover, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { informationCircle, helpCircleOutline } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";
import { TIMEOUT_TOAST_ERROR } from "../../App";

const PageHeatingHDO = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);

    const defaultXmppObject = {
      typefce: 'get',
      fce: '/loadHDO',
      param: ''
    }
    const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
      defaultXmppObject,
      'nezjištěno',
    );

    const [showHelp, setShowHelp] = useState(false);
    const [isHDO, setIsHDO] = useState('');

    useEffect(function fce() {
      // 👍 We're not breaking the first rule anymore
      if (!isLoadingXmpp) {
        let resultXmpp: string = dataXmpp;
        let result = 'nezjištěno';  
        
        if(resultXmpp === '' || resultXmpp === 'true')
          return;
  
        try {
          if(resultXmpp.length === 1) {
            let status = (dataXmpp).substr(0,1);
            switch(status) {
              case '0':
                result = 'Tarif neaktivní';
              break;
              case '1':
                  result = 'Nízký tarif';
                break;
                case '2':
                  result = 'Vysoký tarif';
                break;
              default:
            }
            setShowLoading(false);
          }
        }
        catch {
          result = 'nezjištěno'
        }
        finally {
          setIsHDO(result);
        }
      }
  
    },[dataXmpp, isLoadingXmpp]);

    const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
      doFetchXmpp(
        defaultXmppObject,
      );
      e.detail.complete();
    }
    
    return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      
      <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
      </IonRefresher>

      <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Regulátor pro elektrické vytápění řady RT64 je vybaven blokováním vytápění v případě vysokého tarifu. 
            Informace o tarifu není poskytována u vodního vytápění.
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

      <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" size="" icon={informationCircle}/></IonCardTitle>
            <IonCardSubtitle>
              
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
                <IonItem lines="none">
                  <IonLabel></IonLabel><IonText>{isHDO}</IonText>
                </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />

        </IonContent>  
    </IonPage>
    )
  }
  export default PageHeatingHDO;  
