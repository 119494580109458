//@ts-check

import { useState, useEffect, useReducer } from "react";

import { sendXMPPmessage, deleteAllRecordFromBuffers } from '../App';
import { randomID } from './fce-math';
import { log } from "./fce-string";

/*
--- XMPP Reducer---
*/

const dataXmppFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoadingXmpp: true,
        isErrorXmpp: false,
        errMsgXmpp: ' ',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoadingXmpp: false,
        isErrorXmpp: false,
        errMsgXmpp: ' ',
        dataXmpp: action.payload
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoadingXmpp: false,
        isErrorXmpp: true,
        errMsgXmpp: action.payload,
      };
    default:
      throw new Error();
  }
};
const dataXmppFetchReducer2 = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoadingXmpp2: true,
        isErrorXmpp2: false,
        errMsgXmpp2: ' ',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoadingXmpp2: false,
        isErrorXmpp2: false,
        errMsgXmpp2: ' ',
        dataXmpp2: action.payload
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoadingXmpp2: false,
        isErrorXmpp2: true,
        errMsgXmpp2: action.payload,
      };
    default:
      throw new Error();
  }
};
const dataXmppFetchReducer3 = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoadingXmpp3: true,
        isErrorXmpp3: false,
        errMsgXmpp3: ' ',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoadingXmpp3: false,
        isErrorXmpp3: false,
        errMsgXmpp3: ' ',
        dataXmpp3: action.payload
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoadingXmpp3: false,
        isErrorXmpp3: true,
        errMsgXmpp3: action.payload,
      };
    default:
      throw new Error();
  }
};

export const useDataXMPP = (initialXMPPparam, initialData) => {
  const [xmppParam, setXmppParam] = useState(initialXMPPparam);
  const [state, dispatch] = useReducer(dataXmppFetchReducer, {
    isLoadingXmpp: false,
    isErrorXmpp: false,
    errMsgXmpp: ' ',
    dataXmpp: initialData
  });

  useEffect(() => {
    let didCancel = false; 
    
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      
      try {
        deleteAllRecordFromBuffers();
        const result = await sendXMPPmessage(
          randomID(),
          xmppParam.typefce,
          xmppParam.fce,
          xmppParam.param
        )
        if(result === null) {
          throw new Error('Chyba komunikace s HC64');
        }
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result.result });
        }
      } catch(err) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE', payload: err.message });
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [xmppParam]);
  return [state, setXmppParam];
};

export const useDataXMPP2 = (initialXMPPparam, initialData) => {
  const [xmppParam, setXmppParam] = useState(initialXMPPparam);
  const [state, dispatch] = useReducer(dataXmppFetchReducer2, {
    isLoadingXmpp2: false,
    isErrorXmpp2: false,
    errMsgXmpp2: ' ',
    dataXmpp2: initialData
  });

  useEffect(() => {
    let didCancel = false; 
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        deleteAllRecordFromBuffers();
        const result = await sendXMPPmessage(
          randomID(),
          xmppParam.typefce,
          xmppParam.fce,
          xmppParam.param
        )
        if(result === null) {
          throw new Error('Chyba komunikace s HC64');
        }
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result.result });
        }
      } catch(err) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE', payload: err.message });
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [xmppParam]);
  return [state, setXmppParam];
};

export const useDataXMPP3 = (initialXMPPparam, initialData) => {
  const [xmppParam, setXmppParam] = useState(initialXMPPparam);
  const [state, dispatch] = useReducer(dataXmppFetchReducer3, {
    isLoadingXmpp3: false,
    isErrorXmpp3: false,
    errMsgXmpp3: ' ',
    dataXmpp3: initialData
  });

  useEffect(() => {
    let didCancel = false; 
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        deleteAllRecordFromBuffers();
        const result = await sendXMPPmessage(
          randomID(),
          xmppParam.typefce,
          xmppParam.fce,
          xmppParam.param
        )
        if(result === null) {
          throw new Error('Chyba komunikace s HC64');
        }
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result.result });
        }
      } catch(err) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE', payload: err.message });
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [xmppParam]);
  return [state, setXmppParam];
};







/*
//Staré načítání
*/

export async function fetchVersion() {
  const data = await fetchFromXMPP('get','/version',' ');
  return (data).substr(0,1) + '.' + (data).substr(1,1);
};

async function fetchFromXMPP(typefce:string, fce:string, param: string) {
log('fetchFromXMPP', false);
return sendXMPPmessage(
  randomID(),
  typefce,
  fce,
  param
).then(res=>{
  if(res === null)
    throw new Error('XMPP_ANSWER_IS_NULL');
  return res;
}).catch(err => {
  throw new Error(err);
}).finally(()=>{
  deleteAllRecordFromBuffers();
})
}

