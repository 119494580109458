//@ts-check

import { ISOdateTimeFormat2HC64 } from "./fce-string";

export function emulateResult(uid:string, type:string, fce:string, param:string) {
    //[UID]256203095[/UID][TYPE]get[/TYPE][FCE]/listOfRooms[/FCE][PARAM][/PARAM]
    let result = '';

    if(type === 'get') {
        switch (fce) {
            case '/unitSettings': {
                result = '0000000000';
                break;
            }
            case '/numOfRooms': {
                result = '04';
                break;
            }
            case '/moreWholeRoom': {
                result = '1Obyvaci pokoj023.1+22020.0000.005.0000000000001Detsky pokoj 022.8+22020.0000.005.0000000000001Loznice      020.3+20020.0000.005.0000000000001Chodba       018.1+19019.0000.000.010000000000';
                break;
            }
            case '/listOfRooms': {
                result = 'Obyvaci pokojDetsky pokoj Loznice      Chodba       ';
                break;
            }
            case '/wholeRoom': {
                switch (param) {
                    case '00':
                        result = '1Obyvaci pokoj023.1+20020.0000.005.00000000005';        
                        break;
                    case '01':
                        result = '1Detsky pokoj 022.8+20020.0000.005.00000000005';        
                        break;
                    case '02':
                        result = '1Loznice      020.3+20020.0000.005.00000000005';        
                        break;
                    case '03':
                        result = '1Chodba       019.8+19019.0000.000.00000000000';        
                        break;
                    default:
                        break;
                }
                break;
            }
            case '/loadLows': {
                result = '015                              ';
                break;
            }
            case '/lowLoadRooms': {
                result = '1111';
                break;
            }
            case '/listOfModes': {
                result = 'PO az PA     Loznice      Chodby       Vikend       Rezim  5     Rezim  6     Rezim  7     Rezim  8     Rezim  9     Rezim 10     Rezim 11     Rezim 12     Rezim 13     Rezim 14     Rezim 15     Rezim 16     Rezim 17     Rezim 18     Rezim 19     Rezim 20     Rezim 21     Rezim 22     Rezim 23     Rezim 24     Rezim 25     Rezim 26     Rezim 27     Rezim 28     Rezim 29     Rezim 30     Rezim 31     Rezim 32     ';
                break;
            }
            case '/loadMode': {
                switch (param) {
                    case '00':
                        result = 'PO az PA     00:0002005:0002208:0002015:0002221:00021';        
                        break;
                    case '01':
                        result = 'Loznice      00:00020';        
                        break;
                    case '02':
                        result = 'Chodby       00:00019';        
                        break;
                    case '03':
                        result = 'Vikend       00:0002006:0002221:00021';        
                        break;
                    default:
                        break;
                }
                break;
            }
            case '/roomSettings': {
                switch (param) {
                    case '00':
                        result = '0100320000000303-1-1-1-1-1-1-1-1-1-1-1-1-1-1';        
                        break;
                    case '01':
                        result = '0100320000000303-1-1-1-1-1-1-1-1-1-1-1-1-1-1';        
                        break;
                    case '02':
                        result = '0133-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1';        
                        break;
                    case '03':
                        result = '0134-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1';        
                        break;
                    default:
                        break;
                }
                break;
            }
            case '/wtr01Settings': {
                result = '0-1???';
                break;
            }
            case '/loadMinFloor': {
                result = '00';
                break;
            }
            case '/loadHDO': {
                result = '0';
                break;
            }
            case '/getKCNET': {
                result = '000000000000000000000000000000000000000000';
                break;
            }
            case '/version': {
                result = '97';
                break;
            }
            case '/loadDateTime': {
                result = '2020-04-0106:00'
                break;
            }


            default:
                break;
        }
    }
    
    return result;    

}

