//@ts-check

import React, { useState, useRef, useEffect } from "react";
import { IonDatetime, IonRange, IonSelect, IonSelectOption, IonModal, IonButton, IonListHeader, IonList, IonItem, IonLabel } from '@ionic/react';
import { Route, Redirect } from "react-router-dom";
import axios from 'axios';
import { log } from "./fce-string";

const URL_PREFIX = 'http:';

export const MyIonDateTime = (props: any) => {
  return (
    <>
    { 
      (props.min !== undefined) &&
        <IonDatetime 
          displayFormat="DD. MM. YYYY HH:mm" 
          min={props.min} 
          max="2050" 
          doneText='OK'
          cancelText='Zrušit'
          value={props.value} 
          onIonChange={props.onIonChange}>
        </IonDatetime>
    }
    </>
  )
}

export const IonDateTimeRegime  = (props: any) => {
  return (
    <>
    <IonDatetime 
      value={props.value}
      onIonChange={props.onIonChange}
      display-format="HH:mm" 
      picker-format="HH:mm"
      doneText='OK'
      hourValues={props.hourValues}
      minuteValues={props.minuteValues}
      disabled={props.disabled}
    >
    </IonDatetime>
    </>
  )
}

export const IonRangeRegime  = (props : any) => {
  return (
    <>
    <IonRange 
      value={props.value}
      onIonChange={props.onIonChange}
      min={0}
      max={50}
      step={1}
      pin={true}
      disabled={props.disabled}
    >
    </IonRange>
    </>
  )
}

export const IonRangeRollerRotate  = (props : any) => {
  return (
    <IonRange 
      value={props.value}
      onIonChange={props.onIonChange}
      min={0}
      max={10}
      step={1}
      pin={true}
      disabled={props.disabled}
    >
    </IonRange>
  )
}

export const IonSelectRollerPosition = (props : any) => {
  return (
    <IonSelect 
      value={props.value} 
      interface="popover" 
      onIonChange={props.onIonChange}
      disabled={props.disabled}
    >
      <IonSelectOption key={0} value={0}>Otevřeno</IonSelectOption>
      <IonSelectOption key={1} value={1}>Zavřeno</IonSelectOption>
      <IonSelectOption key={2} value={2}>Šterbiny</IonSelectOption>
      <IonSelectOption key={3} value={3}>Mezipoloha</IonSelectOption>
    </IonSelect>
  )
}

export const MyPrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  
  <Route {...rest} render={(props) => (
    loggedIn === true
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
)

export const MyLoginRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route {...rest} render={(props) => (
    loggedIn === false
      ? <Component {...props} />
      : <Redirect to='/tabheating'/> 
  )} />
)

export function usePromiseOld(promiseOrFunction, defaultValue) {
  const [state, setState] = React.useState({ value: defaultValue, error: null, isPending: true });

  React.useEffect(() => {
    
    let isSubscribed = true
    Promise.resolve(promiseOrFunction)
      .then(value => isSubscribed ? setState({ value, error: null, isPending: false }) : null)
      .catch(error => isSubscribed ? setState({ value: defaultValue, error: error, isPending: false }) : null)

    return () => (isSubscribed = false);
  }, []); //promiseOrFunction, defaultValue

  const { value, error, isPending } = state;
  return [value, error, isPending];
}

export function usePromise<T>(
  promiseOrFunction: (() => Promise<T>) | Promise<T>,
  defaultValue: T
): [T, Error | string | null, boolean] {
  
  log('usePromise<T>', false);

  const [state, setState] = React.useState({
    value: defaultValue,
    error: null,
    isPending: true
  });

  React.useEffect(() => {
    const promise =
      typeof promiseOrFunction === "function"
        ? promiseOrFunction()
        : promiseOrFunction;
        
    let isSubscribed = true;
    promise
      .then(value =>
        isSubscribed ? setState({ value, error: null, isPending: false }) : null
      )
      .catch(error =>
        isSubscribed
          ? setState({ value: defaultValue, error: error, isPending: false })
          : null
      );

    return () => {
      isSubscribed = false;
    };
  }, [promiseOrFunction, defaultValue]);

  const { value, error, isPending } = state;
  return [value, error, isPending];
}




// Hook
export function usePrevious(value) {
  
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}


export const LogoutPopup = (props:any) => {
    const [signoutTime, setSignoutTime] = useState(props.signoutTime);
    const [warningTime, setWarningTime] = useState(props.warningTime);
    const [xmppuser, setXmppuser] = useState(props.xmppUserName);
    let warnTimeout;
    let logoutTimeout;

    const sendData = () => {
      props.parentCallback("Hey Popsie, How’s it going?");
    };

    const warn = () => {
        //console.log('Warning');
        props.parentCallbackWarn(true);
    };

    const logout = () => {
        //console.log('You have been logged out');
        props.parentCallbackLogout(true);
    };

    const destroy = () => {
        log('Session destroyed', false);
    }
    const setTimeouts = () => {
        warnTimeout = setTimeout(warn, warningTime);
        logoutTimeout = setTimeout(logout, signoutTime);
    };

    const clearTimeouts = () => {
        if (warnTimeout) clearTimeout(warnTimeout);
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const refreshRedis = () => {
      //Refresh REDIS record
      var bodyFormData = {
        auth:{
          uid: btoa(unescape(encodeURIComponent(xmppuser)))
        }
      }
      axios({
        method: 'post',
        url: URL_PREFIX+'//hc64.bmr.cz/auth/refresh.php',
        headers: {'Content-Type': 'applications/json' },
        data: bodyFormData
      })
      .then(r => {
        if (r.status === 200) {
            log('Refreshed Redis key from LogoutPopup: ' + xmppuser, false)
        } 
        else {
        }
      }).catch(e => {
      }).finally(() => {
      });
    }

    useEffect(() => {
        const events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        const resetTimeout = () => {
            clearTimeouts();
            setTimeouts();
            //refreshRedis();
        };

        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }

        setTimeouts();
        return () => {
            for(let i in events){
                window.removeEventListener(events[i], resetTimeout);
                clearTimeouts();
            }
        }
    },[]);

    return (
      <>
        <div onChange ={warn}/>
      </>
    )
    
}

  


/*
export function usePromise(promiseOrFunction, defaultValue) {
  const [state, setState] = React.useState({ value: defaultValue, error: null, isPending: true });

  React.useEffect(() => {
    const promise = (typeof promiseOrFunction === 'function')
      ? promiseOrFunction()
      : promiseOrFunction

    let isSubscribed = true
    promise
      .then(value => isSubscribed ? setState({ value, error: null, isPending: false }) : null)
      .catch(error => isSubscribed ? setState({ value: defaultValue, error: error, isPending: false }) : null)

    return () => (isSubscribed = false);
  }, [promiseOrFunction, defaultValue]);

  const { value, error, isPending } = state;
  return [value, error, isPending];
}
*/


/*
const MyComponent = () => {
    const handleClick = useCallback((e) => {
        //...
    }, []) // deps

    return <OptimizedButtonComponent onClick={handleClick}>Click Me</button>;
};
*/

