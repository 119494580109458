//@ts-check

import { useState, useReducer, useLayoutEffect } from "react";

import { sendXMPPmessage, deleteAllRecordFromBuffers } from '../App';
import { randomID } from './fce-math';
import { prepareID, getArrOfNames } from "./fce-string";

/*
--- XMPP Reducer---
*/
const dataXmppFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoadingXmppListRooms: true,
        isErrorXmppListRooms: false,
        errMsgXmppListRooms: ' ',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoadingXmppListRooms: false,
        isErrorXmppListRooms: false,
        errMsgXmppListRooms: ' ',
        dataXmppListRooms: action.payload
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoadingXmppListRooms: false,
        isErrorXmppListRooms: true,
        errMsgXmppListRooms: action.payload,
      };
    default:
      throw new Error();
  }
};

export const useFetchRoomsXMPP = (initialXMPPparam, initialData, roomLength) => {
  const [xmppParam, setXmppParam] = useState(initialXMPPparam);
  const [state, dispatch] = useReducer(dataXmppFetchReducer, {
    isLoadingXmppListRooms: false,
    isErrorXmppListRooms: false,
    errMsgXmppListRooms: ' ',
    dataXmppListRooms: initialData
  });

  useLayoutEffect(() => {
    let didCancel = false;

      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        
        try {
          if(xmppParam.param !=='') {      

            if(xmppParam.param === undefined)
              throw new Error('Chyba komunikace s HC64');

            let roomList=[];
            let numRooms = parseInt(xmppParam.param);

            if(numRooms <= 16) {
              //Méně než 16 okruhů včetně
              deleteAllRecordFromBuffers();
              const result = await sendXMPPmessage(
                randomID(),
                xmppParam.typefce,
                xmppParam.fce,
                '00' + prepareID(numRooms)
              )
              if(result === null) {
                throw new Error('Chyba komunikace s HC64');
              }
              //1Okruh  1     029.0+20020.0000.005.000000000000
              let dataList = getArrOfNames(result.result, roomLength);
              dataList.forEach(row => {
                roomList.push(row);
              });
            }
            else {
              //Více než 16 okruhů
              //Načíst prvních šestnáct
              deleteAllRecordFromBuffers();
              const result = await sendXMPPmessage(
                randomID(),
                xmppParam.typefce,
                xmppParam.fce,
                '0016'
              )
              if(result === null) {
                throw new Error('Chyba komunikace s HC64');
              }
              let dataList = getArrOfNames(result.result, roomLength);
              dataList.forEach(row => {
                roomList.push(row);
              });
              
              //Načíst vše nad 16
              let n = numRooms - 16;
              deleteAllRecordFromBuffers();
              const result2 = await sendXMPPmessage(
                randomID(),
                xmppParam.typefce,
                xmppParam.fce,
                '16' + prepareID(n)
              )
              if(result === null) {
                throw new Error('Chyba komunikace s HC64');
              }

              dataList = getArrOfNames(result2.result, roomLength);
              dataList.forEach(row => {
                roomList.push(row);
              });
            }

            if(roomList === null) {
              throw new Error('Chyba komunikace s HC64');
            }
            if (!didCancel) {
              dispatch({ type: 'FETCH_SUCCESS', payload: roomList });
            }
        }
      } catch (e) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE', payload: e.message });
          }
        }
      };
      fetchData();
  

  return () => {
    didCancel = true;
  };

  }, [xmppParam, roomLength]);
  
  return [state, setXmppParam];
};

