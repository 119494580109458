//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonContent, 
         IonToast, IonButton, IonList, IonItem, IonRefresher, IonRefresherContent, IonDatetime, IonPopover, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { time, helpCircleOutline } from "ionicons/icons";

/* XMPP fetch */
import { useDataXMPP } from '../../functions/fetchXMPP';
import { ISOdateTimeFormat2HC64, HC64dateTimeFormat2ISO } from "../../functions/fce-string";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

const PageSettingDateTime = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadDateTime',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );  
  
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);
  const [dateTime, setDateTime] = useState('');

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      let result = '';

      if(resultXmpp === '' || resultXmpp === 'true')
        return;

      try {
        if(resultXmpp.length === 15) {
          //'2019-10-2311:55'
          result = HC64dateTimeFormat2ISO(resultXmpp.substr(0, 15));
          setShowLoading(false);
        }
      }
      catch {
        result = '';
      }
      finally {
        setDateTime(result);
      }
    }

  },[dataXmpp, isLoadingXmpp]);
  
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(
      defaultXmppObject,
    );
    e.detail.complete();
  }
  
  const handleOnChangeDateTime = (e) => {
    setDateTime(e.detail.value);
  }

  const handleOnClickOK = () => {
    //format: '2019-10-2312:22'
    var p = ISOdateTimeFormat2HC64(dateTime);

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveDateTime',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );

    setShowToastSaved(true);
  }

  const handleOnClickNow = () => {
    //format: '2019-10-2312:22'
    var date = new Date();
    var result = date.toISOString();
    setDateTime(result);
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabsetting" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
            <IonRefresherContent 
              pullingIcon="arrow-dropdown"
              pullingText="Přetažením dolů načti znovu data"
              refreshingSpinner="circles"
              refreshingText="Načítám ...">
            </IonRefresherContent>
        </IonRefresher>
        
        <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Nastavení času a datumu řídící jednotky. Aktuální hodnota je důležitá pro správné fungování regulace.
            </p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

        <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />

        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" icon={time}/></IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel>Čas a datum:</IonLabel>
                <IonDatetime displayFormat="DD. MM. YYYY HH:mm" max="2050" doneText='OK' cancelText='Zrušit' value={dateTime} onIonChange={handleOnChangeDateTime}></IonDatetime>
              </IonItem>
            </IonList>
            <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickNow}>Získat aktuální čas</IonButton>
            <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOK}>Uložit</IonButton>
          </IonCardContent>
        </IonCard>
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
      </IonContent>  
    </IonPage>
  )
}
export default PageSettingDateTime;  
