//@ts-check

import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from '@ionic/core';
import {  } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, 
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, 
         IonIcon, IonText, IonContent, 
         IonToast, IonList, IonItem, IonRefresher, IonRefresherContent, IonSpinner, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';

/* Icons */
import { informationCircle } from "ionicons/icons";

import { useDataXMPP } from '../../functions/fetchXMPP';
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";
import { TIMEOUT_TOAST_ERROR } from "../../App";

const PageSettingInfo = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);

  const defaultXmppObject = {
    typefce: 'get',
    fce: '/version',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    'nezjištěno',
  );

  const [version, setVersion] = useState('');

  useEffect(function fce() { 
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      let result = 'nezjištěno';  
      
      if(resultXmpp === '' || resultXmpp === 'true')
        return;

      try {
        if(resultXmpp.length === 2) {
          result = (dataXmpp).substr(0,1) + '.' + (dataXmpp).substr(1,1);  
          setShowLoading(false);
        }
      }
      catch {
        result = 'nezjištěno'
      }
      finally {
        setVersion(result);
      }
    }

  },[dataXmpp, isLoadingXmpp]);

  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(
      defaultXmppObject,
    );
    e.detail.complete();
  }

  return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabsetting" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
        </IonToolbar>
      </IonHeader>
      
      <IonContent>
      
      <IonRefresher slot="fixed" onIonRefresh={doXmppRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
      </IonRefresher>

      <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" size="" icon={informationCircle}/></IonCardTitle>
            <IonCardSubtitle>
              Informace o verzi firmware řídící jednotky HC64
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
                <IonItem lines="none">
                  <IonLabel>Verze firmware: </IonLabel><IonText>{version}</IonText>
                </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        
        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />

        </IonContent>  
    </IonPage>
    )
  }
  export default PageSettingInfo;  
