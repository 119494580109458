//@ts-check

import React, { useState, useLayoutEffect } from "react";
import { useHistory } from 'react-router';
import { RefresherEventDetail } from '@ionic/core';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar,IonContent,
         IonList, IonItem, IonLabel, 
         IonToast, IonNote, IonIcon, IonGrid, IonRow, IonCol, 
         IonText, IonRefresher, IonRefresherContent, IonCard, 
         IonCardHeader, IonCardTitle, IonCardContent, IonPopover, IonButton, IonSpinner, IonItemSliding, IonItemOptions, IonItemOption, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { informationCircle, snow, sunny, card, moon, open, handRight, flame, helpCircleOutline } from "ionicons/icons";
import { useDataXMPP } from '../../functions/fetchXMPP';
import { useFetchRoomsXMPP } from "../../functions/fetchRoomsXMPP";
import { parseNaN2questionMarks } from "../../functions/fce-string";
import thermometerfloor from "../../img/thermometer-floor.svg";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";
import { TIMEOUT_TOAST_ERROR } from "../../App";

//createStore('valueRangeStore', 0); //Global hooks

const PageHeatingInfoRoom = (props : any) => {  
  //debugger;

  const h = useHistory();
  const deviceResource = useDeviceSelected();
  const [showHelp, setShowHelp] = useState(false);
  const [showFloor, setShowFloor] = useState(false);

  //Počet místností
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/numOfRooms',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );  

  /*
  const doXmppRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmpp(
      defaultXmppObject,
    );
    e.detail.complete();
  }
  */
 
  useLayoutEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp && dataXmpp !=='') {
      //console.log('dataXmpp ' + dataXmpp );
      doFetchXmppListRooms(
        defaultXmppObjectListRooms,
      );
    }
  },[dataXmpp, isLoadingXmpp]);
 

  //List místností
  const defaultXmppObjectListRooms = {
    typefce: 'get',
    fce: '/moreWholeRoom',
    param: dataXmpp
  }
  const [{ dataXmppListRooms, isLoadingXmppListRooms, isErrorXmppListRooms, errMsgXmppListRooms }, doFetchXmppListRooms] = useFetchRoomsXMPP(
    defaultXmppObjectListRooms,
    [], 47
  );  
  const doFetchXmppListRoomsRefresh = (e : CustomEvent<RefresherEventDetail>) => {
    doFetchXmppListRooms(
      defaultXmppObjectListRooms,
    );
    e.detail.complete();
  }

  const handleOnChangeShowFloor = (e) => {
    if(showFloor)
      setShowFloor(false);
    else
      setShowFloor(true);
  }
  
  const RenderFloorSensors = (props: any) => {
    let data = [];

    if(props.idAirSensor>0) {
      props.dataFloorSensors.forEach(row => {
        if(props.idAirSensor === row.idAirSensor) {
          data.push(row);
        }
      })
    }

    return (
      <>
      { data.map ((item, index) => (
        <IonLabel key={index}><IonIcon color={props.color} icon={thermometerfloor}></IonIcon><IonText color={props.color}><small>&nbsp;{item.name}: {parseNaN2questionMarks(item.actualTemp)}°C /</small> </IonText><IonText color={props.color}><small>{parseNaN2questionMarks(item.setTemp)}°C</small></IonText></IonLabel>
      ))
      }
      </>
    );
  }

  const RenderListRooms = () => {
    //1Obyvak       019.8020.5005.50012111
    //1Okruh  1     028.3+20020.0000.005.0000000000
    //1Okruh  1     024.9+23023.0000.005.000000000002
    //1Okruh  2     ?????+20000.0000.006.000024000002
    //
    var dataRoomSensors = [];
    var dataFloorSensors = [];
    let i = 0;
    /*
    var POS_ACTUALTEMP = 14;  //délka 5
    var POS_REQUIRED = 19     //délka 3 znaménko + 2 znaky
    var POS_REQUIREDALL = 22; //délka 5
    var POS_USEROFFSET = 27;  //délka 5
    var POS_MAXOFFSET = 32;   //délka 4
    var POS_S_TOPI = 36;      //délka 1
    var POS_S_OKNO = 37;      //délka 1
    var POS_S_KARTA = 38;     //délka 1
    var POS_VALIDATE = 39;    //délka 3
    var POS_LOW = 42;         //délka 1 místnost je v LOW 1, není v LOW 0
    var POS_LETO = 43;        //délka 1 místnost je v TOPNE 1, je v NETOPNÉ 0
    var POS_S_CHLADI = 44;    //délka 1
    vazba podlahového čidla na vzduchové 42,2
    */
    try {
      dataXmppListRooms.forEach(row => {
        const room = { 
          id: i,
          use: row.substr(0,1),
          name: row.substr(1,13),
          actualTemp: parseFloat(row.substr(14,5)),
          setTemp: parseFloat(row.substr(19,3)),
          setTempAll: parseFloat(row.substr(22,5)),
          userOfset: parseFloat(row.substr(27,5)),
          maxUserOfset: parseFloat(row.substr(32,4)),
          isHeating: row.substr(36,1),
          isOpenWindow: row.substr(37,1),
          isGuestCard: row.substr(38,1),
          validity: row.substr(39,3),
          isLow: row.substr(42,1),
          isSummer: row.substr(43,1),
          isCooling: row.substr(44,1),
          idAirSensor: parseInt(row.substr(45,2),10) || 0
        };

        if(room.idAirSensor > 0) {
          dataFloorSensors.push(room);
        }
        else
        {
          dataRoomSensors.push(room);
        }
        
        i ++;
      });

      //Sort dataFloorSensor
      dataFloorSensors.sort((a, b) => parseFloat(a.idAirSensor) - parseFloat(b.idAirSensor));
    }
    catch {
      dataFloorSensors = [];
      dataRoomSensors = [];
    }

    return (
      <>
        { dataRoomSensors.map((item, index) => (
          <IonItemSliding key={item.id}>
            <IonItem key={item.id} lines='full' onClick={(e) => {e.preventDefault(); h.push('/tabheating/pageheatingroomlist/room/'+item.id+'/'+item.name)}}>
              <IonGrid>
                <IonRow>
                  <IonCol size='5'><IonLabel color='primary'>{item.name}</IonLabel></IonCol>
                  <IonCol size='5'><IonLabel color='primary'><small>{parseNaN2questionMarks(item.actualTemp)} | {parseNaN2questionMarks(item.setTemp)}°C </small></IonLabel></IonCol>
                  <IonCol size='2'>
                    <IonNote>{item.userOfset !== 0 ? <IonIcon color='warning' icon={handRight}/> : ''}</IonNote>
                    <IonNote>{item.isHeating === '1' ? <IonIcon color='danger' icon={flame}/> : ''}</IonNote>
                    <IonNote>{item.isLow === '1' ? <IonIcon color='' icon={moon}/> : ''}</IonNote>
                    <IonNote>{item.isSummer === '1' ? <IonIcon icon={sunny}/> : ''}</IonNote>
                    <IonNote>{item.isOpenWindow === '1' ? <IonIcon icon={open}/> : ''}</IonNote>
                    <IonNote>{item.isGuestCard === '1' ? <IonIcon icon={card}/> : ''}</IonNote>
                    <IonNote>{item.isCooling === '1' ? <IonIcon icon={snow}/> : ''}</IonNote>
                  </IonCol>
                </IonRow>
                
                {showFloor ? 
                <IonRow>
                  <IonCol>
                    <RenderFloorSensors idAirSensor={item.id} dataFloorSensors={dataFloorSensors} color='primary'></RenderFloorSensors>
                  </IonCol>
                </IonRow> : ''}

                {item.userOfset > 0 ? 
                <IonRow>
                  <IonCol>
                    <IonLabel>
                        {item.userOfset !== 0 ? <p><IonText color='warning'>({item.userOfset}°C ručně, po úpravě {item.setTempAll}°C) </IonText></p> : ''}
                    </IonLabel>
                  </IonCol>
                </IonRow>: ''}
                
              </IonGrid>
            </IonItem>
            <IonItemOptions side="end">
              <IonItemOption onClick={(e) => {e.preventDefault(); h.push('/tabheating/pageheatingmanuallist/room/'+item.id+'/'+item.name)}}><IonIcon icon={handRight}/>Změnit</IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
      </>
    );
      /*
                          <IonText color="primary">
                      Požadovaná teplota: {item.setTemp}°C
                    </IonText>
      <IonCol><IonLabel><IonText color={item.isHeating === '1' ? 'danger' : 'dark'}>{isNumeric(item.actualTemp) ? item.actualTemp : '??.?'}°C</IonText></IonLabel></IonCol>
      <IonNote>{item.isHeating =='1' ? <IonIcon icon={bonfire}/> : <IonIcon icon={remove}/>}</IonNote >
      <h6>Ruční změna: {item.userOfset}°C, max. změna: {item.maxUserOfset}°C</h6>
      <IonIcon color='light' icon={thermometerfloor}></IonIcon><IonCheckbox onIonChange={handleOnChangeShowFloor}></IonCheckbox>          
      */
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabheating" />
            {isLoadingXmppListRooms===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={handleOnChangeShowFloor}>RT_P</IonButton>
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doFetchXmppListRoomsRefresh}>
          <IonRefresherContent 
            pullingIcon="arrow-dropdown"
            pullingText="Přetažením dolů načti znovu data"
            refreshingSpinner="circles"
            refreshingText="Načítám ...">
          </IonRefresherContent>
        </IonRefresher>
        
        <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
        >
          <IonCard>
            <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
              <p>Zde jsou zobrazeny aktuální a požadované teploty a dále i ruční změny.<br></br>
              <small>Formát: aktuální teplota / požadovaná teplota</small>
              </p>
              <p>Výpis lze obnovit přetažením dolů. Žlutou barvou jsou označeny ruční změny.</p>
              <p>Kliknutím na položku přejdete k přiřazení režimu pro vybranou místnost.</p>
              <p>Posunem řádku doleva můžete ručně změnit teplotu.</p>
              <p>Ikonou RT_P zobrazíte teploty logicky navázaných podlahových čidel.</p>
              <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>
        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" icon={informationCircle}/></IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <RenderListRooms/>
            </IonList>
          </IonCardContent>
        </IonCard>

        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
        <IonToast
          isOpen={isErrorXmppListRooms}
          message={errMsgXmppListRooms}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />

      </IonContent>
    </IonPage>
  );
};

export default PageHeatingInfoRoom;

/* Sliding item with text options on both sides */
/*
<IonItemSliding>
<IonItemOptions side="start">
  <IonItemOption onClick={() => console.log('favorite clicked')}>Favorite</IonItemOption>
</IonItemOptions>

<IonItem>
  <IonLabel>Test sliding item</IonLabel>
</IonItem>

<IonItemOptions side="end">
  <IonItemOption onClick={() => console.log('unread clicked')}>Unread</IonItemOption>
</IonItemOptions>
</IonItemSliding>

<IonLoading
          isOpen={isLoadingXmppListRooms}
          message={'Loading...'}
          duration={30000}
        />
*/