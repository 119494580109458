//@ts-check

import React, { useState, useEffect } from "react";
import { getArrOfNames, prepareID } from '../../functions/fce-string';

import { IonPage, IonBackButton, IonButtons, IonHeader,
         IonToolbar, IonLabel, IonToggle,
         IonCard, IonCardHeader, IonCardSubtitle, 
         IonCardContent, IonCardTitle, IonRange,
         IonIcon, IonText, IonContent, 
         IonToast, IonButton, IonList, IonItem, IonPopover, IonSpinner, IonSelect, IonSelectOption, IonBadge
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../TabHeating.css';

/* Icons */
import { helpCircleOutline, handRight } from "ionicons/icons";
import { useDataXMPP, useDataXMPP2, useDataXMPP3 } from '../../functions/fetchXMPP';
import { parseBoolean } from "../../functions/fce-math";

import roller0 from "../../img/roller0.svg";
import roller10 from "../../img/roller10.svg";
import { TIMEOUT_TOAST_SAVED, TIMEOUT_TOAST_ERROR } from "../../App";
import { useDeviceSelected } from "../../context/useDeviceSelectedContext";

/*
Load form HC64:
listOfRollerShutters  ->  'Roleta  1    Roleta  2    '
loadCentral           ->  '0!000'
centralRollerShutters ->  '11'

Save to HC64:

*/

const PageRollerCentral = (props : any) => {
  const deviceResource = useDeviceSelected();
  const [showLoading, setShowLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showToastSaved, setShowToastSaved] = useState(false);

  //Načtení statusu LOW
  const defaultXmppObject = {
    typefce: 'get',
    fce: '/loadCentral',
    param: ''
  }
  const [{ dataXmpp, isLoadingXmpp, isErrorXmpp, errMsgXmpp }, doFetchXmpp] = useDataXMPP(
    defaultXmppObject,
    '',
  );

  const [first, setFirst] = useState(0); //Hlídání prvního průběhu load stránky

  const [centralActive, setCentralActive] = useState(false);
  const [centralError, setCentralError] = useState(false);
  const [centralPosition, setCentralPosition] = useState(0);
  const [centralRotation, setCentralRotation] = useState(0);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp) {
      let resultXmpp: string = dataXmpp;
      try {
        resultXmpp = resultXmpp.trim();
      }
      catch {
        resultXmpp = '';
      }

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {
        if(resultXmpp.length === 5)
        {
          let isActive = parseBoolean(resultXmpp.substr(0,1));
          setCentralActive(isActive);

          let isError = resultXmpp.substr(1,1);
          if(isError !== '0') {
            setCentralError(true);
          }
          
          let pos = parseInt(resultXmpp.substr(2,1));
          setCentralPosition(pos);
          let rot = parseInt(resultXmpp.substr(3,2));
          setCentralRotation(rot);
        }
      }
      catch {}
      finally {}
    }
  },[dataXmpp, isLoadingXmpp]);

  
  //----Načtení místností
  const defaultXmppObject2 = {
    typefce: 'get',
    fce: '/listOfRollerShutters',
    param: ''
  }
  const [{ dataXmpp2, isLoadingXmpp2, isErrorXmpp2, errMsgXmpp2 }, doFetchXmpp2] = useDataXMPP2(
    defaultXmppObject2,
    '',
  );  
  const [listRooms, setListRooms] = useState([]);

  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp2) {
      let resultXmpp: string = dataXmpp2;
      let result = [];

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {  
        if(resultXmpp.length >= 13) {
          let dataList = getArrOfNames(resultXmpp, 13);
          let i = 0;
          dataList.forEach(row => {
            const reg = { 
              id: i,
              name: row,
            };
            result.push(reg);
            i ++;
          });
        }
      }
      catch {
        result = [];
      }
      finally {
        setListRooms(result)
      }
    }
  },[dataXmpp2]);
  

  //----Načtení statusů přiřazení Léto k místnostem
  const defaultXmppObject3 = {
    typefce: 'get',
    fce: '/centralRollerShutters',
    param: ''
  }
  const [{ dataXmpp3, isLoadingXmpp3, isErrorXmpp3, errMsgXmpp3 }, doFetchXmpp3] = useDataXMPP3(
    defaultXmppObject3,
    '',
  );
  
  const defaultListRoller = () => {
    let result = [];
    for(let i=0;i<32;i++){
      const reg = { 
        id: i,
        checked: false
      };
      result.push(reg);
    }
    return result;
  }
  const [listRoller, setListRoller] = useState(defaultListRoller);
  
  useEffect(function fce() {
    // 👍 We're not breaking the first rule anymore
    if (!isLoadingXmpp3) {
      let resultXmpp: string = dataXmpp3;
      let result = [];

      if(resultXmpp === '' || resultXmpp === 'true')
        return;
      
      try {  
        if(resultXmpp.length > 1) {
          let dataList = getArrOfNames(resultXmpp, 1);
          let i = 0;
          dataList.forEach(row => {
            let checked = parseBoolean(row);
            
            const reg = { 
              id: i,
              checked: checked,
            };
            result.push(reg);
            i ++;
          });
          setShowLoading(false);
        }
      }
      catch {
        result = [];
      }
      finally {
        setListRoller(result)
      }
    }
  },[dataXmpp3]);


  const ListRooms = () => {
    return (
        <>
          {listRooms.map((item, index) => (
            <IonItem key={item.id}>
              <IonLabel>{item.name}</IonLabel>
              <IonToggle disabled={isErrorXmpp} checked={listRoller[item.id].checked} onIonChange={(e)=>handleOnCheckedRoom(e, item.id)}/>
            </IonItem>
          ))}
        </>
    );
  }

  //Konfigurace Slidu
  const slideOpts = {
    initialSlide: 0,
    pager: true,
    stretch: 0,
    speed: 200,
    freemode: true
  };

  const handleOnSetRotation = (e) => {
      setCentralRotation(parseInt(e.detail.value));
  }

  const handleOnChecked = (e) => {
    if(first > 0) {
        setCentralActive(e.target.checked);
    }
    else {
      setFirst(1);
    }
  }

  const handleOnSelectItem = (e) => {
    let p = parseInt(e.detail.value);
    setCentralPosition(p);
  }

  const handleOnClickOK = () => {
    let p = '';

    if(centralActive) {
      p += '1';
    }
    else {
      p += '0';
    }

    p += prepareID(centralPosition);
    p += prepareID(centralRotation);

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveCentral',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );

    setShowToastSaved(true);
    
  }

  const handleOnCheckedRoom = (e, id:number) => {
    var v = e.detail.checked;
    let copy = JSON.parse(JSON.stringify(listRoller));
    copy[id].checked = v;
    setListRoller(copy);
  }

  const handleOnClickOKroomsAssign = () => {
    
    let p = '';
    listRoller.forEach(row=>{
      if(row.checked) {
        p += '1';
      }
      else {
        p += '0';
      }
    })

    const saveXmppObject = {
      typefce: 'SET',
      fce: '/saveCentralRollerShutters',
      param: p
    }
    doFetchXmpp(
      saveXmppObject,
    );

    setShowToastSaved(true);
  }

  return (
    <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text='Zpět' defaultHref="/tabroller" />
            {showLoading===true ? <IonSpinner name="circles"/> : ''}
          </IonButtons>
          <IonBadge slot='end'>{deviceResource}</IonBadge>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowHelp(true)}>?</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonPopover
          isOpen={showHelp}
          onDidDismiss={e => setShowHelp(false)}
          >
          <IonCard>
          <h3><IonIcon icon={helpCircleOutline}/> Nápověda</h3>
            <p>
            Zapnutím centrálního tlačítka dojde k nastavení všech přiřazených rolet na pozici definovanou u centrálního tlačítka.
            </p>
            <p>Standardně všechny rolety reagují na centrální tlačítko. Zvolené rolety lze níže vyřadit.</p>
            <IonButton expand="block" onClick={()=>setShowHelp(false)}>Zavřít</IonButton>
          </IonCard>
        </IonPopover>

        <IonToast
        isOpen={showToastSaved}
        onDidDismiss={() => setShowToastSaved(false)}
        message="Nastavení uloženo..."
        duration={TIMEOUT_TOAST_SAVED}
      />
        
        <IonCard className="welcome-card" >
          <IonCardHeader>
            <IonCardTitle>{props.title} <IonIcon color="medium" icon={handRight}/></IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle></IonCardTitle>
                    <IonCardSubtitle>{!centralError ? 'Centrální tlačítko rolet/žaluzií' : 'Chyba komunikace centrálního tlačítka'}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                          <IonLabel>{centralActive ? 'Tlačítko zapnuto' : 'Tlačítko vypnuto'}</IonLabel>
                          <IonToggle value="low" checked={centralActive} onIonChange={handleOnChecked} disabled={centralError}/>
                      </IonItem>
                      <IonItem>
                          <IonLabel>Poloha:</IonLabel>
                          <IonSelect value={centralPosition.toString()} interface="popover" onIonChange={(e)=>handleOnSelectItem(e)} disabled={centralError}>
                            <IonSelectOption value="0">Otevřeno</IonSelectOption>
                            <IonSelectOption value="1">Zavřeno</IonSelectOption>
                            <IonSelectOption value="2">Šterbiny</IonSelectOption>
                            <IonSelectOption value="3">Mezipoloha</IonSelectOption>
                          </IonSelect>
                      </IonItem>
                      <IonItem lines="none">
                          <IonLabel>Natočení (pouze žaluzie):</IonLabel><IonText>{centralRotation}</IonText>
                      </IonItem>
                      <IonItem>
                          <IonRange min={0} max={10} step={1} pin={true} value={centralRotation} onIonChange={handleOnSetRotation} disabled={!centralActive}>
                              <IonIcon slot="start" icon={roller0}/>
                              <IonIcon slot="end" icon={roller10}/>
                          </IonRange>
                      </IonItem>
                    </IonList>
                    <IonButton disabled={isErrorXmpp || isLoadingXmpp || centralError} expand="block" onClick={handleOnClickOK}>Uložit</IonButton>
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle></IonCardTitle>
                    <IonCardSubtitle>Přiřazení rolet/žaluzií</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <ListRooms/>
                    </IonList>
                    <IonButton disabled={isErrorXmpp || isLoadingXmpp} expand="block" onClick={handleOnClickOKroomsAssign}>Uložit rolety/žaluzie</IonButton>
                  </IonCardContent>
                </IonCard>

          </IonCardContent>
        </IonCard>

        <IonToast
          isOpen={isErrorXmpp}
          message={errMsgXmpp}
          position="bottom"
          duration={TIMEOUT_TOAST_ERROR}
          buttons={[
            {
              text: 'OK',
              role: 'cancel'
            }
          ]}
        />
      </IonContent>  
    </IonPage>
    )
  }
  export default PageRollerCentral;  
